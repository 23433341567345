import {
  SHOW_CART,
  SHOW_SETTINGS,
  SHOW_MAIN_MENU,
  HIDE_TOAST_MESSAGE,
  SET_TOAST_MESSAGE,
  SET_TRIAL_EXPIRED,
  SET_OUTSTANDING_INVOICES,
  SET_DISCONNECTED_TIME,
  SET_DESKTOP_CONNECTED,
  SHOW_KDS,
  SHOW_CHAT,
  SET_IS_DESKTOP,
  SET_THEME,
  SHOW_EXTERNAL_URL
} from './actionTypes'

export const showCart = () => ({
  type: SHOW_CART
})

export const showExternalOpenUrl = obj => ({
  type: SHOW_EXTERNAL_URL,
  payload: obj
})

export const showKds = show => ({
  type: SHOW_KDS,
  payload: show
})

export const showChat = show => ({
  type: SHOW_CHAT,
  payload: show
})

export const showSettings = () => ({
  type: SHOW_SETTINGS
})

export const showMainMenu = () => ({
  type: SHOW_MAIN_MENU
})

export const hideToastMessage = () => ({
  type: HIDE_TOAST_MESSAGE
})

export const setToastMessage = (msg, obj, action) => ({
  type: SET_TOAST_MESSAGE,
  payload: { message: msg, opt: obj, action: action }
})

export const setTrialExpired = () => ({
  type: SET_TRIAL_EXPIRED
})

export const setOutstandingInvoices = hasOutstanding => ({
  type: SET_OUTSTANDING_INVOICES,
  payload: hasOutstanding
})

export const setDisconnectedTime = (time, reason) => ({
  type: SET_DISCONNECTED_TIME,
  payload: { time, reason }
})

export const setIsDesktopConnected = is => ({
  type: SET_DESKTOP_CONNECTED,
  payload: is
})

export const setIsDesktop = version => ({
  type: SET_IS_DESKTOP,
  payload: version
})

export const setTheme = theme => ({
  type: SET_THEME,
  payload: theme
})
