/* eslint-disable no-unreachable */
import { memo, Fragment, Component, useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Text, translate } from 'react-internationalization'
import List from '@material-ui/core/List'

import { Divider } from '@countr/ui'

import { ErrorUtils } from '../../utils/ErrorUtils'
import { AppInstances } from '../../utils/countrSdkInstance'
import { cartUtils } from '../../utils/cartUtils'
import { CountrListeners } from '../../utils/CountrListeners'
import { PrinterUtils } from '../../utils/PrinterUtils'
import ScreenUtils from './../../utils/ScreenUtils'
import StoreUtils from '../../utils/StoreUtils'
import Util from '../../utils/Util'

import {
  addCart,
  editCart,
  selectCart,
  selectCartItem,
  deleteCart,
  setTableSeat
} from '../../store/actions/carts'
import { setToastMessage } from './../../store/actions/app'

import CartEntry from './CartEntry'
import CartItemDetails from '../Assortment/CartItemDetails/CartItemDetails'
import CartSubTotal from './CartSubTotal'
import CartTotal from './CartTotal'
import CartHeader from './Header/CartHeader'
import CartToolbar from './Header/CartToolbar'
import Calculator from './Optional/Calculator'
import DeliveryDetails from './Optional/DeliveryDetails'
import SeatEntry from './SeatEntry'

import './Cart.scss'

const mapStateToProps = state => {
  return {
    user: state.user,
    devices: state.devices,
    carts: state.carts,
    settings: state.settings,
    customers: state.customers
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addCart: cart => dispatch(addCart(cart)),
    editCart: cart => dispatch(editCart(cart)),
    selectCart: cart => dispatch(selectCart(cart)),
    selectCartItem: item => dispatch(selectCartItem(item)),
    deleteCart: id => dispatch(deleteCart(id)),
    setToastMessage: (msg, opt, action) => dispatch(setToastMessage(msg, opt, action)),
    setTableSeat: seat => dispatch(setTableSeat(seat))
  }
}

export const Cart = memo(props => {
  const [itemDetailsDialog, setItemDetailsDialog] = useState(false)
  const [entryID, setEntryID] = useState('')
  const [itemIndexInCart, setItemIndexInCart] = useState(0)
  const [currency, setCurrency] = useState('')
  const selectedCartIdRef = useRef()

  selectedCartIdRef.current = props.selectedCart?._id || ''

  useEffect(() => {
    const bootstrap = () => {
      const store = props.devices.store
      CountrListeners.initCartListeners(store._id, cartCallbacks)

      if (store && store.currency) {
        setCurrency(store.currency.symbol)
      }
    }

    bootstrap()
  }, [])

  const cartCallbacks = (cart, type) => {
    const allCarts = props.carts.carts

    if (type === CountrListeners.getConstants().CART_UPDATED) {
      if (cartShouldUpdate(cart)) {
        if (allCarts.findIndex(c => c._id === cart._id) >= 0) {
          updateCart(cart)
        }
      }
    } else if (type === CountrListeners.getConstants().CART_CREATED) {
      if (cartShouldUpdate(cart)) {
        allCarts.push(cart)

        if (cartUtils.isThirdPartySource(cart)) {
          props.addCart(cart)
          localStorage.setItem('CountrLite:Cart-' + cart._id, JSON.stringify(cart))

          if (props.settings.kitchenDeliveryPrint) {
            printOrder(cart)
          }
        }
      }
    }
  }

  const cartShouldUpdate = cart => {
    const {
      store,
      device: { _id }
    } = props.devices

    if (cart.server_modified || !cart.originator || cart.originator !== _id) {
      return true
    }

    return false
  }

  const printOrder = cart => {
    if (!!cart.status && cart.status !== 'new') {
      const { splitOrderItems, splitOrderAmount } = props.settings
      const { kdsAttached } = props.devices.store.options
      PrinterUtils.order(cart, null, false, splitOrderItems, splitOrderAmount, kdsAttached)

      const toastMsg = {
        by: cartUtils.getDeliveryBy(cart),
        receipt_id: cart.extras.deviceCartName || ''
      }

      props.setToastMessage('delivery_toast', toastMsg)
      Util.showNotification(`${translate('cart')} -> ${toastMsg.by}: ${toastMsg.receipt_id}`)
    }
  }

  const openCartItemDetailsDialog = (item, index, cart) => {
    if (hasDeliveryDetails()) {
      return false
    }

    if (!props.settings.kioskMode) {
      props.selectCartItem(item)
      setItemDetailsDialog(true)
      setEntryID(cartUtils.getCartEntryId(item))
      setItemIndexInCart(index)
    }
  }

  const closeCartItemDetailsDialog = () => {
    setItemDetailsDialog(false)
    setEntryID('')

    // Remove the Focus to avoid Scanning new product open the Modal
    if (document.activeElement instanceof HTMLElement) {
      setTimeout(() => {
        document.activeElement.blur()
      }, 500)
    }
  }

  const createCart = index => {
    const cartTables = props.settings.cartsTable
    const device = props.devices.device
    const merchantId = props.user.user._id
    const newCart = cartUtils.createCart(merchantId, device, index, cartTables)

    return newCart
  }

  const handleSeatRemove = async id => {
    let amount
    const updatedCart = JSON.parse(JSON.stringify(props.carts.selectedCart))
    // get items index in cart items and calculate remaining amount
    const index = updatedCart.items.findIndex(i => cartUtils.getCartEntryId(i) === id)
    const remainingAmount = updatedCart.items[index].amount

    PrinterUtils.handlePrintRemovedItem(
      updatedCart.items[index],
      props.selectedCart,
      amount.quantity ? amount.quantity : false
    )
    if (remainingAmount <= 0) {
      updatedCart.items.splice(index, 1)
    }

    handleUpdateCart(updatedCart)
  }

  const removeItem = async (id, index) => {
    const updatedCart = { ...props.carts.selectedCart }
    const item = updatedCart.items[index]

    const paidAmount = cartUtils.getItemPaidAmount(updatedCart, item)

    if (!!paidAmount) {
      updatedCart.items[index].amount = paidAmount
    } else {
      updatedCart.items.splice(index, 1)
    }

    PrinterUtils.handlePrintRemovedItem(item, props.selectedCart)
    handleUpdateCart(updatedCart)
  }

  const handleUpdateCart = async updatedCart => {
    const countr = await AppInstances.getCountrSdk()
    const { totalAmount, totalAmountPreTax } = countr.calculateTotal(updatedCart)
    updatedCart.total = totalAmount
    updatedCart.sub_total = totalAmountPreTax
    updatedCart.date = new Date()

    if (props.settings.enableCustomerScreen) {
      const port = props.settings.customerScreenPort
      if (port && port.length) {
        ScreenUtils.addProduct(undefined, updatedCart.total, updatedCart.currency.code, port)
      }
    }

    props.selectCart(updatedCart)
    cartUtils.updateCartServerDebounce(updatedCart)
    // update redux carts
    const allCarts = props.carts.carts
    const selectedIndex = allCarts.findIndex(c => c._id === updatedCart._id)
    if (selectedIndex > -1) {
      props.editCart(updatedCart)
    }

    // delete cart if has 0 items and it is an exchange cart
    if (cartUtils.isExchangeableCart(updatedCart) && !updatedCart.items.length) {
      const deleteId = updatedCart._id
      cartUtils.deleteCartServer(deleteId)
      const currentCart = JSON.parse(JSON.stringify(props.carts.carts[0]))
      props.selectCart(currentCart)
      props.deleteCart(deleteId)
      localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(currentCart))
      localStorage.removeItem('EXCHANGE:CountrLite:Cart-' + deleteId)
    }
  }

  const updateCart = cart => {
    if (cart._id === selectedCartIdRef.current) {
      props.selectCart(cart)
      localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(cart))
    }

    // Updating cart triggered by cart.update event
    props.editCart(cart)
    localStorage.setItem('CountrLite:Cart-' + cart._id, JSON.stringify(cart))
  }

  const hasDeliveryDetails = () => {
    const cart = props.selectedCart

    return (
      (cart.order_source !== 'pos' || cart.order_source !== 'web_pos') &&
      cart.extras &&
      cart.extras.delivery
    )
  }

  return (
    <div id="cart" className={props.settings.cartAlwaysOpen ? 'fixed-cart' : 'floating-cart'}>
      <CartHeader createCart={createCart} />
      <CartToolbar />

      {/*
          Using props.selectedCart instead of props.carts.selectedCart
          because every time that dispatch a action to selectedCart it will re render this component
          and with I try to evaluate this selectedCart state it will throw some error
        */}
      {!cartUtils.showSeats(props.selectedCart, props.devices.device) &&
        Object.keys(props.selectedCart).length > 0 &&
        props.selectedCart.items.length > 0 && (
          <List id="cart-list-items" className="cart-list-items">
            {props.selectedCart.items.map((item, index) => (
              <Fragment key={`${cartUtils.getCartEntryId(item)}-${item.product.discount}-${index}`}>
                <CartEntry
                  cart={props.carts.selectedCart}
                  item={item}
                  index={index}
                  settings={props.settings}
                  currency={props.devices.store.currency.symbol}
                  openCartItemDetailsDialog={openCartItemDetailsDialog}
                  removeItem={removeItem}
                  devices={props.devices}
                />
                <Divider />
              </Fragment>
            ))}
          </List>
        )}
      {cartUtils.showSeats(props.selectedCart) && !!props.selectedCart.items.length && (
        <List id="cart-list-items" className="cart-list-items">
          {/* In case of more than one seats and the setting enabled the view will change */}
          {[...Array(props.selectedCart.extras.covers + 1).keys()].map((seat, index) => (
            <Fragment key={`${seat}-${index}`}>
              <SeatEntry
                seat={seat}
                seatNo={props.carts.seatNo}
                cartName={cartUtils.getCartName(props.selectedCart)}
                setTableSeat={props.setTableSeat}
                selectedCart={JSON.parse(JSON.stringify(props.selectedCart))}
                getProductId={cartUtils.getCartEntryId}
                settings={props.settings}
                currency={props.devices.store.currency.symbol}
                removeItem={handleSeatRemove}
                openCartItemDetailsDialog={openCartItemDetailsDialog}
                devices={props.devices}
              />
              <Divider />
            </Fragment>
          ))}
        </List>
      )}
      {Object.keys(props.selectedCart).length > 0 && props.selectedCart.items.length === 0 && (
        <div className="cart-list-items">
          <span className="no-items"></span>
        </div>
      )}
      {hasDeliveryDetails() && <DeliveryDetails cart={props.selectedCart} />}
      {props.settings.showCalculator && <Calculator />}
      {Object.keys(props.carts.selectedCart).length > 0 && (
        <CartSubTotal cart={props.carts.selectedCart} settings={props.settings} />
      )}
      <CartTotal symbol={Object.keys(props.selectedCart).length > 0 ? currency : ''} />
      {itemDetailsDialog && (
        <CartItemDetails
          itemDetailsDialog={itemDetailsDialog}
          itemIndexInCart={itemIndexInCart}
          closeCartItemDetailsDialog={closeCartItemDetailsDialog}
          entryID={entryID}
        />
      )}
    </div>
  )
})

const CartConnected = connect(mapStateToProps, mapDispatchToProps)(Cart)
export default CartConnected
