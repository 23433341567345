import { AppInstances } from './countrSdkInstance'
import { RequestQueue } from './RequestQueue'
import { cartUtils } from './cartUtils'
import DesktopUtils from './DesktopUtils'
import store from './../index'
import { setTableSeat } from '../store/actions/carts'
import StoreUtils from './StoreUtils'

export const PaymentUtils = (function () {
  const methods = ['cash', 'giftcard', 'cheque', 'invoice', 'pay_later']
  const cardMethods = ['manual_card_payment', 'payplaza', 'ccv']

  return {
    reflect: function (promise) {
      return promise.then(
        v => {
          return { result: v, status: 'resolved' }
        },
        e => {
          return { error: e, status: 'rejected' }
        }
      )
    },
    suggestionList: function (total) {
      let suggestionList = []
      const n = Math.ceil(total)
      suggestionList.push(n)
      let x = 1
      while (suggestionList[suggestionList.length - 1] / 3 < total) {
        suggestionList.push(n - (n % (2 * x)) + 2 * x)
        suggestionList.push(n - (n % (5 * x)) + 5 * x)
        suggestionList.push(n - (n % (10 * x)) + 10 * x)

        x *= 10
      }

      //Remove any values not consistent with the main denominations of note
      // (5 or 10, depending on rounded up value)
      suggestionList = suggestionList.filter((obj, index) => {
        if (!(obj % 5 && index > 0) && !(!(n % 5) && !(obj % 5) && obj % 10 && index > 0)) {
          return obj
        } else {
          return false
        }
      })

      // Return unique array (the first 4 elements)
      return suggestionList
        .filter((v, i, a) => a.indexOf(v) === i)
        .slice(0, 4)
        .sort((a, b) => {
          return parseFloat(a) > parseFloat(b)
        })
        .map(s => s.toFixed(2))
    },
    getPaymentMethod: function (type, device) {
      if (device.settings && device.settings.web_payments) {
        const index = device.settings.web_payments.findIndex(pay => pay.method === type)

        if (index < 0) {
          return type
        } else {
          const method = device.settings.web_payments[index]

          if (cardMethods.indexOf(method.method) >= 0) {
            return 'card'
          } else {
            return type
          }
        }
      } else {
        if (methods.indexOf(type) >= 0) {
          return type
        } else {
          return 'card'
        }
      }
    },
    getPaymentProvider: function (type, device) {
      if (device.settings && device.settings.web_payments) {
        const index = device.settings.web_payments.findIndex(pay => pay.method === type)

        if (index < 0) {
          return null
        } else {
          const method = device.settings.web_payments[index]
          if (method.isCustom) {
            return method.provider ? method.provider : null
          } else {
            return methods.indexOf(type) >= 0 ? null : type
          }
        }
      } else {
        return methods.indexOf(type) >= 0 ? null : type
      }
    },
    isPayLater: function (payment) {
      return payment.method === 'pay_later'
    },
    getEmployee: function (employee) {
      return Object.keys(employee).length > 0
        ? {
            name: employee.first_name + ' ' + employee.last_name,
            _id: employee._id
          }
        : { name: 'None', _id: null }
    },
    calculatePaidValue: function (cart) {
      if (!cart.payments.length) {
        return 0
      }

      return cart.payments.reduce((acc, payment) => acc + payment.paid, 0)
    },
    getLastCartPaymentValue: function (cart) {
      if (!cart.payments.length) {
        return 0
      }

      return cart.payments[cart.payments.length - 1].paid
    },
    createTransactionBody: function (
      method,
      provider,
      cart,
      device,
      total,
      change,
      isKiosk = false,
      isTip = false
    ) {
      const newNumber = parseInt(localStorage.getItem('CountrLite:LastTransaction'), 10) + 1
      localStorage.setItem('CountrLite:LastTransaction', newNumber)
      let numberString = newNumber.toString()
      for (let i = numberString.length; i < 6; i++) {
        numberString = '0' + numberString
      }

      const payment = {
        date: new Date(),
        paid: parseFloat(total),
        method: this.getPaymentMethod(method, device),
        provider: !!provider ? provider : this.getPaymentProvider(method, device),
        tip: !!isTip ? change : null,
        info: {},
        card_print_info: {
          clientTicket: ''
        },
        merchant_card_print_info: null,
        postponed: this.isPayLater({ method }),
        payment_started: new Date()
      }

      if (!!cart.info && !!cart.card_print_info) {
        payment.info = cart.info
        payment.card_print_info = cart.card_print_info
      }

      if (!!cart.info && !!cart.info.products) {
        payment.info.products = cart.info.products
      }

      if (DesktopUtils.isDesktop() && payment.provider === 'ccv') {
        let clientTicket = ''
        let clientTicketLines = ''
        const parser = new DOMParser()
        clientTicket = parser.parseFromString(cart.ccvInfo, 'text/xml')
        clientTicket = clientTicket.getElementsByTagName('TextLine')

        for (let i = 0; i < clientTicket.length; i++) {
          clientTicketLines += clientTicket[i].childNodes[0].nodeValue + '\n'
        }

        payment.info.printMessages = cart.ccvInfo
        payment.card_print_info.clientTicket = clientTicketLines
      }

      if (payment.method === 'invoice') {
        payment.info = {
          customer: cart.customer
        }
      }

      const body = {
        store: cart.store,
        device: device._id,
        date: new Date().toISOString(),
        timezone: cart.timezone,
        currency: cart.currency,
        customer: cart.customer,
        receipt_id: device.store.store_id + '-' + device.device_id + '-' + numberString,
        receipt_number: parseInt(numberString, 10),
        order_source: isKiosk ? 'kiosk' : 'web_pos',
        employee: cart.employee,
        items: cart.items,
        payments: cart.payments || [],
        paid: parseFloat(cart.paid),
        total: parseFloat(cart.total),
        sub_total: parseFloat(cart.sub_total),
        discount: cart.discount,
        change: !!isTip ? 0 : change,
        extras: cart.extras
      }

      if (cart.reduction) {
        body.reduction = cart.reduction

        if (cart.reduction.numeric && cart.reduction.numeric > 0) {
          const percent = cartUtils.calculateReductionPercent(cart)
          body.discount = percent
          body.reduction.percentage = percent
        }
      }

      if (!body.extras) {
        body.extras = {}
      } else {
        delete body.extras.modified
      }

      body.extras.appVersion = process.env.REACT_APP_VERSION
      body.extras.orderSource = cart._id

      if (cart.extras && cart.extras.note) {
        body.extras.note = cart.extras.note
      }

      if (!!body.extras) {
        body.items.forEach(item => {
          item.product.extras = {
            ...item.product.extras,
            drop_ship: body.extras.drop_ship || false,
            takeaway: body.extras.takeaway || false,
            buzzer: body.extras.buzzer || null,
            covers: body.extras.covers || 0
          }
        })

        if (body.extras.isGiveaway) {
          delete body.extras.isGiveaway
        }
      }

      // TODO: Remove this hardcode check
      if (payment.provider === 'cikam' && !!payment.info) {
        const { paid, change } = payment.info
        payment.paid = paid - change
        body.change = 0
      }

      // Adding new payment to payments array and recalculating paid value
      body.payments.push(payment)
      body.paid = body.payments.reduce(
        (acc, current) => acc + (!current.postponed ? current.paid : 0),
        0
      )

      if (this.isCartPaid(body)) {
        localStorage.setItem(
          'CountrWeb:LastTransactionInProgress-' + body.receipt_id,
          JSON.stringify(body)
        )
      }

      return this.updateOrderStatus(body, 'pending')
    },
    cleaningCart: function (cart, cartIndex, hasTables) {
      const state = store.getState()

      const c = JSON.parse(JSON.stringify(cart));
      c.customer = null
      c.total = 0
      c.sub_total = 0
      c.discount = 0
      c.reduction = {
        numeric: 0,
        percentage: 0
      }
      c.scanned_codes = []
      c.paid = 0
      c.payments = []
      c.items = []
      c.extras.note = ''
      c.date = new Date()
      c.updated_at = new Date()
      c.extras.cartStartedAt = new Date()
      c.extras.drop_ship = false
      c.extras.takeaway = false
      c.extras.covers = 0
      c.extras.buzzer = null
      c.device = state.devices.device._id
      c.originator = state.devices.device._id
      c.server_modified = false
      delete c.extras.modified
      delete c.extras.isGiveaway
      delete c.extras.giveaway_reason

      if (!hasTables) {
        const table = JSON.parse(localStorage.getItem('CountrWeb:CartAsTables'))
        const cartTable = table && table !== null ? 'TABLE ' : 'CART '
        c.extras.deviceCartName = cartTable + (cartIndex + 1)
      }

      c.info = {}
      c.card_print_info = {
        clientTicket: ''
      }

      // seat seat to 0
      store.dispatch(setTableSeat(0))
      return c
    },

    updateOrderStatus: function (order, newStatus) {
      const orderCopy = { ...order }

      orderCopy.items.forEach(item => {
        const currentStatusIndex = item.status.findIndex(
          status => status.state === 'new' || (status.state === 'pending' && status.amount > 0)
        )

        const findedPrintedStatus = item.status.findIndex(
          status => status.state === 'printed'
        )

        if (~findedPrintedStatus) return

        const newStatusIndex = item.status.findIndex(status => status.state === newStatus)
        const lastUpdate = new Date()

        // Updating new status
        if (currentStatusIndex >= 0) {
          item.status[currentStatusIndex].amount = 0
          // item.status[currentStatusIndex].last_update = lastUpdate
        }

        // Updating printed status
        if (newStatusIndex >= 0) {
          item.status[newStatusIndex].amount = item.amount
          item.status[newStatusIndex].last_update = lastUpdate
        } else {
          item.status.push({
            state: newStatus,
            amount: item.amount,
            last_update: lastUpdate,
            employees: []
          })
        }
      })

      return orderCopy
    },
    isCartPaid: function (cart) {
      const { total } = cart
      // Need to calculate paid instead of get cart.paid, because pay_later has cart.paid = 0
      // and just have cart.payments[0].paid = value
      const paid = this.calculatePaidValue(cart)
      const paidCents = Math.round(parseFloat(paid) * 100)
      const totalCents = Math.round(parseFloat(total) * 100)

      return paidCents >= (totalCents - 0.9) //Add just less than 1 cent to handle rounding issues
    },
    payWithMethod: function (body, cart, callbacks, cartIndex, hasTables) {
      const state = store.getState()

      // if paid is greater than total, create transaction and clean cart
      return AppInstances.getCountrSdk().then(async socket => {
        if (this.isCartPaid(body)) {
          //First attempt to fiscalise transaction if needed and if config set
          try {
            if (StoreUtils.isFiscalStore(state.devices.store, state.devices.device)) {
              const fdmInfo = state.devices.device.options.fiscalConfiguration
              const signBody = {
                ...fdmInfo,
                checkout: body,
                merchantID: state.user.user._id
              }
              await socket.fiscal(fdmInfo.provider).signCheckout(signBody).then((res) => {
                body.fiscal_info = res.pushResponse || res
                if(res.checkoutId) body._id = res.checkoutId
                return
              })
            }
          } catch(ex) {
            console.log('Fiscalisation exception', ex)
          }

          const requestBody = JSON.parse(JSON.stringify(body))

          const promises = []

          promises.push(
            socket.transactions.create(requestBody).then(transaction => {
              callbacks.addTransactionHead(transaction)
              localStorage.removeItem(
                'CountrWeb:LastTransactionInProgress-' + transaction.receipt_id
              )
              return transaction
            })
          )

          const cleanedCart = this.cleaningCart(cart, cartIndex, hasTables)
          promises.push(
            socket.carts.update(cleanedCart._id, cleanedCart).then(updatedCart => {
              return updatedCart
            })
          )
          // remove deliver cart from the cart list
          if (!hasTables && cart.order_source !== 'web_pos') {
            callbacks.deleteCart(cart._id)
            localStorage.removeItem('CountrLite:Cart-' + cart._id)
          } else {
            callbacks.editCart(cleanedCart)
            localStorage.setItem('CountrLite:Cart-' + cleanedCart._id, JSON.stringify(cleanedCart))
            callbacks.selectCart(cleanedCart)
            localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(cleanedCart))
          }

          Promise.all(promises.map(this.reflect)).then(results => {
            const success = results.filter(x => x.status === 'resolved')
            const errors = results.filter(x => x.status === 'rejected')

            if (errors.length === 0) {
              return Promise.resolve(success.map(res => res.result))
            } else {
              RequestQueue.enqueueAction({
                type: 'transactions',
                action: 'create',
                payload: requestBody
              })

              return Promise.reject(requestBody)
            }
          })
          return
        } else {
          // Just update cart with a new payment element
          const requestBody = JSON.parse(JSON.stringify(body))
          cart.paid = requestBody.paid
          cart.payments = requestBody.payments

          callbacks.editCart(cart)
          localStorage.setItem('CountrLite:Cart-' + cart._id, JSON.stringify(cart))
          callbacks.selectCart(cart)
          localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(cart))

          // Decreasing last transaction number to not jump 2 receipt_ip after a partial payment
          const newNumber = parseInt(localStorage.getItem('CountrLite:LastTransaction'), 10) - 1
          localStorage.setItem('CountrLite:LastTransaction', newNumber)

          return socket.carts.update(cart._id, cart).then(
            updatedCart => {
              return Promise.resolve('partial_payment')
            },
            error => {
              RequestQueue.enqueueAction({
                type: 'carts',
                action: 'update',
                payload: cart
              })
              return Promise.reject(error)
            }
          )
        }
      })
    },
    createRefundBody: function (
      countr,
      user,
      device,
      transaction,
      refundValue,
      listItems,
      type,
      reason,
      note,
      refundExtras,
      newNumber,
      employee,
      roundCheck
    ) {
      listItems.forEach(item => {
        item.amount = item.refund
        delete item.refund
      })

      let discount = transaction.discount

      if (transaction.reduction && transaction.reduction.numeric) {
        discount =
          transaction.reduction.numeric / (transaction.total + transaction.reduction.numeric)
      }

      const items = listItems.filter(item => item.amount > 0)
      const total = refundValue
      const taxes = cartUtils.getCartTaxes({ items: items, discount: discount })
      const subTotal = parseFloat(total - taxes)

      // const newNumber = parseInt(localStorage.getItem('CountrLite:LastTransaction'), 10) + 1
      // localStorage.setItem('CountrLite:LastTransaction', newNumber)
      let numberString = newNumber.toString()
      for (let i = numberString.length; i < 6; i++) {
        numberString = '0' + numberString
      }

      const refund = {
        date: new Date().toISOString(),
        merchant: user._id,
        store: device.store._id,
        device: device._id,
        receipt_id: device.store.store_id + '-' + device.device_id + '-' + numberString,
        receipt_number: newNumber,
        receipt_source: transaction.receipt_id,
        transaction_source: transaction._id,
        discount: discount,
        reason: reason,
        extras: JSON.parse(JSON.stringify(transaction.extras)),
        employee: employee,
        customer: transaction.customer,
        currency: transaction.currency,
        items: items,
        timezone: transaction.timezone,
        is_refund: true,
        order_source: 'web_pos',
        payments: [
          {
            date: new Date().toISOString(),
            paid: -total,
            method: this.getPaymentMethod(type, device),
            provider: this.getPaymentProvider(type, device),
            tip: null,
            info: {},
            card_print_info: {
              clientTicket: ''
            },
            merchant_card_print_info: null,
            postponed: false
          }
        ]
      }

      if (transaction.reduction) {
        refund.reduction = transaction.reduction

        if (transaction.reduction.numeric && transaction.reduction.numeric > 0) {
          const percent = cartUtils.calculateReductionPercent(transaction)
          refund.discount = percent
          refund.reduction.percentage = percent
        }
      }

      const { totalAmount, totalAmountPreTax } = countr.calculateTotal(refund)
      refund.sub_total = totalAmountPreTax
      refund.total = roundCheck ? total : totalAmount
      refund.paid = roundCheck ? total : totalAmount

      // if (refund.payments[0].provider === 'payplaza') {
      if (refundExtras && Object.keys(refundExtras).length) {
        const info = Object.assign({}, refundExtras)

        if (info.cardParsedData || info.printData) {
          refund.payments[0].card_print_info = Object.assign({}, info.cardParsedData, info.printData)
        }
        refund.payments[0].info = info
      }

      if (DesktopUtils.isDesktop() && refund.payments[0].provider === 'ccv') {
        let clientTicket = ''
        let clientTicketLines = ''
        const parser = new DOMParser()
        const ccvInfo = JSON.parse(localStorage.getItem('ccv-refund-payload'))
        clientTicket = parser.parseFromString(ccvInfo, 'text/xml')
        clientTicket = clientTicket.getElementsByTagName('TextLine')

        for (let i = 0; i < clientTicket.length; i++) {
          clientTicketLines += clientTicket[i].childNodes[0].nodeValue + '\n'
        }

        refund.payments[0].info.printMessages = ccvInfo
        refund.payments[0].card_print_info.clientTicket = clientTicketLines
        localStorage.removeItem('ccv-refund-payload')
      }

      // Refund extra note (more detailed reason)
      refund.extras.note = note

      return refund
    },

    flattenPaymentInfoObj: function (info) {
      if (!!info) {
        if (typeof info === 'string') {
          return info
        } else if (typeof info === 'object' && Object.keys(info).length) {
          const printInfo = Object.keys(info).map(key => `${key}: ${info[key]}`)
          return printInfo.join('\n')
        }
      }

      return ''
    },

    extractSdkPaymentExtras: function (cart, extras) {
      // Cikam has a different format for clientTicket
      // It is a obj, so need to stringify it
      const printData = this.flattenPaymentInfoObj(extras.printData)
      const clientTicket =
        !!extras && !!extras.cardParsedData && !!extras.cardParsedData.clientTicket
          ? extras.cardParsedData.clientTicket
          : extras.printData?.clientTicket
          ? extras.printData.clientTicket
          : printData || ''
      cart.info = extras
      cart.card_print_info = {
        clientTicket: !!clientTicket ? clientTicket : !!printData ? printData : ''
      }

      return cart
    },

    addPartialGiftPaymentToCart: function (cart, extras) {
      const payment = {
        date: new Date(),
        paid: extras.activity[extras.activity.length - 1].journeys,
        method: 'giftcard',
        provider: '',
        tip: null,
        info: { ...extras },
        merchant_card_print_info: null,
        postponed: false,
        payment_started: new Date()
      }

      cart.paid += payment.paid
      cart.payments.push(payment)

      cartUtils.updateCartLocally(cart)
      cartUtils.updateCartServer(cart)

      return cart
    },

    roundCashPayment: function (value) {
      const roundedValue = this.roundValue(value, 2)

      if (roundedValue <= 0.05 && roundedValue > 0) {
        return 0.05
      }

      if (roundedValue >= -0.04 && roundedValue < 0) {
        return -0.05
      }

      if (roundedValue == 0) {
        return 0
      }
      
      return (Math.ceil(roundedValue * 20 - 0.5) / 20).toFixed(2)
    },

    roundValue: function (value, decimals) {
      // parse value to float if is not already it
      value = isNaN(value) ? parseFloat(value) : value
      value = isNaN(value) ? 0 : value

      // check decimals value
      decimals = decimals || 2

      // define roundby value
      var roundby = parseInt(Math.pow(10, decimals), 10)

      // trunc amount
      value = parseFloat(value * roundby).toFixed(0)
      value = (Math.round(value) | 0) / roundby

      return value
    },

    calculatetransactionsTip: function (transaction) {
      const executedPayments = transaction?.payments.length ? transaction.payments : []
      return executedPayments.reduce((acc, crr) => (acc += crr.tip || 0), 0)
    }
  }
})()
