import { AppInstances } from './countrSdkInstance'
import { cartUtils } from './cartUtils'
import ProductUtils from './ProductUtils'
import Util from './Util'
export default class VoucherUtils {
  static searchGiftcard = async code => {
    const url = process.env.REACT_APP_API_SERVER
    const token = localStorage.getItem('access_token')

    try {
      const response = await fetch(`${url}/giftcards/search?text=${code}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      })
      const giftcards = await response.json()
      return !!giftcards && !!giftcards.length ? giftcards[0] : null
    } catch (error) {
      // TODO: Toast error
      console.log(error)
      return null
    }
  }

  static updateGiftcard = async giftcard => {
    const url = process.env.REACT_APP_API_SERVER
    const token = localStorage.getItem('access_token')

    try {
      // TODO remove and use CountrRequest wrapper
      const response = await fetch(`${url}/giftcards/${giftcard._id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(giftcard)
      })
      const result = await response.json()
      return result
    } catch (error) {
      // TODO: Toast error
      console.log(error)
      return error
    }
  }

  static redeemGiftcard = async (giftcard, store, cart, partial) => {
    const paying = partial ? partial : cart.total
    const reduceValue = paying < giftcard.value_remaining ? paying : giftcard.value_remaining
    const activity = {
      date: new Date(),
      type: 'Redeem',
      current: store.name,
      journeys: reduceValue
    }

    giftcard.value_remaining -= reduceValue
    giftcard.activity.push(activity)
    giftcard.is_active = giftcard.value_remaining > 0

    return this.updateGiftcard(giftcard)
  }

  static searchVoucherCode = async code => {
    const countr = await AppInstances.getCountrSdk()
    return countr.coupons.control({ text: code }).then(
      voucher => {
        return Promise.resolve({ ...voucher, code })
      },
      error => {
        return Promise.reject(error)
      }
    )
  }

  static checkVoucherValid = (voucher, cart, selectCart) => {
    if (!!voucher.status && voucher.status === 'valid') {
      if ((cart.scanned_codes || []).indexOf(voucher.code) >= 0) {
        return Promise.resolve({
          type: 'coupon',
          result: true,
          message: 'voucher_already_processed'
        })
      } else {
        return this.applyVoucher(voucher, cart, selectCart)
      }
    } else {
      return Promise.resolve({
        type: 'coupon',
        result: false,
        message: voucher.message
      })
    }
  }

  static applyVoucher = (voucher, cart, selectCart) => {
    if (!!voucher.ticket_info && voucher.ticket_info.scope === 'cart') {
      return this.applyCartLevelVoucher(voucher, cart, selectCart)
    } else if (!!voucher.ticket_info && voucher.ticket_info.scope === 'item') {
      return this.applyItemLevelVoucher(voucher, cart, selectCart)
    }
  }

  static applyCartLevelVoucher = async (voucher, cart, selectCart) => {
    let percentage_discount = 0
    if ((voucher.ticket_info.discount || {}).numeric) {
      const _old = cart.total
      const _new = _old - parseFloat((voucher.ticket_info.discount || {}).numeric)
      percentage_discount = 1 - _new / _old
    }
    //Apply the discount
    cart.reduction = {
      percentage: (voucher.ticket_info.discount || {}).percentage || percentage_discount,
      numeric: (voucher.ticket_info.discount || {}).numeric || 0,
      reason: voucher.ticket_info.title + ' (' + voucher.ticket_info.code + ')'
    }
    // cart.discount = (voucher.ticket_info.discount || {}).percentage || percentage_discount
    const countr = await AppInstances.getCountrSdk()
    const { totalAmount, totalAmountPreTax } = countr.calculateTotal(cart)
    cart.total = totalAmount
    cart.sub_total = totalAmountPreTax
    cart.scanned_codes.push(voucher.code)
    selectCart(cart)
    cartUtils.updateCartServer(cart)

    return Promise.resolve({
      status: 'success',
      message: 'discount_applied',
      temporaryOpen: true
    })
  }

  static applyItemLevelVoucher = async (voucher, cart, selectCart) => {
    if (!cart.items.length) {
      return Promise.resolve({
        type: 'coupon',
        result: true,
        message: 'no_applicable_items_in_cart'
      })
    } else {
      const countr = await AppInstances.getCountrSdk()
      const listApplicableItems = []

      cart.items.forEach(item => {
        if (ProductUtils.isCustomProduct(item.product) || cartUtils.isDiscountedItem(item)) {
          // Item cannot have voucher discount
        } else if (
          voucher?.ticket_info?.products?.indexOf(item.product._id) >= 0 || 
          Util.arrayOverlap((voucher.ticket_info.categories || []), item.product.categories) || 
          Util.arrayOverlap((voucher.ticket_info.variants || []), [item.product.current_variant._id])
        ) { 
          listApplicableItems.push(item)
        }
      })

      if (!listApplicableItems.length) {
        return Promise.resolve({
          type: 'coupon',
          result: true,
          message: 'no_applicable_items_in_cart'
        })
      }

      let result = null
      // Applying discount to all applicable item
      listApplicableItems.forEach(item => {
        const cartEntryIndex = cart.items.findIndex(
          i => cartUtils.getCartEntryId(i) === cartUtils.getCartEntryId(item)
        )
        cart.items.splice(cartEntryIndex, 1)

        // If has more than 1 amount, the discount is applyed just to 1 amount
        if (item.amount > 1) {
          item.amount -= 1
          cart.items.unshift(item)
        }

        // Now add the discounted item to the heade of the cart items
        const newCartEntryDiscounted = JSON.parse(JSON.stringify(item))
        newCartEntryDiscounted.amount = 1

        let percentage_discount = 0
        if ((voucher.ticket_info.discount || {}).numeric) {
          // const _old = newCartEntryDiscounted.product.current_variant.price
          const _old = countr.getPrice(newCartEntryDiscounted.product)
          const _new = _old - parseFloat((voucher.ticket_info.discount || {}).numeric)
          percentage_discount = 1 - _new / _old
        }

        //Apply the discount
        newCartEntryDiscounted.product.reduction = {
          percentage: (voucher.ticket_info.discount || {}).percentage || percentage_discount,
          numeric: (voucher.ticket_info.discount || {}).numeric || 0,
          reason: `Voucher ${voucher.ticket_info.code}`
        }
        newCartEntryDiscounted.product.discount = percentage_discount
        // newCartEntryDiscounted.discount_note = newCartEntryDiscounted.product.reduction.reason

        cart.items.unshift(newCartEntryDiscounted)
        cart.scanned_codes.push(voucher.code)

        const { totalAmount, totalAmountPreTax } = countr.calculateTotal(cart)
        cart.total = totalAmount
        cart.sub_total = totalAmountPreTax
        selectCart(cart)
        cartUtils.updateCartServer(cart)

        result = Promise.resolve({
          status: 'success',
          message: 'discount_applied',
          temporaryOpen: true
        })
      })

      return result
    }
  }
}
