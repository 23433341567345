import { memo, useState } from 'react';
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import { Text } from 'react-internationalization'
import { IconButton } from '@material-ui/core';

const releaseNotes = require('./../../release_notes/release_note.json')
const detailsStyle = { padding: 8 }

const ReleaseNote = memo(({ open, handleClose }) => {
  const [releaseNoteIndex, setReleaseNoteIndex] = useState(null)
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">
        <Text id="release_note" />
      </DialogTitle>
      <DialogContent>
        {releaseNotes.releases.slice(releaseNoteIndex,releaseNoteIndex+5).map(release => (
          <ExpansionPanel key={release.version}>
            <ExpansionPanelSummary expandIcon={<Icon>keyboard_arrow_down</Icon>}>
              <Typography>
                <strong>
                  <Text id="release_note" /> - {release.version}
                </strong>
              </Typography>
            </ExpansionPanelSummary>
            {!!release?.improvements?.length &&
              release.improvements.map((improvement, index) => (
                <ExpansionPanelDetails key={index} style={detailsStyle}>
                  <Typography>
                    [<Text id="improvement" />] - {improvement}
                  </Typography>
                </ExpansionPanelDetails>
              ))}
            {!!release?.fixes?.length &&
              release.fixes.map((fix, index) => (
                <ExpansionPanelDetails key={index} style={detailsStyle}>
                  <Typography>
                    [<Text id="fixed" />] - {fix}
                  </Typography>
                </ExpansionPanelDetails>
              ))}
          </ExpansionPanel>
        ))}
      </DialogContent>
      <DialogActions>
        <IconButton onClick={()=>{setReleaseNoteIndex(releaseNoteIndex-5<=0?0:releaseNoteIndex-5);}}>
        <span
              className="icon-left-double-arrow"/>
        </IconButton>
        <IconButton onClick={()=>{setReleaseNoteIndex(releaseNoteIndex+5);}}>
        <span
              className="icon-right-double-arrow"/>
        </IconButton>
        <Button onClick={handleClose} color="secondary">
          <Text id="close" />
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default ReleaseNote
