import { Component } from 'react';
import { connect } from 'react-redux'

import { Text } from 'react-internationalization'
import { isMobileOnly } from 'react-device-detect'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import { AppInstances } from './../../utils/countrSdkInstance'
import { PrinterUtils } from './../../utils/PrinterUtils'
import ProductUtils from '../../utils/ProductUtils'
import { cartUtils } from '../../utils/cartUtils'
import Util from '../../utils/Util'

import PaymentSelectionModal from './PaymentSelectionModal'
import ContinuePartialPayment from './ContinuePartialPayment'
import PartialPaymentItems from './PartialPaymentItems'
import ConfirmationModal from './../generic/ConfirmationModal'
import PaidPartialPayments from './PaidPartialPayments'
import PartialPaymentSeat from './PartialPaymentSeat'

import { selectCart, editCart } from '../../store/actions/carts'
import { setToastMessage } from '../../store/actions/app'

import './NewPaymentModal.scss'

const mapStateToProps = state => {
  return {
    carts: state.carts,
    user: state.user,
    devices: state.devices,
    settings: state.settings
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectCart: cart => dispatch(selectCart(cart)),
    editCart: cart => dispatch(editCart(cart)),
    setToastMessage: msg => dispatch(setToastMessage(msg))
  }
}

class PartialPaymentModal extends Component {
  state = {
    paymentType: '',
    paymentTypeLocked: '',
    expectedAmount: 0,
    stillRemaining: 0,
    expectedChange: 0,
    openPaymentSelectionModal: false,
    openContinuePartialPayment: false,
    updatePaymentPerItem: false,
    lockPartialPerItem: false,
    disabledPayButton: false,
    openConfirmationModal: false,
    selectedPartialPayment: -1,
    paidProducts: [],
    activeCart: {}
  }

  handlePaidProducts = products => {
    this.setState({ paidProducts: products })
  }

  handleProductsName = (product, cart) => {
    const found = cart.items.find(i => cartUtils.getCartEntryId(i) === product.id)

    if (!found) {
      return null
    }

    const { language = 'en' } = this.props.settings
    const productName = ProductUtils.getProductName(found.product, language)
    const variantName = ProductUtils.getVariantName(found.product.current_variant, language)

    return `${productName}${variantName !== 'Default' ? ` (${variantName})` : ''}`
  }

  handleChangeValue = (name, value) => {
    if (name === 'expectedAmount' && parseFloat(value) === 0) {
      this.setState({ disabledPayButton: true })
    } else {
      this.setState({ disabledPayButton: false })
    }

    this.setState({ [name]: value }, () => {
      if (name === 'expectedAmount') {
        this.calculateRestAmount()
      }
    })
  }

  calculateRestAmount = () => {
    const rest = parseFloat(
      this.props.carts.selectedCart.total -
        this.props.carts.selectedCart.payments.reduce((acc, current) => {
          return parseFloat(acc) + parseFloat(current.paid)
        }, 0)
    )
    this.setState(
      {
        stillRemaining: (rest - this.state.expectedAmount).toFixed(2)
      },
      () => {
        if (this.state.stillRemaining < 0) {
          this.calculateChangeAmount(this.state.stillRemaining)
        } else {
          this.setState({ expectedChange: (0).toFixed(2) })
        }
      }
    )
  }

  calculateChangeAmount = change => {
    const toChange = Math.abs(change)
    this.setState({ expectedChange: toChange.toFixed(2) }, () => {
      this.setState({ stillRemaining: (0).toFixed(2) })
    })
  }

  updateInputs = () => {
    this.setState({ expectedAmount: parseFloat(this.state.stillRemaining).toFixed(2) }, () => {
      this.setState({ stillRemaining: (0).toFixed(2) })
    })
  }

  handleDeletePayment = (index, cart) => {
    this.setState({ openConfirmationModal: true, selectedPartialPayment: index, activeCart: cart })
  }

  handleCloseConfirmationModal = () => {
    this.setState({ openConfirmationModal: false, selectedPartialPayment: -1 })
  }

  handleConfirmConfirmationModal = async () => {
    const activeCart = this.state.activeCart._id
      ? this.state.activeCart
      : this.props.carts.selectedCart

    const toRemove = parseFloat(
      JSON.parse(JSON.stringify(activeCart.payments[this.state.selectedPartialPayment].paid))
    )
    activeCart.paid -= toRemove
    activeCart.payments.splice(this.state.selectedPartialPayment, 1)
    const still = parseFloat(activeCart.total - activeCart.paid)

    this.setState({
      expectedAmount: still.toFixed(2),
      stillRemaining: (0).toFixed(2),
      lockPartialPerItem: this.handleLockPartialPerItem()
    })

    this.handleCloseConfirmationModal()

    const countr = await AppInstances.getCountrSdk()
    const cart = Object.assign({}, activeCart)

    cartUtils.updateCartServer(cart)

    this.props.editCart(cart)
    localStorage.setItem('CountrLite:Cart-' + cart._id, JSON.stringify(cart))
  }

  handleOpenPaymentSelectionModal = () => {
    const { expectedAmount } = this.state
    if (isNaN(expectedAmount) || expectedAmount === null || expectedAmount <= 0) {
      this.props.setToastMessage('partial_payment_bigger_zero')
      return
    }
    this.setState({ openPaymentSelectionModal: true })
  }

  handleClosePaymentSelectionModal = transaction => {
    this.setState({ openPaymentSelectionModal: false })
    if (transaction !== false) {
      if (transaction === 'partial_payment') {
        this.updateInputs()
        this.handleOpenContinuePartialPayment()
        this.setState({
          updatePaymentPerItem: true,
          lockPartialPerItem: this.state.paymentType === 'value' ? true : false
        })
      } else {
        if (this.props.settings.allowPartialPaymentPrint) {
          const userDefaultReceipt = this.props.user.user.receipt
          const store = this.props.devices.store
          const currency = this.props.devices.store.currency.code
          PrinterUtils.partialReceipt(transaction, userDefaultReceipt, store, currency)
        }
        this.props.handleClosePartialPaymentModal(transaction)
      }
    }
  }

  handleUpdatePaymentPerItem = () => {
    this.setState({ updatePaymentPerItem: !this.state.updatePaymentPerItem })
  }
  
  handleOpenContinuePartialPayment = () => {
    this.setState({ openContinuePartialPayment: true })
    if (this.props.settings.allowPartialPaymentPrint) {
      const userDefaultReceipt = this.props.user.user.receipt
      const store = this.props.devices.store
      const currency = this.props.devices.store.currency.code
      const cart = JSON.parse(
        JSON.stringify(
          this.state.activeCart._id ? this.state.activeCart._id : this.props.carts.selectedCart
        )
      )
      PrinterUtils.partialReceipt(cart, userDefaultReceipt, store, currency)
    }
  }

  handleCloseContinuePartialPayment = () => {
    this.setState({ openContinuePartialPayment: false })
  }

  handleLockPartialPerItem() {
    const payPerValue = this.props.carts.selectedCart.payments.find(p => !p.info)
    return payPerValue ? true : false
  }

  handleInitialValueState() {
    this.setState({
      expectedAmount: parseFloat(
        this.props.carts.selectedCart.total -
          this.props.carts.selectedCart.payments.reduce((acc, current) => {
            return parseFloat(acc) + parseFloat(current.paid)
          }, 0)
      ).toFixed(2),
      stillRemaining: (0).toFixed(2),
      lockPartialPerItem: this.handleLockPartialPerItem()
    })
  }

  handleError = async error => {
    const msg = !!error.message ? error.message : JSON.stringify(error)

    AppInstances.logError({
      message: `Error while sorting products at PartialPaymentItems.js`,
      user: this.props.user.user._id,
      store: this.props.devices.store._id,
      device: this.props.devices.device._id,
      stack: JSON.stringify(error),
      info: msg,
      date: new Date().toISOString()
    })
  }

  componentDidMount = () => {
    this.setState({
      paymentType: 'item'
    })
    this.handleInitialValueState()
  }

  render() {
    const cart = this.props.carts.selectedCart

    return (
      <Dialog
        fullWidth={true}
        open={this.props.openPartialPaymentModal}
        onClose={(event, reason) => {
          Util.handleModalDisableBackdrop(
            reason,
            this.props.handleClosePartialPaymentModal.bind(this)
          )
        }}
        aria-labelledby="form-dialog-title"
        fullScreen={isMobileOnly}>
        <DialogTitle id="form-dialog-title">
          <Text id="partial_payment" />
          {' - '}
          {this.props.perSeat ? (
            <Text id="partial_payment_seat" />
          ) : (
            <Text id="partial_payment_item" />
          )}
        </DialogTitle>
        <DialogContent>
          {this.props.perSeat ? (
            <PartialPaymentSeat
              handleChangeValue={this.handleChangeValue}
              updatePaymentPerItem={this.state.updatePaymentPerItem}
              handleUpdatePaymentPerItem={this.handleUpdatePaymentPerItem}
              handlePaidProducts={this.handlePaidProducts}
              handleError={this.handleError}
              activeCart={cart}
            />
          ) : (
            <PartialPaymentItems
              handleChangeValue={this.handleChangeValue}
              updatePaymentPerItem={this.state.updatePaymentPerItem}
              handleUpdatePaymentPerItem={this.handleUpdatePaymentPerItem}
              handlePaidProducts={this.handlePaidProducts}
              handleError={this.handleError}
              activeCart={cart}
              device={this.props.devices.device}
            />
          )}
          {/* payments array items */}
          <PaidPartialPayments
            cart={cart}
            handleProductsName={this.handleProductsName}
            handleDeletePayment={this.handleDeletePayment}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.props.handleClosePartialPayment()}>
            <Text id="cancel" />
          </Button>
          {this.state.paymentType !== '' && (
            <Button
              variant="contained"
              // className="payment-types-button"
              onClick={this.handleOpenPaymentSelectionModal}
              disabled={this.state.disabledPayButton}
              style={{
                backgroundColor: this.state.disabledPayButton ? '#b0c6d7' : '#318ed5',
                color: '#fff'
              }}>
              <Text id="payment_method" />
            </Button>
          )}
        </DialogActions>
        {this.state.openPaymentSelectionModal && (
          <PaymentSelectionModal
            openPaymentSelectionModal={this.state.openPaymentSelectionModal}
            handleClosePaymentSelectionModal={this.handleClosePaymentSelectionModal}
            total={this.state.expectedAmount}
            change={this.state.expectedChange}
            paidProducts={this.state.paidProducts}
          />
        )}
        {this.state.openContinuePartialPayment && (
          <ContinuePartialPayment
            openContinue={this.state.openContinuePartialPayment}
            handleClose={this.handleCloseContinuePartialPayment}
            cart={this.props.carts.selectedCart}
          />
        )}
        {this.state.openConfirmationModal && (
          <ConfirmationModal
            openConfirmation={this.state.openConfirmationModal}
            handleCloseConfirmation={this.handleCloseConfirmationModal}
            confirmBtn={this.handleConfirmConfirmationModal}
            confirmationTitle={<Text id="remove_payment" />}
            confirmationText={<Text id="do_you_want_remove_payment" />}
            type="remove_partial_payment"
          />
        )}
      </Dialog>
    )
  }
}

const PartialPaymentModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartialPaymentModal)
export default PartialPaymentModalConnected
