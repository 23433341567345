import {
  SHOW_CART,
  SHOW_SETTINGS,
  SHOW_MAIN_MENU,
  HIDE_TOAST_MESSAGE,
  SET_TOAST_MESSAGE,
  SET_TRIAL_EXPIRED,
  SET_OUTSTANDING_INVOICES,
  SET_DISCONNECTED_TIME,
  SET_DESKTOP_CONNECTED,
  SHOW_KDS,
  SHOW_CHAT,
  SET_IS_DESKTOP,
  SET_THEME,
  SHOW_EXTERNAL_URL
} from './../actions/actionTypes'

const initialState = {
  cart: false,
  showSettingsDialog: false,
  showLeftMenu: false,
  isToastDisplayed: false,
  toastMsg: '',
  toastOpt: {},
  toastAction: '',
  trialExpired: false,
  outstandingInvoices: false,
  disconnectedTime: '',
  disconnectedReason: '',
  isDesktopConnected: false,
  showKds: false,
  showChat: false,
  isDesktop: false,
  desktopVersion: '',
  desktopIP: '',
  theme: {},
  openExternalUrlIframe: {
    show: false,
    url: ''
  }
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CART:
      return {
        ...state,
        cart: !state.cart
      }

    case SHOW_KDS:
      return {
        ...state,
        showKds: action.payload
      }

    case SHOW_EXTERNAL_URL:
      return {
        ...state,
        openExternalUrlIframe: action.payload
      }

    case SHOW_CHAT:
      return {
        ...state,
        showChat: action.payload
      }
    case SHOW_SETTINGS:
      return {
        ...state,
        showSettingsDialog: !state.showSettingsDialog
      }
    case SHOW_MAIN_MENU:
      return {
        ...state,
        showLeftMenu: !state.showLeftMenu
      }
    case HIDE_TOAST_MESSAGE:
      return {
        ...state,
        isToastDisplayed: false,
        toastMsg: '',
        toastOpt: {},
        toastAction: ''
      }
    case SET_TOAST_MESSAGE:
      return {
        ...state,
        toastMsg: action.payload.message,
        toastOpt: action.payload.opt,
        toastAction: action.payload.action,
        isToastDisplayed: true
      }
    case SET_TRIAL_EXPIRED:
      return {
        ...state,
        trialExpired: true
      }
    case SET_OUTSTANDING_INVOICES:
      return {
        ...state,
        outstandingInvoices: action.payload
      }
    case SET_DISCONNECTED_TIME:
      return {
        ...state,
        disconnectedTime: action.payload.time,
        disconnectedReason: action.payload.reason
      }
    case SET_DESKTOP_CONNECTED:
      return {
        ...state,
        isDesktopConnected: action.payload
      }
    case SET_IS_DESKTOP:
      return {
        ...state,
        isDesktop: true,
        desktopVersion: action.payload.version || '',
        desktopIP: action.payload.ip || ''
      }
    case SET_THEME:
      return {
        ...state,
        theme: action.payload
      }
    default:
      return state
  }
}

export default appReducer
