import { PureComponent } from 'react'
import { connect } from 'react-redux'

import { AppInstances } from '../../../utils/countrSdkInstance'
import { RequestQueue } from '../../../utils/RequestQueue'
import countriesList from '../../../utils/countriesList'

import { addCustomerCart, selectCart } from '../../../store/actions/carts'
import { addCustomer } from '../../../store/actions/customers'
import { setToastMessage } from '../../../store/actions/app'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import InputLabel from '@material-ui/core/InputLabel'
import Switch from '@material-ui/core/Switch'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'

import { Text, translate } from 'react-internationalization'
import escapeStringRegexp from 'escape-string-regexp'

import './CustomerModal.css'

const mapStateToProps = state => {
  return {
    devices: state.devices,
    carts: state.carts,
    customers: state.customers
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addCustomerCart: customers => dispatch(addCustomerCart(customers)),
    selectCart: cart => dispatch(selectCart(cart)),
    addCustomer: customer => dispatch(addCustomer(customer)),
    setToastMessage: msg => dispatch(setToastMessage(msg))
  }
}

const PRIMARY_TYPE = 'primary'
const SECONDARY_TYPE = 'secondary'
const BILLING_KEY = 'billing'
const SHIPPING_KEY = 'shipping'

class CustomerModal extends PureComponent {
  state = {
    searchedCustomer: '',
    customersList: [],
    filterList: [],
    customerClicked: false,
    selectedCustomer: {},
    newCustomer: false,
    newCustomerObj: {
      first_name: '',
      last_name: '',
      phone: '',
      mailing_list: false,
      email: '',
      billing: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        number: '',
        number_ext: '',
        payment_saved: false,
        state: '',
        zip: '',
        vat: '',
        registration: ''
      },
      shipping: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        number: '',
        number_ext: '',
        state: '',
        zip: ''
      },
      store_credit: [],
      total_spend: 0,
      total_visits: 0
    },
    showCompany: false,
    showShipping: false,
    newCustomerNotValid: false,
    customerAddressMandatory: false
  }

  handleClose = () => {
    this.props.handleCloseCustomerDialog()
  }

  locallySearch = value => {
    return new Promise(resolve => {
      const search = this.state.customersList.filter(customer => {
        const { first_name, last_name, email } = customer
        const _first_name = !!first_name ? first_name.toLowerCase() : ''
        const _last_name = !!last_name ? last_name.toLowerCase() : ''
        const _email = !!email ? email.toLowerCase() : ''

        if (_first_name.includes(value) || _last_name.includes(value) || _email.includes(value)) {
          return customer
        }
      })

      resolve(search)
    })
  }

  serverSearch = async (sdk, value) => {
    return sdk.customers.search({
      text: escapeStringRegexp(value)
    })
  }

  handleChange = async event => {
    const value = !!event.target.value ? event.target.value.toLowerCase() : ''

    if (value.length > 2) {
      try {
        const countr = await AppInstances.getCountrSdk()
        const result = await Promise.all([
          this.locallySearch(value),
          this.serverSearch(countr, value)
        ])
        this.handleResult(result, value)
      } catch (error) {
        const res = await this.locallySearch(value)
        this.handleResult([res, []], value)
      }
    }
  }

  handleResult = (result, value) => {
    this.setState({
      searchedCustomer: value,
      filterList: result[0].length < result[1].length ? result[1] : result[0]
    })
  }

  clickCustomer = customer => {
    this.setState({ customerClicked: true, selectedCustomer: customer })
  }

  unclickCustomer = () => {
    this.setState({ customerClicked: false, selectedCustomer: {} })
  }

  addNewCustomer = () => {
    this.setState({ newCustomer: true })
  }

  handleChangeNewCustomer = name => event => {
    const copy = Object.assign({}, this.state.newCustomerObj)
    if (name === 'mailing_list') {
      copy.mailing_list = !this.state.newCustomerObj.mailing_list
    } else {
      copy[name] = event.target.value
    }
    this.setState({ newCustomerObj: copy })
  }

  handleChangeSwitch = name => {
    if (name === 'showCompany') {
      this.setState({ showCompany: !this.state.showCompany }, () => {
        const el = document.getElementById('show-company')
        el.classList.remove('collapse-default')

        if (this.state.showCompany) {
          el.classList.add('collapse-open')
          el.classList.remove('collapse-close')
        } else {
          el.classList.remove('collapse-open')
          el.classList.add('collapse-close')
        }
      })
    } else if (name === 'showShipping') {
      this.setState({ showShipping: !this.state.showShipping }, () => {
        const el = document.getElementById('show-shipping')
        el.classList.remove('collapse-default')

        if (this.state.showShipping) {
          el.classList.add('collapse-open')
          el.classList.remove('collapse-close')
        } else {
          el.classList.remove('collapse-open')
          el.classList.add('collapse-close')
        }
      })
    }
  }

  hangleChangeAddress = (type, name) => event => {
    const copy = Object.assign({}, this.state.newCustomerObj)
    copy[type][name] = event.target.value
    this.setState({ newCustomerObj: copy })
  }

  addCustomerToCart = () => {
    const cart = this.props.carts.selectedCart
    cart.customer = this.state.selectedCustomer
    this.props.selectCart(cart)
    this.props.handleCloseCustomerDialog()
    localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(cart))

    AppInstances.getCountrSdk().then(socket => {
      socket.carts.update(cart._id, cart).then(
        updatedCart => {},
        error => {
          RequestQueue.enqueueAction({
            type: 'carts',
            action: 'update',
            payload: cart
          })
        }
      )
    })
  }

  addNewCustomerToCart = () => {
    if (!this.newCustomerValid()) {
      this.setState({ newCustomerNotValid: false })
      const cart = this.props.carts.selectedCart
      AppInstances.getCountrSdk().then(socket => {
        socket.customers.create(this.state.newCustomerObj).then(customer => {
          this.props.addCustomer(customer)
          cart.customer = customer
          this.props.selectCart(cart)
          this.props.handleCloseCustomerDialog()
          localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(cart))
          socket.carts.update(cart._id, cart).then(
            updatedCart => {},
            error => {
              RequestQueue.enqueueAction({
                type: 'carts',
                action: 'update',
                payload: cart
              })
            }
          )
        })
      })
    } else {
      this.setState({ newCustomerNotValid: true })
    }
  }

  sendInvoiceCustomer = () => {
    const customer = Object.assign(
      {},
      this.state.newCustomer ? this.state.newCustomerObj : this.state.selectedCustomer
    )

    if (this.checkVatRegistration(customer)) {
      this.props.handleCloseCustomerDialog(customer)
    } else {
      this.props.setToastMessage('customer_not_valid_vat')
      return
    }
  }

  checkVatRegistration = customer => {
    return (
      customer.email &&
      customer.billing &&
      customer.billing.vat &&
      customer.billing.vat.length //&&
      // customer.billing.registration &&
      // customer.billing.registration.length
    )
  }

  shouldShowSendButton = () => {
    return (
      Object.keys(this.state.selectedCustomer).length ||
      this.checkVatRegistration(this.state.newCustomerObj)
    )
  }

  checkAddress = (customer, type) => {
    return customer[type] !== undefined &&
      customer[type].address1 !== undefined &&
      customer[type].address1 !== null &&
      customer[type].address1 !== ''
      ? true
      : false
  }

  newCustomerValid = () => {
    let result = true
    const simpleCheck = ['first_name', 'last_name', 'phone', 'email']
    const requiredCustomerFieldsCheck = [
      'address1',
      'city',
      'country',
      'number',
      'state',
      'zip'
    ]

    if (!!this.props.devices.store?.options?.customerAddressMandatory) {
      result = requiredCustomerFieldsCheck.filter(l => this.state.newCustomerObj.shipping[l] === '').length > 0 ? true : false

      if (result) {
        return result
      }
    }
    
    result = simpleCheck.filter(l => this.state.newCustomerObj[l] === '').length > 0 ? true : false

    return result
  }

  getCountryName = country => {
    let name = ''
    if (country && country !== null && country !== '') {
      const aux = countriesList.find(c => c.code === country)
      name = aux && aux.name ? aux.name : ''
    }

    return name
  }

  handleAddSeperator = textString => {
    return textString.length ? ' - ' : ''
  }

  handleCustomersAddress = (type, key) => {
    let result = ''
    if (type === PRIMARY_TYPE) {
      if (this.state.selectedCustomer[key]?.address1) {
        result += `${this.state.selectedCustomer[key].address1}`
      }
      if (this.state.selectedCustomer[key]?.number) {
        result += `, ${this.state.selectedCustomer[key].number}`
      }
      if (!!this.state.selectedCustomer[key]?.address2) {
        result += ` (${this.state.selectedCustomer[key].address2})`
      }
    }

    if (type === SECONDARY_TYPE) {
      if (this.state.selectedCustomer[key]?.zip) {
        result += `${this.state.selectedCustomer[key].zip}`
      }
      if (this.state.selectedCustomer[key].city) {
        result += `${this.handleAddSeperator(result)}${this.state.selectedCustomer[key].city}`
      }
      if (this.state.selectedCustomer[key].country) {
        result += `${this.handleAddSeperator(result)}${this.getCountryName(
          this.state.selectedCustomer[key].country
        )}`
      }
    }

    return result
  }

  handleIsMandatoryAddress = () => {
    if (this.state.customerAddressMandatory) {
      return this.state.newCustomerNotValid
    }
    return false
  }

  handleGetShippingText = str => {
    return `${translate(str)}${
      !!this.props.devices.store?.options?.customerAddressMandatory ? ' *' : ''
    }`
  }

  componentDidMount = () => {
    const defaultCountry = { ...this.state.newCustomerObj }
    defaultCountry.billing.country = 'nl'
    defaultCountry.shipping.country = 'nl'

    this.setState({
      customersList: this.props.customers.customers,
      newCustomerObj: defaultCountry,
      customerAddressMandatory: !!this.props.devices.store?.options?.customerAddressMandatory,
      showShipping: !!this.props.devices.store?.options?.customerAddressMandatory
    })
  }

  render() {
    return (
      <Dialog
        open={this.props.openCustomerDialog}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Text id="customerid" />
          <IconButton className="add-new-customer" onClick={this.addNewCustomer}>
            <span className="icon-profile-loyalty" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!this.state.newCustomer ? (
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={12}>
                <TextField
                  className="search-customer-textfield"
                  label={<Text id="search_for_customer" />}
                  fullWidth={true}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} className="customers-div">
                {!this.state.customerClicked && (
                  <List
                    component="nav"
                    className={`customer-list ${
                      this.state.customerClicked ? 'customer-clicked-animation' : ''
                    }`}>
                    {this.state.filterList.map(customer => (
                      <ListItem
                        button
                        key={customer._id}
                        onClick={() => this.clickCustomer(customer)}>
                        <span className="icon-profile-loyalty" />
                        <ListItemText
                          primary={`${customer.first_name} ${customer.last_name} (${customer.email})`}
                        />
                        <span className="icon-right-arrow" />
                      </ListItem>
                    ))}
                  </List>
                )}
                {this.state.customerClicked && (
                  <div
                    className={`${
                      this.state.customerClicked ? 'customer-selected-animation' : ''
                    }`}>
                    <div className="customer-info-header">
                      <IconButton onClick={this.unclickCustomer}>
                        <span className="icon-left-arrow" />
                      </IconButton>
                      <InputLabel className="customer-info-header-label">
                        <Text id="customer_info" />
                      </InputLabel>
                    </div>
                    <List>
                      <ListItem>
                        <span className="icon-profile-loyalty" />
                        <ListItemText
                          primary={`${this.state.selectedCustomer.first_name} ${this.state.selectedCustomer.last_name}`}
                          secondary={this.state.selectedCustomer.email}
                        />
                      </ListItem>
                      {this.checkAddress(this.state.selectedCustomer, 'billing') && (
                        <ListItem>
                          <span className="icon-business" />
                          <ListItemText
                            primary={this.handleCustomersAddress(PRIMARY_TYPE, BILLING_KEY)}
                            secondary={this.handleCustomersAddress(SECONDARY_TYPE, BILLING_KEY)}
                          />
                        </ListItem>
                      )}
                      {this.checkAddress(this.state.selectedCustomer, 'shipping') && (
                        <ListItem>
                          <span className="icon-country" />
                          <ListItemText
                            primary={this.handleCustomersAddress(PRIMARY_TYPE, SHIPPING_KEY)}
                            secondary={this.handleCustomersAddress(SECONDARY_TYPE, SHIPPING_KEY)}
                          />
                        </ListItem>
                      )}
                      {this.state.selectedCustomer.phone !== undefined &&
                        this.state.selectedCustomer.phone !== null &&
                        this.state.selectedCustomer.phone !== '' && (
                          <ListItem>
                            <span className="icon-phone-support" />
                            <ListItemText primary={this.state.selectedCustomer.phone} />
                          </ListItem>
                        )}
                      {this.state.selectedCustomer.last_visit !== undefined &&
                        this.state.selectedCustomer.last_visit !== null && (
                          <ListItem>
                            <span className="icon-clock" />
                            <ListItemText
                              primary={
                                'Last visit: ' +
                                new Date(this.state.selectedCustomer.last_visit).toLocaleString()
                              }
                              secondary={
                                'Total visits: ' + this.state.selectedCustomer.total_visits
                              }
                            />
                          </ListItem>
                        )}
                      {this.checkAddress(this.state.selectedCustomer, 'billing') && (
                        <ListItem>
                          <span className="icon-business-category" />
                          <ListItemText
                            primary={`${translate('vat_no')}: ${
                              this.state.selectedCustomer.billing.vat
                            }`}
                            secondary={`${translate('company_registration_no')}: ${
                              this.state.selectedCustomer.billing.registration
                            }`}
                          />
                        </ListItem>
                      )}
                    </List>
                  </div>
                )}
              </Grid>
            </Grid>
          ) : (
            <div>
              {/* Customer details */}
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                  <InputLabel className="new-customer-section-header-label">
                    <Text id="add_new_customer" />
                  </InputLabel>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className="new-customer-textfield"
                    label={`${translate('firstid')} *`}
                    fullWidth={true}
                    value={this.state.newCustomerObj.first_name}
                    onChange={this.handleChangeNewCustomer('first_name')}
                    error={this.state.newCustomerNotValid}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className="new-customer-textfield"
                    label={`${translate('lastid')} *`}
                    fullWidth={true}
                    value={this.state.newCustomerObj.last_name}
                    onChange={this.handleChangeNewCustomer('last_name')}
                    error={this.state.newCustomerNotValid}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className="new-customer-textfield-email"
                    label={`${translate('email_address')} *`}
                    type="email"
                    fullWidth={true}
                    value={this.state.newCustomerObj.email}
                    onChange={this.handleChangeNewCustomer('email')}
                    error={this.state.newCustomerNotValid}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className="new-customer-textfield"
                    label={`${translate('phone_number')} *`}
                    type="tel"
                    fullWidth={true}
                    value={this.state.newCustomerObj.phone}
                    onChange={this.handleChangeNewCustomer('phone')}
                    error={this.state.newCustomerNotValid}
                  />
                </Grid>
                <Grid item xs={6} className="new-customer-switch">
                  <InputLabel className="mailing_list">
                    <Text id="mailing_list_permission" />
                  </InputLabel>
                  <Switch
                    checked={this.state.newCustomerObj.mailing_list}
                    onChange={this.handleChangeNewCustomer('mailing_list')}
                    className="mailing_list"
                    color="default"
                    style={{
                      color: this.state.newCustomerObj.mailing_list ? '#318ed5' : '#616161'
                    }}
                  />
                </Grid>
              </Grid>
              <Divider className="margin-divider" />
              {/* Billing */}
              <Grid container>
                <Grid item xs={12}>
                  <InputLabel className="mailing_list new-customer-section-header-label">
                    <Text id="company_address" />
                  </InputLabel>
                  <Switch
                    checked={this.state.showCompany}
                    onChange={() => this.handleChangeSwitch('showCompany')}
                    className="mailing_list"
                    color="default"
                    style={{ color: this.state.showCompany ? '#318ed5' : '#616161' }}
                  />
                </Grid>
              </Grid>
              <Grid container className="collapse-default" id="show-company">
                <Grid item xs={12}>
                  <TextField
                    className="new-customer-textfield"
                    label={<Text id="companyid" />}
                    fullWidth={true}
                    value={this.state.newCustomerObj.billing.organization}
                    onChange={this.hangleChangeAddress('billing', 'organization')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className="new-customer-textfield"
                    label={<Text id="address" />}
                    fullWidth={true}
                    value={this.state.newCustomerObj.billing.address1}
                    onChange={this.hangleChangeAddress('billing', 'address1')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    className="new-customer-textfield"
                    label={<Text id="number" />}
                    type="number"
                    fullWidth={true}
                    value={this.state.newCustomerObj.billing.number}
                    onChange={this.hangleChangeAddress('billing', 'number')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    className="new-customer-textfield"
                    label={<Text id="number_extension" />}
                    fullWidth={true}
                    value={this.state.newCustomerObj.billing.number_ext}
                    onChange={this.hangleChangeAddress('billing', 'number_ext')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className="new-customer-textfield"
                    label={<Text id="address_continued" />}
                    fullWidth={true}
                    value={this.state.newCustomerObj.billing.address2}
                    onChange={this.hangleChangeAddress('billing', 'address2')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className="new-customer-textfield"
                    label={<Text id="city" />}
                    fullWidth={true}
                    value={this.state.newCustomerObj.billing.city}
                    onChange={this.hangleChangeAddress('billing', 'city')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className="new-customer-textfield"
                    label={<Text id="zip" />}
                    fullWidth={true}
                    value={this.state.newCustomerObj.billing.zip}
                    onChange={this.hangleChangeAddress('billing', 'zip')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className="new-customer-textfield"
                    label={<Text id="state" />}
                    fullWidth={true}
                    value={this.state.newCustomerObj.billing.state}
                    onChange={this.hangleChangeAddress('billing', 'state')}
                  />
                </Grid>
                <Grid item xs={6} className="new-customer-country-align">
                  <InputLabel
                    className={
                      this.state.newCustomerObj.billing.country.length > 0
                        ? 'new-customer-select-label-selected'
                        : 'new-customer-select-label'
                    }>
                    <Text id="country" />
                  </InputLabel>
                  <Select
                    value={this.state.newCustomerObj.billing.country}
                    onChange={this.hangleChangeAddress('billing', 'country')}
                    fullWidth={true}
                    autoWidth={true}
                    className="new-customer-select">
                    {countriesList.map(country => {
                      return (
                        <MenuItem key={country.code} value={country.code}>
                          <font className="country-name">{country.name}</font>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className="new-customer-textfield"
                    label={<Text id="vat_no" />}
                    fullWidth={true}
                    value={this.state.newCustomerObj.billing.vat}
                    onChange={this.hangleChangeAddress('billing', 'vat')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className="new-customer-textfield"
                    label={<Text id="company_registration_no" />}
                    fullWidth={true}
                    value={this.state.newCustomerObj.billing.registration}
                    onChange={this.hangleChangeAddress('billing', 'registration')}
                  />
                </Grid>
              </Grid>
              <Divider className="margin-divider" />
              {/* Shipping */}
              <Grid container>
                <Grid item xs={12}>
                  <InputLabel className="mailing_list new-customer-section-header-label">
                    <Text id="shipping_address" />
                  </InputLabel>
                  {!this.props.devices.store?.options?.customerAddressMandatory ? (
                    <Switch
                      checked={this.state.showShipping}
                      onChange={() => this.handleChangeSwitch('showShipping')}
                      className="mailing_list"
                      color="default"
                      style={{ color: this.state.showShipping ? '#318ed5' : '#616161' }}
                    />
                  ) : (
                    <br />
                  )}
                </Grid>
                <Grid
                  container
                  className={
                    this.state.customerAddressMandatory ? 'collapse-open' : 'collapse-default'
                  }
                  id="show-shipping">
                  <Grid item xs={6}>
                    <TextField
                      className="new-customer-textfield"
                      label={this.handleGetShippingText('address')}
                      fullWidth={true}
                      value={this.state.newCustomerObj.shipping.address1}
                      onChange={this.hangleChangeAddress('shipping', 'address1')}
                      error={this.handleIsMandatoryAddress()}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      className="new-customer-textfield"
                      label={this.handleGetShippingText('number')}
                      type="number"
                      fullWidth={true}
                      value={this.state.newCustomerObj.shipping.number}
                      onChange={this.hangleChangeAddress('shipping', 'number')}
                      error={this.handleIsMandatoryAddress()}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      className="new-customer-textfield"
                      label={<Text id="number_extension" />}
                      fullWidth={true}
                      value={this.state.newCustomerObj.shipping.number_ext}
                      onChange={this.hangleChangeAddress('shipping', 'number_ext')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className="new-customer-textfield"
                      label={<Text id="address_continued" />}
                      fullWidth={true}
                      value={this.state.newCustomerObj.shipping.address2}
                      onChange={this.hangleChangeAddress('shipping', 'address2')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      className="new-customer-textfield"
                      label={this.handleGetShippingText('city')}
                      fullWidth={true}
                      value={this.state.newCustomerObj.shipping.city}
                      onChange={this.hangleChangeAddress('shipping', 'city')}
                      error={this.handleIsMandatoryAddress()}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      className="new-customer-textfield"
                      label={this.handleGetShippingText('zip')}
                      fullWidth={true}
                      value={this.state.newCustomerObj.shipping.zip}
                      onChange={this.hangleChangeAddress('shipping', 'zip')}
                      error={this.handleIsMandatoryAddress()}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      className="new-customer-textfield"
                      label={this.handleGetShippingText('state')}
                      fullWidth={true}
                      value={this.state.newCustomerObj.shipping.state}
                      onChange={this.hangleChangeAddress('shipping', 'state')}
                      error={this.handleIsMandatoryAddress()}
                    />
                  </Grid>
                  <Grid item xs={6} className="new-customer-country-align">
                    <InputLabel
                      className={
                        this.state.newCustomerObj.shipping.country.length > 0
                          ? 'new-customer-select-label-selected'
                          : 'new-customer-select-label'
                      }>
                        {this.handleGetShippingText('country')}
                    </InputLabel>
                    <Select
                      value={this.state.newCustomerObj.shipping.country}
                      onChange={this.hangleChangeAddress('shipping', 'country')}
                      fullWidth={true}
                      autoWidth={true}
                      className="new-customer-select">
                      {countriesList.map(country => {
                        return (
                          <MenuItem key={country.code} value={country.code}>
                            <font className="country-name">{country.name}</font>
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={this.handleClose}>
            <Text id="close" />
          </Button>
          {this.props.isInvoicePayment && this.shouldShowSendButton() ? (
            <Button style={{ color: '#318ed5' }} onClick={this.sendInvoiceCustomer}>
              <Text id="send_to_customer" />
            </Button>
          ) : (
            <div>
              {this.state.customerClicked && !this.state.newCustomer && (
                <Button style={{ color: '#318ed5' }} onClick={this.addCustomerToCart}>
                  <Text id="add_customer" />
                </Button>
              )}
              {this.state.newCustomer && (
                <Button style={{ color: '#318ed5' }} onClick={this.addNewCustomerToCart}>
                  <Text id="add_new_customer" />
                </Button>
              )}
            </div>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

const CustomerModalConnected = connect(mapStateToProps, mapDispatchToProps)(CustomerModal)
export default CustomerModalConnected
