import Dexie from 'dexie'
import * as Comlink from 'comlinkjs'
import { AppInstances } from './utils/countrSdkInstance'

const CountrWebWorker = Comlink.proxy(
  new Worker(`${window.location.origin}/countrworker.worker.js`)
)

const countrWebWorker = new CountrWebWorker()

export default class IndexedDBWrapper {
  static indexedDBInstance = null
  device = {}
  user = {}
  countrApi = null
  lastDelta = new Date('1970-01-01').toISOString()

  constructor(user, device, v = 1) {
    this.device = device
    this.user = user
    IndexedDBWrapper.indexedDBInstance = new Dexie(user._id)

    IndexedDBWrapper.indexedDBInstance.version(1).stores({
      products: '_id, position, name, ean, *i_categories',
      categories: '_id, created_at, name, category_id',
      device: '_id',
      user: '_id'
    })

    IndexedDBWrapper.indexedDBInstance.version(2).stores({
      transactions: null
    })

    IndexedDBWrapper.indexedDBInstance.version(3).stores({
      products: '_id, position, name, *ean, *i_categories'
    })

    IndexedDBWrapper.indexedDBInstance.on('ready', () => {})

    IndexedDBWrapper.indexedDBInstance
      .open()
      .then(async () => {
        this.countrApi = await countrWebWorker
        await this.init(device)
      })
      .catch(e => {
        this.logError({
          msg: 'Failed to open IndexedDB Database',
          stack: e.stack
        })
      })

    // this.showEstimatedQuota()
  }

  async init(device) {
    // Saving current lastDelta for check deleted products after initialize
    const last = localStorage.getItem(`${device._id}_products_lastDelta`)
    if (last) {
      localStorage.setItem(`${device._id}_lastDelta_deleted`, last)
    }

    await Promise.all([
      this.populateProducts(device),
      // this.populateTransactions(device),
      this.populateCategories(device)
    ]).catch(e => {
      this.logError({
        msg: 'Error in Async Init IndexedDBWrapper method',
        stack: e.stack
      })
    })

    localStorage.setItem('freshdelta', false)
  }

  logError = async obj => {
    const errorObj = {
      source: process.env.REACT_APP_ERROR_SOURCE,
      message: `${obj.msg}, users: ${this.user.username},
        _ID: ${this.user._id}, device id: ${this.device._id}`,
      user: this.user._id,
      store: this.device.store._id,
      device: this.device._id,
      stack: JSON.stringify(obj.stack),
      date: new Date().toISOString()
    }

    await AppInstances.getCountrSdk()
    AppInstances.logError(errorObj)
  }

  async cleanTable(tableName) {
    try {
      await IndexedDBWrapper.indexedDBInstance[tableName].clear()
    } catch (e) {
      this.logError({
        msg: `Error trying to clean IndexedDB table named ${tableName}`,
        stack: e.stack
      })
    }
  }

  static clearDatabase() {
    try {
      return IndexedDBWrapper.indexedDBInstance.delete()
    } catch (e) {
      this.logError({
        msg: `Error trying to clean IndexedDB database`,
        stack: e.stack
      })
    }
  }

  async populateCategories(device, lastDelta) {
    const delta = this.calculateDelta(device, lastDelta)

    return await this.countrApi
      .getData(
        process.env.REACT_APP_API_SERVER,
        window.localStorage.getItem('access_token'),
        this.user._id,
        device.store._id,
        delta,
        {
          name: 'categories',
          endpoint: 'categories/delta'
        }
      )
      .then(data => {
        // Success returned and updating Delta date
        localStorage.setItem(
          `${device._id}_categories_lastDelta`,
          new Date().toISOString() // Current date Delta
        )
      })
      .catch(console.log)
  }

  calculateDelta(device, lastDelta) {
    if (!device || !device._id) {
      throw new Error('To calculate Delta date you have to provide a Device object')
    }

    return lastDelta
      ? lastDelta
      : localStorage.getItem(`${device._id}_products_lastDelta`) ||
          new Date('1970-01-01').toISOString()
  }

  async populateProducts(device, lastDelta) {
    const delta = this.calculateDelta(device, lastDelta)
    if (delta === '1970-01-01T00:00:00.000Z') {
      localStorage.setItem('freshdelta', true)
    }

    return await this.countrApi
      .getData(
        process.env.REACT_APP_API_SERVER,
        window.localStorage.getItem('access_token'),
        this.user._id,
        device.store._id,
        delta,
        {
          name: 'products',
          endpoint: 'products/delta'
        },
        [
          {
            name: 'ean',
            field: 'variants',
            item: 'ean'
          },
          {
            name: 'i_categories',
            field: 'categories',
            item: '_id'
          }
        ]
      )
      .then(data => {
        // Success returned and updating Delta date
        localStorage.setItem(
          `${device._id}_products_lastDelta`,
          new Date().toISOString() // Current date Delta
        )
      })
      .catch(e => {
        this.logError({
          msg: `Error trying to fetch Countr Api - populateProducts`,
          stack: e.stack
        })
      })
  }

  async populateTransactions(device, lastDelta) {
    const delta = lastDelta
      ? lastDelta
      : localStorage.getItem(`${device._id}_transactions_lastDelta`) ||
        new Date('1970-01-01').toISOString()

    return await this.countrApi
      .getData(
        process.env.REACT_APP_API_SERVER,
        window.localStorage.getItem('access_token'),
        this.user._id,
        device.store._id,
        delta,
        {
          name: 'transactions',
          endpoint: 'transactions/delta'
        }
      )
      .then(() => {
        // Success returned and updating Delta date
        localStorage.setItem(
          `${device._id}_transactions_lastDelta`,
          new Date().toISOString() // Current date Delta
        )
      })
      .catch(e => {
        this.logError({
          msg: `Error trying to fetch Countr Api - populateTransactions`,
          stack: e.stack
        })
      })
  }

  /**
   *
   */
  async showEstimatedQuota() {
    if (navigator.storage && navigator.storage.estimate) {
      const estimation = await navigator.storage.estimate()
      console.log(`Quota: ${estimation.quota}`)
      console.log(`Usage: ${estimation.usage}`)
    } else {
      this.logError({
        msg: `StorageManager not found - showEstimatedQuota`
      })
    }
  }

  /**
   *
   */
  async isStoragePersisted() {
    return (
      (await navigator.storage) &&
      navigator.storage.persisted &&
      navigator.storage.persisted().then(async isPersisted => {
        if (isPersisted) {
          console.log(':) Storage is successfully persisted.')
        } else {
          console.log(':( Storage is not persisted.')
          console.log('Trying to persist..:')
          if (await navigator.storage.persist()) {
            console.log(':) We successfully turned the storage to be persisted.')
          } else {
            console.log(':( Failed to make storage persisted')
          }
        }
      })
    )
  }

  /**
   * Search by field name
   * @param {String} name
   * @param {String} field
   * @param {String} collection
   */
  static async searchDbByFields(value, field, collection, sortBy) {
    return IndexedDBWrapper.indexedDBInstance
      .transaction('r', collection, async () => {
        const table = IndexedDBWrapper.indexedDBInstance.table(collection)

        const promises = []
        const resultPromises = []

        if (Array.isArray(field)) {
          field.forEach(async item => {
            promises.push(table.orderBy(item).uniqueKeys())
          })
        } else {
          throw new Error('Fields to search need to be an array')
        }

        const results = await Promise.all(promises)

        const matchProducts = results.flat().filter(prod => {
          if (typeof prod === 'string') {
            return ~prod.toLowerCase().indexOf(value.toLowerCase().trim())
          } else if (typeof prod === 'object' && prod.length === 1) {
            return ~prod[0].toLowerCase().indexOf(value.toLowerCase().trim())
          } else {
            return false
          }
        })

        if (Array.isArray(field)) {
          field.forEach(async item => {
            const currentQuery = table.where(item).anyOfIgnoreCase(...matchProducts)

            if (sortBy) {
              currentQuery.sortBy('name')
            }

            resultPromises.push(currentQuery.toArray())
          })
        } else {
          throw new Error('Fields to search need to be an array')
        }

        return (await Promise.all(resultPromises)).flat()
      })
      .catch(e => {
        this.logError({
          msg: `Error trying to search IndexedDB - 
            searchDbByFields - value: ${value}, field: ${field}, collection: ${collection}, 
            sortBy: ${sortBy}`,
          stack: e.stack
        })
      })
  }

  /**
   *
   */
  static async searchByIdAndUpdateOrAdd(item, collection, action) {
    return IndexedDBWrapper.indexedDBInstance.transaction('rw', collection, async () => {
      const table = IndexedDBWrapper.indexedDBInstance.table(collection)

      try {
        if (action === 'create') {
          table.add(item)
        } else {
          table.update(item._id, item)
        }
        return `Product ${item._id} - ${item.name} ${action}d`
      } catch (error) {
        this.logError({
          msg: `Failed to add product ${item._id} - ${item.name} IndexedDB Database`,
          stack: JSON.stringify(error)
        })
        return null
      }
    })
  }

  /**
   *
   */
  static async searchByIdAndDelete(itemsId, collection) {
    return IndexedDBWrapper.indexedDBInstance.transaction('rw', collection, async () => {
      const table = IndexedDBWrapper.indexedDBInstance.table(collection)
      table.bulkDelete(itemsId)
      return `Products ${itemsId} deleted`
    })
  }

  /**
   * Search by field name (Using to search barcode)
   * @param {String} name
   * @param {String} field
   * @param {String} collection
   */
  static async searchContainDbByFields(value, field, collection) {
    return IndexedDBWrapper.indexedDBInstance
      .transaction('r', collection, async () => {
        const table = IndexedDBWrapper.indexedDBInstance.table(collection)
        const names = await table.orderBy(field).uniqueKeys()

        const matchProducts = names.filter(
          ean =>
            ean && ean.length >= 5 && value.toLowerCase().indexOf((ean || '').toLowerCase()) >= 0
        )

        return table
          .where(field)
          .startsWithAnyOfIgnoreCase(...matchProducts)
          .toArray()
      })
      .catch(e => {
        // @TODO
        // send error collection
        console.log(e)
      })
  }

  /**
   * Search by field starting with value
   * @param {String} value
   * @param {String} field
   *
   * @TODO Refactor to check the search buy EAN
   */
  static searchDbByFieldsStartWith(value, field, collection) {
    const products = IndexedDBWrapper.indexedDBInstance.table(collection)
    return products.where(field).startsWithAnyOfIgnoreCase(value).toArray()
  }

  /**
   *
   */
  static getTransactions() {
    const transactions = IndexedDBWrapper.indexedDBInstance.table('transactions')

    return new Promise((resolve, reject) => {
      try {
        resolve(
          transactions.limit(process.env.REACT_APP_INDEXEDDB_LIMIT).reverse().sortBy('created_at')
        )
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   *
   */
  static getProducts(sortBy = 'position') {
    const products = IndexedDBWrapper.indexedDBInstance.table('products')

    return new Promise((resolve, reject) => {
      try {
        resolve(products.orderBy(sortBy).limit(process.env.REACT_APP_INDEXEDDB_LIMIT).sortBy(sortBy))
      } catch (error) {
        reject(error)
      }
    })
  }

  static getResourceCount(resource) {
    const res = IndexedDBWrapper.indexedDBInstance.table(resource)
    return new Promise((resolve, reject) => {
      try {
        resolve(res.count())
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   *
   */
  static getAllProducts(sortBy = 'position') {
    const products = IndexedDBWrapper.indexedDBInstance.table('products')

    return new Promise((resolve, reject) => {
      try {
        resolve(products.toArray())
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   *
   */
  static getCategories() {
    const categories = IndexedDBWrapper.indexedDBInstance.table('categories')

    return new Promise((resolve, reject) => {
      try {
        resolve(categories.toArray())
      } catch (error) {
        reject(error)
      }
    })
  }
}
