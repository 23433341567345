import { memo, Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { selectCart, editCart, deleteCart } from '../../../store/actions/carts'
import { setToastMessage } from '../../../store/actions/app'

import { AppInstances } from '../../../utils/countrSdkInstance'
import { RequestQueue } from '../../../utils/RequestQueue'
import { cartUtils } from '../../../utils/cartUtils'
import ProductUtils from '../../../utils/ProductUtils'
import { PrinterUtils } from '../../../utils/PrinterUtils'
import RegisterOperationsUtils from '../../../utils/RegisterOperationsUtils'

import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ListItem from '@material-ui/core/ListItem'

import { Text, translate } from 'react-internationalization'
import { Button, Divider } from '@countr/ui'

import CartItemQuantityComponent from './CartItemQuantityComponent'
import CartItemDiscount from './CartItemDiscount'
import CartItemNoteComponent from './CartItemNoteComponent'
import CartItemAddons from './CartItemAddons'
import CartItemAddonGroups from './CartItemAddonGroups'
import WorkerResourceLoader from '../../../WorkerResourceLoader'
import defaultImg from './../../../assets/icon.svg'

import './CartItemDetails.scss'

const mapStateToProps = state => {
  return {
    devices: state.devices,
    carts: state.carts,
    settings: state.settings,
    theme: state.app.theme
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectCart: cart => dispatch(selectCart(cart)),
    editCart: cart => dispatch(editCart(cart)),
    deleteCart: id => dispatch(deleteCart(id)),
    setToastMessage: msg => dispatch(setToastMessage(msg))
  }
}

/**
 */
const CartItemDetails = memo(props => {
  const [amount, setAmount] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [note, setNote] = useState('')
  const [currentAddons, setCurrentAddons] = useState([])
  const [addonsList, setAddonsList] = useState([])
  const [addonsGroupList, setAddonsGroupList] = useState([])
  const [hasAddonGroups, setHasAddonGroups] = useState(false)
  const [disableSave, setDisableSave] = useState(false)
  const [reduction, setReduction] = useState({ numeric: 0, percentage: 0 })
  const [activeCart, setActiveCart] = useState({})
  const [hasItemsInPartial, setHasItemsInPartial] = useState(false)
  const style = props.theme &&
    props.theme.colors && {
      color: props.theme.colors.text,
      backgroundColor: props.theme.colors.body,
      width: props.settings.disableCustomDiscounts ? 300 : 'auto'
    }
  const product = { ...props.carts.selectedCartItem.product }
  const originalProduct = Object.assign({}, props.carts.selectedCartItem)

  useEffect(() => {
    if (selectItemHasKeys()) {
      const item = props.carts.selectedCartItem

      setAmount(item.amount)
      setDiscount(item.product.discount * 100)
      setReduction(product.reduction)
      setNote(item.note)
      setCurrentAddons(
        !!item.product.current_addons && !!item.product.current_addons.length
          ? item.product.current_addons
          : []
      )
      findAddonsFromIndexedDB()
    }

    setActiveCart(props.carts.selectedCart)

    const cartEntryId = props.carts.selectedCartItem
      ? cartUtils.getCartEntryId(props.carts.selectedCartItem)
      : false
    const hasItemsInPartial = cartUtils.checkCartPayments(
      props.carts.selectedCart,
      true,
      cartEntryId
    )
    setDisableSave(hasItemsInPartial)
    setHasItemsInPartial(hasItemsInPartial)
  }, [])

  const findAddonsFromIndexedDB = async () => {
    const products = await WorkerResourceLoader.searchDbByFields(product._id, ['_id'], 'products')
    if (products && products.length) {
      if (products[0] && products[0].addonGroups.length) {
        setHasAddonGroups(true)
        setAddonsGroupList(products[0].addonGroups)
      } else {
        setHasAddonGroups(false)
        setAddonsList(products[0].addons)
      }
    }
  }

  const handleCloseitemDetailsDialog = () => {
    setAmount(0)
    setDiscount(0)
    setNote('')
    setCurrentAddons([])
    setAddonsList([])
    setHasAddonGroups(false)

    props.closeCartItemDetailsDialog()
  }

  const handleChangeAmount = value => {
    changeAmount(Number(value))
  }

  const updateCartAmount = type => {
    changeAmount(type === 'add' ? Number(amount) + 1 : Number(amount) - 1)
  }

  const handleRegisterOperation = amount => {
    const operationArgs = {
      action: 'item_removed',
      note: `Removed ${amount} item(s) (${product.name})  with a value of ${
        props.devices.store.currency.symbol
      } ${(product.current_variant.price * amount).toFixed(2)}`
    }
    RegisterOperationsUtils.applyOperation(operationArgs)
  }

  const changeAmount = value => {
    const item = activeCart.items[props.itemIndexInCart]

    if (item) {
      const paidAmount = cartUtils.getItemPaidAmount(activeCart, item)

      if (!!paidAmount) {
        if (value >= paidAmount) {
          setAmount(value)
        }
      } else {
        let newAmount = props.settings.allowNegativePayments
          ? Math.abs(value) !== 0
            ? value
            : 0
          : value >= 0
          ? value
          : 0

        setAmount(newAmount)
      }
    }
  }

  const handleChangeDiscount = (type, value) => {
    if (type === 'discount_percent') {
      setDiscount(value)
      setReduction({ numeric: 0, percentage: value / 100 })
    } else {
      const item = activeCart.items[props.itemIndexInCart]

      if (type === 'discount_value') {
        setDiscount((value / item.product.current_variant.price) * 100)
        setReduction({ numeric: value, percentage: 0 })
      } else {
        const discount = item.product.current_variant.price - value
        setDiscount((discount / item.product.current_variant.price) * 100)
        setReduction({
          numeric: discount,
          percentage: 0
        })
      }
    }
  }

  const handleChangeNote = value => {
    setNote(value)
  }

  const handleDisableSave = value => {
    setDisableSave(value)
  }

  const saveDetails = async () => {
    if (discount < 0 || discount > 100) {
      props.setToastMessage('invalid_discount')
      return
    }

    const itemID = props.entryID
    const showSeats = cartUtils.showSeats(activeCart, props.devices.device)
    const updatedCart = { ...activeCart }

    const indexById = updatedCart.items.findIndex(i => cartUtils.getCartEntryId(i) === itemID)
    const index = showSeats ? indexById : props.itemIndexInCart
    const diff = amount - updatedCart.items[index].amount
    updatedCart.items[index].amount = amount
    updatedCart.items[index].product.discount = discount / 100
    updatedCart.items[index].product.reduction = reduction
    updatedCart.items[index].note = note
    updatedCart.items[index].product.current_addons = currentAddons

    updatedCart.items[index].status = updatedCart.items[index].status || []

    const newStatus = updatedCart.items[index].status.findIndex(st => st.state === 'new')

    if (newStatus >= 0) {
      updatedCart.items[index].status[newStatus].amount += diff
    } else {
      updatedCart.items[index].status.concat(ProductUtils.createCartEntryStatus(diff))
    }

    const amountSum = updatedCart.items.reduce((acc, current) => acc + current.amount, 0)

    if (cartUtils.isExchangeableCart(activeCart) && amountSum === 0) {
      const id = activeCart._id
      await cartUtils.deleteCartServer(id)
      const currentCart = JSON.parse(JSON.stringify(props.carts.carts[0]))
      props.selectCart(currentCart)
      props.deleteCart(id)
      localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(currentCart))
      localStorage.removeItem('EXCHANGE:CountrLite:Cart-' + id)
      handleCloseitemDetailsDialog()
      return
    }

    // If product's amount is 0, remove it from cart
    if (amount === 0) {
      PrinterUtils.handlePrintRemovedItem(updatedCart.items[index], activeCart)
      updatedCart.items.splice(index, 1)
    }

    // If items are removed send register operations update
    const newAmount = props?.carts?.selectedCartItem?.amount || 0
    const oldAmount = originalProduct?.amount || 0
    if (amount > 0 && newAmount < oldAmount) {
      handleRegisterOperation(oldAmount - newAmount)
    }
    handleUpdateCart(updatedCart)
  }

  const handleUpdateCart = async updatedCart => {
    const countr = await AppInstances.getCountrSdk()
    const { totalAmount, totalAmountPreTax } = countr.calculateTotal(updatedCart)
    updatedCart.total = totalAmount
    updatedCart.sub_total = totalAmountPreTax

    props.selectCart(updatedCart)
    localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(updatedCart))
    props.editCart(updatedCart)
    localStorage.setItem('CountrLite:Cart-' + updatedCart._id, JSON.stringify(updatedCart))

    cartUtils.updateCartServer(updatedCart).then(
      cart => {},
      error => {
        RequestQueue.enqueueAction({
          type: 'carts',
          action: 'update',
          payload: updatedCart
        })
      }
    )

    handleCloseitemDetailsDialog()
  }

  const selectItemHasKeys = () => {
    return Object.keys(props.carts.selectedCartItem).length > 0
  }

  const returnItemFullPrice = () => {
    return `${props.devices.store.currency.symbol} ${(
      product.current_variant.price * props.carts.selectedCartItem.amount
    ).toFixed(2)}`
  }

  const returnItemDeposit = () => {
    const deposit = product.options.deposit
    if (deposit && deposit.price) {
      const line = `+ ${translate('deposit')} - ${
        props.devices.store.currency.symbol
      }${deposit.price.toFixed(2)}`
      return (
        <Grid item xs={12}>
          <span>{line}</span>
        </Grid>
      )
    }
  }

  return (
    <div className="Cart-item-details">
      <Dialog
        open={props.itemDetailsDialog}
        onClose={handleCloseitemDetailsDialog}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={style}>
          {selectItemHasKeys() && <div>{product.name}</div>}
        </DialogTitle>
        <DialogContent style={style}>
          {selectItemHasKeys() && (
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <ListItem className="cart-list-item">
                  {product.image ? (
                    <img
                      src={product.image}
                      className="cart-item-avatar"
                      onError={e => (e.target.src = defaultImg)}
                      alt=""
                    />
                  ) : (
                    <div className="cart-item-avatar-default">
                      <font className="cart-item-avatar-no-img-initial">
                        {product.name.slice(0, 3).toUpperCase()}
                      </font>
                    </div>
                  )}
                  <Grid container direction="column">
                    <Grid item xs={12}>
                      <span>{product.name}</span>
                      <span>
                        {!product.current_variant.default ? product.current_variant.name : ''}
                      </span>
                    </Grid>
                    {returnItemDeposit()}
                  </Grid>
                </ListItem>
              </Grid>
              <Grid item xs={3} style={style}>
                <Grid container direction="column">
                  <span>{returnItemFullPrice()}</span>
                  <span>{`x ${props.carts.selectedCartItem.amount}`}</span>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Divider style={{ height: 0 }} />
          {/* Discount */}
          {props.settings.productLevelDiscount && (
            <CartItemDiscount
              discount={discount}
              reduction={reduction}
              handleChangeDiscount={handleChangeDiscount}
            />
          )}
          <Divider style={{ height: 0 }} />
          {/* Amount */}
          <CartItemQuantityComponent
            product={product}
            amount={amount}
            handleChangeAmount={handleChangeAmount}
            updateCartAmount={updateCartAmount}
          />
          <Divider style={{ height: 0 }} />
          <CartItemNoteComponent note={note} handleChangeNote={handleChangeNote} />
          <Divider style={{ height: 0 }} />
          {hasAddonGroups > 0 ? (
            <CartItemAddonGroups
              addonsGroupList={addonsGroupList}
              currentAddons={currentAddons}
              setCurrentAddons={setCurrentAddons}
              store={props.devices.store}
              handleDisableSave={handleDisableSave}
              language={props.settings.language}
            />
          ) : (
            <Fragment>
              {addonsList.length > 0 && (
                <CartItemAddons
                  currentAddons={currentAddons}
                  setCurrentAddons={setCurrentAddons}
                  addonsList={addonsList}
                  store={props.devices.store}
                />
              )}
            </Fragment>
          )}
          {(addonsList.length > 0 || addonsGroupList.length > 0) && <Divider className="spacing" />}
          {!!hasItemsInPartial && (
            <Grid item xs={12} className="warning-text">
              * <Text id="remove_partial_payment_by_item" />
            </Grid>
          )}
        </DialogContent>
        <DialogActions style={style}>
          <Button
            label={<Text id="cancel" />}
            onClick={handleCloseitemDetailsDialog}
            className="secondary"
          />
          <Button
            label={<Text id="save" />}
            onClick={saveDetails}
            className="primary"
            disabled={disableSave}
          />
        </DialogActions>
      </Dialog>
    </div>
  )
})

const CartItemDetailsConnected = connect(mapStateToProps, mapDispatchToProps)(CartItemDetails)
export default CartItemDetailsConnected
