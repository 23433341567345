import { Component } from 'react'
import { connect } from 'react-redux'

import { AppInstances } from './../../utils/countrSdkInstance'
import { RequestQueue } from '../../utils/RequestQueue'
import { returnUpdatableDevice } from './../../utils/DeviceUtils'
import ScreenUtils from '../../utils/ScreenUtils'
import StoreUtils from '../../utils/StoreUtils'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Tooltip from '@material-ui/core/Tooltip'

import { setSettings } from './../../store/actions/settings'
import { addDevice } from './../../store/actions/devices'
import { loadingFalse, loadingTrue } from './../../store/actions/loading'
import { editCart, selectCart } from './../../store/actions/carts'
import { setToastMessage, setTheme } from './../../store/actions/app'
import { selectEmployee, showEmployeesModal } from './../../store/actions/employees'

import ConfirmationModal from '../generic/ConfirmationModal'
import ChangePropertyModal from '../generic/ChangePropertyModal'
import StoresToListen from './StoresToListen'
import CategoriesToListen from './CategoriesToListen'

import { isMobileOnly } from 'react-device-detect'
import { Text, setLanguage, translate } from 'react-internationalization'
import { settingItemsTab } from './SettingsItems'
import langList from './../../translations'
import defaultThemes from './../../themes/default.json'

import './Settings.css'

const mapStateToProps = state => {
  return {
    app: state.app,
    user: state.user,
    settings: state.settings,
    devices: state.devices,
    carts: state.carts,
    payments: state.payments
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSettings: settings => dispatch(setSettings(settings)),
    addDevice: device => dispatch(addDevice(device)),
    loadingFalse: () => dispatch(loadingFalse()),
    loadingTrue: () => dispatch(loadingTrue()),
    editCart: cart => dispatch(editCart(cart)),
    selectCart: cart => dispatch(selectCart(cart)),
    setToastMessage: msg => dispatch(setToastMessage(msg)),
    selectEmployee: employee => dispatch(selectEmployee(employee)),
    showEmployeesModal: () => dispatch(showEmployeesModal()),
    setTheme: theme => dispatch(setTheme(theme))
  }
}

class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      languageList: langList,
      openLanguageAlert: false,
      openAdminPin: false,
      tab: 0,
      themes: [defaultThemes.data.countrLight],
      selectedlang: this.props.settings.language
    }

    // this.updateDeviceDebounce = debounce(this.updateDeviceSettingsServer.bind(this), 1000)
  }

  handleCloseSettings = () => {
    this.props.handleCloseSettings()
  }

  handleUpdateOnlineOrderStores = list => {
    const web_settings = this.props.settings
    const web_payments = this.props.payments.paymentMethods
    const deviceSettings = Object.assign({}, this.props.devices.device.settings) || {}
    const dev = Object.assign({}, this.props.devices.device)

    deviceSettings.web_settings = web_settings
    deviceSettings.web_payments = web_payments
    deviceSettings.onlineOrderStores = list

    dev.settings = deviceSettings
    this.updateDeviceSettingsServer(dev)
  }

  handleUpdateCategories = list => {
    const web_settings = this.props.settings
    const web_payments = this.props.payments.paymentMethods
    const deviceSettings = Object.assign({}, this.props.devices.device.settings) || {}
    const dev = Object.assign({}, this.props.devices.device)

    deviceSettings.web_settings = web_settings
    deviceSettings.web_payments = web_payments
    deviceSettings.buzzerCategories = list

    dev.settings = deviceSettings
    this.updateDeviceSettingsServer(dev)
  }

  handleChangeTab = (event, value) => {
    this.setState({ tab: value })
  }

  handleChange = name => event => {
    const copy = JSON.parse(JSON.stringify(this.props.settings))
    copy[name] = name === 'language' || name === 'theme' ? event.target.value : event.target.checked
    this.props.setSettings(copy)

    if (name === 'language') {
      this.setState({ selectedlang: event.target.value })
      this.setState({ openLanguageAlert: true })
    }

    if (name === 'theme') {
      const theme = this.state.themes.find(theme => theme._id === event.target.value)
      this.props.setTheme(theme)
    }

    if (name === 'kioskMode' && event.target.checked) {
      if (!this.props.settings.adminPin) {
        copy[name] = false
        this.props.setSettings(copy)
        this.props.setToastMessage('kiosk_mode_requirement')
      } else {
        copy.enableCartItemRemove = true
        this.props.setSettings(copy)
      }
    }

    if (name === 'adminPin') {
      if (event.target.checked) {
        this.setState({ openAdminPin: true })
      } else {
        this.disableAdminPin()
      }
    }

    if (name === 'employeePin') {
      if (event.target.checked) {
        this.props.showEmployeesModal()
        if (this.props.devices.store.employees && !this.props.devices.store.employees.length) {
          setTimeout(() => {
            copy.employeePin = false
            this.props.setSettings(copy)
          }, 250)
        }
      } else {
        this.props.selectEmployee({})
      }
    }

    if (
      event.target.checked &&
      (name === 'adminOnlyCustomItem' || name === 'adminOnlyRefunds') &&
      !this.props.settings.employeePin
    ) {
      copy.employeePin = true
      this.props.setSettings(copy)
      this.props.setToastMessage('mandatory_employee_pin')
    }

    if (name === 'openCloseShift') {
      ScreenUtils.kassaClosed(event.target.checked)

      if (!event.target.checked) {
        const port = this.props.settings.customerScreenPort
        if (port && port.length) {
          ScreenUtils.welcome(this.props.devices.store, port)
        }
      }
    }

    if (name === 'cartsTable' && !StoreUtils.hasFloorplans(this.props.devices.store)) {
      this.changeCartsToTables(event.target.checked)
      localStorage.setItem('CountrWeb:CartAsTables', JSON.stringify(event.target.checked))
    } else if (name === 'cartsTable') {
      // Settings changed is cart as tables but it has table layout, just turn it off and send a msg
      copy[name] = false
      this.props.setSettings(copy)
      this.props.setToastMessage('cart_as_tables_not_available')
    }

    if (name === 'splitOrderItems' && event.target.checked) {
      copy.splitOrderAmount = false
      this.props.setSettings(copy)
    } else if (name === 'splitOrderAmount' && event.target.checked) {
      copy.splitOrderItems = false
      this.props.setSettings(copy)
    }

    let deviceSettings = {}
    const payments = this.props.payments.paymentMethods

    if (this.props.devices.device.settings) {
      deviceSettings = Object.assign({}, this.props.devices.device.settings)
    }

    deviceSettings.web_settings = copy
    deviceSettings.web_payments = payments

    const dev = Object.assign({}, this.props.devices.device)

    dev.settings = deviceSettings

    if (name === 'language') {
      dev.settings.language = event.target.value
    }

    // this.updateDeviceDebounce(dev)
    this.updateDeviceSettingsServer(dev)
  }

  updateDeviceSettingsServer = async device => {
    const countr = await AppInstances.getCountrSdk()
    countr.devices.update(device._id, returnUpdatableDevice(device)).then(
      d => {},
      error => {
        RequestQueue.enqueueAction({
          type: 'devices',
          action: 'update',
          payload: returnUpdatableDevice(device)
        })
      }
    )
  }

  handlerSelect = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  changeCartsToTables = value => {
    AppInstances.getCountrSdk().then(socket => {
      this.props.carts.carts.forEach((c, index) => {
        const cart = JSON.parse(JSON.stringify(c))

        if (value) {
          cart.extras.deviceCartName = `${translate('table').toUpperCase()} ${index + 1}`
        } else {
          cart.extras.deviceCartName = `${translate('cart').toUpperCase()} ${index + 1}`
        }

        localStorage.setItem('CountrLite:Cart-' + cart._id, JSON.stringify(cart))
        this.props.editCart(cart)

        if (this.props.carts.selectedCart._id === cart._id) {
          localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(cart))
          this.props.selectCart(cart)
        }

        socket.carts.update(cart._id, cart).then(
          updatedCart => {
            console.log('updated cart name!')
          },
          error => {
            RequestQueue.enqueueAction({
              type: 'carts',
              action: 'update',
              payload: cart
            })
          }
        )
      })
    })
  }

  handleCloseopenLanguageAlert = () => {
    this.setState({ openLanguageAlert: false })
  }

  handleAcceptLanguageAlert = () => {
    setLanguage(this.state.selectedlang)
    localStorage.setItem('CountrWeb:Language', this.state.selectedlang)
    document.documentElement.lang = this.state.selectedlang
    window.location.reload()
  }

  handleCloseChangePropertyModal = () => {
    this.setState({ openAdminPin: false })
    const set = JSON.parse(JSON.stringify(this.props.settings))
    set.adminPin = false
    this.props.setSettings(set)

    let deviceSettings = {}

    if (this.props.devices.device.settings) {
      deviceSettings = Object.assign({}, this.props.devices.device.settings)
    }
    deviceSettings.web_settings = set
    const dev = Object.assign({}, this.props.devices.device)

    dev.settings = deviceSettings
    AppInstances.getCountrSdk().then(countr => {
      countr.devices.update(dev._id, returnUpdatableDevice(dev)).then(updatedDev => {
        console.log('### Device settings updated.')
      })
    })
  }

  handleConfirmChangePropertyModal = value => {
    this.props.loadingTrue()
    this.setState({ openAdminPin: false })

    AppInstances.getCountrSdk().then(socket => {
      socket.devices.readOne.detailed(this.props.devices.device._id).then(dev => {
        if (dev.settings !== undefined) {
          dev.settings.admin_pin_enabled = true
          dev.settings.admin_pin = value
        } else {
          dev.settings = {
            admin_pin_enabled: true,
            admin_pin: value
          }
        }

        socket.devices.update(dev._id, returnUpdatableDevice(dev)).then(device => {
          socket.devices.readOne.detailed(this.props.devices.device._id).then(updatedDevice => {
            this.props.addDevice(updatedDevice)
            this.props.loadingFalse()
          })
        })
      })
    })
  }

  disableAdminPin = () => {
    this.props.loadingTrue()
    this.setState({ openAdminPin: false })

    AppInstances.getCountrSdk().then(socket => {
      socket.devices.readOne.detailed(this.props.devices.device._id).then(dev => {
        if (dev.settings !== undefined) {
          dev.settings.admin_pin_enabled = false
          dev.settings.admin_pin = ''
        } else {
          dev.settings = {
            admin_pin_enabled: false,
            admin_pin: ''
          }
        }

        socket.devices.update(dev._id, returnUpdatableDevice(dev)).then(device => {
          this.props.addDevice(dev)
          this.props.loadingFalse()
        })
      })
    })
  }

  checkAllSettingsIsUpdated = settingsLocal => {
    const redux = Object.keys(this.props.settings)
    const local = Object.keys(settingsLocal)

    return redux.length === local.length && redux.every((v, i) => v === local[i])
  }

  desktopOnlyOption = setting => {
    return !setting.desktopOnly ? true : this.props.app.isDesktop
  }

  renderTabItems = tab => {
    return settingItemsTab.map(
      setting =>
        setting.tab === tab &&
        this.desktopOnlyOption(setting) && (
          <Grid key={setting.key} container alignItems="center" justifyContent="center">
            <Grid item xs={8} className="spacing">
              <Typography component="h2">
                <Tooltip title={<Text id={setting.description} />} placement="right">
                  <span className="description">
                    <Text id={setting.string} />
                  </span>
                </Tooltip>
              </Typography>
            </Grid>
            {this.returnSettingsAction(setting.key)}
            {this.handleMultiStores(setting.key)}
            {this.handleMultiCategories(setting.key)}
          </Grid>
        )
    )
  }

  handleMultiStores = key => {
    if (
      key === 'printOrdersForMultipleStores' &&
      this.props.settings?.printOrdersForMultipleStores
    ) {
      return (
        <StoresToListen
          handleUpdateOnlineOrderStores={this.handleUpdateOnlineOrderStores}
          onlineOrderStores={this.props.settings.listeningStores}
        />
      )
    }
  }

  handleMultiCategories = key => {
    if (
      key === 'askBuzzerInfo' &&
      this.props.settings?.askBuzzerInfo
    ) {
      return (
        <CategoriesToListen
          handleUpdateCategories={this.handleUpdateCategories}
          buzzerCategories={this.props.settings.buzzerCategories}
        />
      )
    }
  }

  returnSettingsAction = setting => {
    return setting !== 'language' && setting !== 'theme' ? (
      <Grid item xs={4} className="settings-switch-grid">
        <Switch
          checked={this.props.settings[setting]}
          onChange={this.handleChange(setting)}
          value={setting}
          color="default"
          style={{
            color: this.props.settings[setting] ? '#318ed5' : '#616161'
          }}
          className="settings-switch"
        />
      </Grid>
    ) : setting === 'language' ? (
      <Grid item xs={4} className="settings-select-grid">
        <Select
          value={this.props.settings.language}
          onChange={this.handleChange('language')}
          className="settings-select">
          {this.state.languageList.length &&
            this.state.languageList.map(lang => {
              return (
                <MenuItem key={lang.key} value={lang.key}>
                  {lang.label}
                </MenuItem>
              )
            })}
        </Select>
      </Grid>
    ) : (
      <Grid item xs={4} className="settings-select-grid">
        <Select
          value={this.props.settings.theme}
          onChange={this.handleChange('theme')}
          className="settings-select">
          {this.state.themes.length &&
            this.state.themes.map(theme => {
              return (
                <MenuItem key={theme._id} value={theme._id}>
                  {theme.name}
                </MenuItem>
              )
            })}
        </Select>
      </Grid>
    )
  }

  componentDidMount() {
    const { store } = this.props.devices

    if (store.extras && store.extras.themes) {
      this.setState({
        themes: Object.keys(store.extras.themes.data).map(key => ({
          ...store.extras.themes.data[key]
        }))
      })
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.openSettingsDialog}
        onClose={this.handleCloseSettings}
        aria-labelledby="form-dialog-title"
        fullScreen={isMobileOnly}
        fullWidth={!isMobileOnly}
        maxWidth="md">
        <DialogTitle id="form-dialog-title">
          <Text id="settings" />
        </DialogTitle>
        <DialogContent className="settings-dialog">
          <Tabs
            value={this.state.tab}
            onChange={this.handleChangeTab}
            variant="fullWidth"
            orientation="vertical"
            classes={{ indicator: 'reset-button' }}
            className="tab-group">
            <Tab label={<Text id="carts" />} />
            <Tab label={<Text id="products" />} />
            <Tab label={<Text id="transactions" />} />
            <Tab label={<Text id="admin" />} />
            <Tab label={<Text id="printer" />} />
            <Tab label={<Text id="store" />} />
          </Tabs>

          {/* render all items in settings */}
          <div className="items-group">{this.renderTabItems(this.state.tab)}</div>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={this.handleCloseSettings}>
            <Text id="close" />
          </Button>
        </DialogActions>
        {this.state.openLanguageAlert && (
          <ConfirmationModal
            openConfirmation={this.state.openLanguageAlert}
            handleCloseConfirmation={this.handleCloseopenLanguageAlert}
            confirmBtn={this.handleAcceptLanguageAlert}
            noCloseBtn={false}
            confirmationTitle={<Text id="change_language" />}
            confirmationText={<Text id="confirm_new_language" />}
            type="change_language"
          />
        )}
        {this.state.openAdminPin && (
          <ChangePropertyModal
            openChangeProperty={this.state.openAdminPin}
            handleClose={this.handleCloseChangePropertyModal}
            handleConfirm={this.handleConfirmChangePropertyModal}
            changePropertyTitle="admin_pin"
            changePropertyText="admin_pin"
            changePropertyHelpText="admin_pin_should_be"
            errorCounterMin={4}
            errorCounterMax={6}
          />
        )}
      </Dialog>
    )
  }
}

const SettingsConnected = connect(mapStateToProps, mapDispatchToProps)(Settings)
export default SettingsConnected
