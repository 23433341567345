import { memo, useCallback, useState } from 'react';
import { connect } from 'react-redux'
import { Text, translate } from 'react-internationalization'
import { setProductSearchInput, runProductSearch } from '../../store/actions/products'
import debounce from 'lodash.debounce'
import { Button } from '@countr/ui'
import CustomProductModal from './Modals/CustomProductModal'
import CategoriesModal from './Categories/CategoriesModal'
import './CatalogHeader.scss'

const mapStateToProps = state => {
  return {
    searchInput: state.products.searchInput,
    hideCategoriesTile: state.settings.hideCategoriesTile,
    cartAlwaysOpen: state.settings.cartAlwaysOpen,
    theme: state.app.theme,
    selectedEmployee: state.employees.selectedEmployee,
    adminOnlyCustomItem: state.settings.adminOnlyCustomItem
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setProductSearchInput: str => dispatch(setProductSearchInput(str)),
    runProductSearch: shouldRun => dispatch(runProductSearch(shouldRun))
  }
}

const CatalogHeader = memo(function CatalogHeader({
  searchInput,
  setProductSearchInput,
  runProductSearch,
  hasIntegrations,
  hideCategoriesTile,
  cartAlwaysOpen,
  theme,
  selectedEmployee,
  adminOnlyCustomItem
}) {
  const delayedCallback = useCallback(
    debounce(s => searchProduct(s), 1000),
    []
  )
  const [openCustomProductModal, setOpenCustomProductModal] = useState(false)
  const [openCategoriesDialog, setOpenCategoriesDialog] = useState(false)

  const handleChangeSearch = event => {
    const str = event.target.value
    setProductSearchInput(str)
    delayedCallback(str)
  }

  const searchProduct = async value => {
    if (value.length > 2) {
      runProductSearch(true)
    }
  }

  const resetProductList = () => {
    setProductSearchInput('')
    runProductSearch(false)
  }

  const handleCustomProductModal = (custom, amount) => {
    setOpenCustomProductModal(!openCustomProductModal)
  }

  const shouldRenderFullWidth = () => {
    return !cartAlwaysOpen ? { width: '100%' } : {}
  }

  const handleShowButton = () => {
    let result = true
    if (
      adminOnlyCustomItem &&
      selectedEmployee &&
      selectedEmployee.rights &&
      selectedEmployee.rights !== 'admin'
    ) {
      result = false
    } else if (adminOnlyCustomItem && (!selectedEmployee || !selectedEmployee.rights)) {
      result = false
    }
    return result
  }

  return (
    <div className="catalog-header toolbar" style={shouldRenderFullWidth()}>
      {!hasIntegrations && handleShowButton() && (
        <Button
          label={<Text id="custom_item" />}
          onClick={handleCustomProductModal}
          styles={{ margin: 6 }}
        />
      )}
      <div className="product-search-bar-container">
        <div className="product-search-bar">
          <div className="product-search-bar-icon">
            <span className="icon-search" />
          </div>
          <input
            id="product-search-input"
            placeholder={translate('search_product')}
            className="product-search-bar-input"
            value={searchInput}
            onChange={handleChangeSearch}
            style={{ color: theme.colors.toolbarText }}
          />
          <div className="product-search-bar-icon-close" onClick={resetProductList}>
            <span className="icon-close" />
          </div>
        </div>
      </div>
      {hideCategoriesTile && (
        <Button
          label={<Text id="categories" />}
          onClick={() => setOpenCategoriesDialog(true)}
          styles={{ margin: 6 }}
        />
      )}
      {openCustomProductModal && (
        <CustomProductModal
          openCustomProduct={openCustomProductModal}
          handleCloseCustomProduct={handleCustomProductModal}
        />
      )}
      {openCategoriesDialog && (
        <CategoriesModal
          categoriesDialog={openCategoriesDialog}
          handleCloseCategories={() => setOpenCategoriesDialog(false)}
        />
      )}
    </div>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(CatalogHeader)
