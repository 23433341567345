import { memo, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Text } from 'react-internationalization'
import { useTracking } from 'react-tracking'
import { cartUtils } from '../../../utils/cartUtils'
import ProductUtils from '../../../utils/ProductUtils'
import Util from '../../../utils/Util'
import defaultImg from './../../../assets/icon.svg'
import { TRACKER_EVENTS } from '../../../utils/trackerEvents'
import { AppInstances } from '../../../utils/countrSdkInstance'

import { showExternalOpenUrl } from '../../../store/actions/app.js'

import './ProductTile.scss'

const mapDispatchToProps = dispatch => {
  return {
    showExternalOpenUrl: obj => dispatch(showExternalOpenUrl(obj))
  }
}

const mapStateToProps = state => {
  return {
    settings: state.settings,
    user: state.user.user,
    selectedCart: state.carts.selectedCart
  }
}

const NOT_TRACKED = 'not_tracked'

const ProductTile = memo(function ProductTile({
  product,
  selectAction,
  store,
  user,
  isDepositSelected,
  settings,
  showInventory,
  handleShowInventory,
  selectedCart,
  showExternalOpenUrl,
  handleShowExternalModal,
  setExternalProduct
}) {
  const productName = useMemo(
    () => ProductUtils.getProductName(product, settings.language),
    [product, settings.language]
  )
  const { trackEvent } = useTracking()

  const getCurrentStock = () => {
    let stock = 0

    for (let index = 0; index < product.variants.length; index++) {
      stock = ProductUtils.getVariantStock(store._id, product, product.variants[index]._id)
      if (stock !== NOT_TRACKED) {
        break
      }
    }
    return stock
  }

  const shouldShowSpecialProductIcon = product => {
    return ProductUtils.isAddonsProduct(product) || ProductUtils.isSoldByWeightProduct(product)
  }

  const returnProductTileClassName = () => {
    let className = 'product-card'

    if (product.options.sold_by_weight) {
      className += ' product-card-have-stock'
    }

    return className
  }

  const returnTileColor = () => {
    return product.categories.length && product.categories[0].color !== undefined
      ? cartUtils.colourNameToHex(product.categories[0].color)
      : '#B6C2CD'
  }

  const fontColor = useMemo(() => Util.isBgColorlightOrDark(returnTileColor()))

  const returnCardStyle = (showBackground = true) => {
    const classObj = {}

    if (showBackground) {
      classObj.background = returnTileColor()
    }

    if (settings.productTileSize) {
      classObj.width = 180
      classObj.height = 180
    }

    return classObj
  }

  const returnPriceBottom = () => {
    return settings.productTileSize ? 180 : 122
  }

  const returnProductPrice = () => {
    let price = 0

    if (isDepositSelected) {
      price =
        product.options.deposit && product.options.deposit.price
          ? `-${product.options.deposit.price.toFixed(2)}`
          : (0).toFixed(2)
    } else {
      price = ProductUtils.getProductPrice(store._id, product, undefined).toFixed(2)
    }

    return (
      <font>
        {store.currency.symbol} {price}
      </font>
    )
  }

  const returnDepositTag = () => {
    if (isDepositSelected) {
      return (
        <span className="deposit-tag">
          <Text id="deposit" />
        </span>
      )
    }
  }

  const handleClick = () => {
    trackEvent({
      event: TRACKER_EVENTS.PRODUCT_CLICK,
      data: { _id: product._id, name: product.name },
      merchant: user._id
    })

    const openUrlAvailable = product.options?.open_url ?? false
    const externalService = product.options?.external_service?.provider
      ? product.options.external_service
      : false

    if (externalService) {
      setExternalProduct(product)
      handleShowExternalModal()
      return
    }

    if (openUrlAvailable) {
      const parsedExternalUrl = openUrlAvailable.replace(/{cart_id}/, selectedCart._id)
      showExternalOpenUrl({
        show: true,
        url: parsedExternalUrl
      })
      resetConviousFrame(parsedExternalUrl)

      return
    }

    if (showInventory) {
      return
    }

    selectAction(product)
  }
  
  function resetConviousFrame(url) {
    var productListId = (url.match(/product-list=([^&]*)/, '') || [])[1];
    if (productListId) window['$CONVIOUS'].navigate('/pick-your-place/' + productListId);
  }

  const handleRightClick = event => {
    event.preventDefault()
    handleShowInventory(product)
  }

  return (
    <div
      style={returnCardStyle()}
      className={returnProductTileClassName()}
      onClick={handleClick}
      onContextMenu={handleRightClick}>
      {settings.showStock && ProductUtils.hasInventory(product) && (
        <div className="product-tile-counter">
          <div className="product-tile-number">
            <div
              className="product-tile-count"
              style={{ border: `1px solid ${returnTileColor()}` }}>
              {getCurrentStock()}
            </div>
          </div>
        </div>
      )}
      {shouldShowSpecialProductIcon(product) && (
        <div className="product-card-addons">
          <div className="product-card-addons-position" style={{ background: returnTileColor() }}>
            <span className="product-card-addons-icon">
              <span
                className={ProductUtils.isAddonsProduct(product) ? 'icon-hamburger' : 'icon-weight'}
              />
            </span>
          </div>
        </div>
      )}
      <div title={ProductUtils.getProductDescription(product, settings.language)}>
        {settings.showProductImageList && product.image ? (
          <img
            draggable="false"
            loading="lazy"
            width="120"
            height="120"
            src={product.image}
            style={returnCardStyle(false)}
            className="product-card-img"
            onError={e => (e.target.src = defaultImg)}
            alt=""
          />
        ) : (
          <div className="product-card-no-img-default" style={returnCardStyle(false)}>
            <font className="product-card-initial">{productName.slice(0, 3).toUpperCase()}</font>
          </div>
        )}
      </div>
      <div
        className="product-card-content-price"
        style={{
          border: `1px solid ${returnTileColor()}`,
          bottom: returnPriceBottom()
        }}>
        <span>
          {product.options.dynamic_pricing ? <Text id="custom_price" /> : returnProductPrice()}
        </span>
      </div>
      <div style={{ background: returnTileColor() }} className="product-card-content-name">
        <span className="product-card-content-name-typo" style={{ color: fontColor }}>
          {!product.visible && <span className="icon-not-visible" />}
          <span className="name" title={productName}>
            {productName}
          </span>
        </span>
      </div>
      {returnDepositTag()}
    </div>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductTile)
