import { memo, useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button } from '@countr/ui'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Receipt from './Receipt'
import Invoice from './Invoice'
import TipModal from './TipModal'
import TransactionListSendEmail from './TransactionListSendEmail'
import PrintButton from '../generic/PrintButton'
import { Text } from 'react-internationalization'
import { AppInstances } from './../../utils/countrSdkInstance'
import { PrinterUtils } from './../../utils/PrinterUtils'
import DesktopUtils from './../../utils/DesktopUtils'
import { cartUtils } from '../../utils/cartUtils'
import ScreenUtils from '../../utils/ScreenUtils'
import StoreUtils from '../../utils/StoreUtils'
import { setToastMessage } from './../../store/actions/app'
import { showEmployeesModal } from '../../store/actions/employees'
import { enableDepositProductsView } from '../../store/actions/products'
import './Transaction.css'

const mapStateToProps = state => {
  return {
    app: state.app,
    user: state.user.user,
    settings: state.settings,
    store: state.devices.store,
    device: state.devices.device,
    transactions: state.transactions.transactions,
    methods: state.payments.paymentMethods,
    showDepositProducts: state.products.showDepositProducts
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setToastMessage: (msg, opt) => dispatch(setToastMessage(msg, opt)),
    showEmployeesModal: () => dispatch(showEmployeesModal()),
    enableDepositProductsView: () => dispatch(enableDepositProductsView())
  }
}

const FinishedTransactionDialog = memo(
  ({
    open,
    handleClose,
    transaction,
    user,
    settings,
    store,
    transactions,
    methods,
    setToastMessage,
    app,
    showDepositProducts,
    enableDepositProductsView,
    handleUpdateTransaction,
    showEmployeesModal,
    device
  }) => {
    const printRef = useRef()
    const [anchorEl, setAchorEl] = useState(null)
    const [isReprint, setIsReprint] = useState(false)
    const [openTipModal, setOpenTipModal] = useState(false)
    const style = {
      color: app.theme.colors.text,
      backgroundColor: app.theme.colors.body
    }

    useEffect(() => {
      if (app.isDesktop) {
        shouldAutoPrintReceipt()
        shouldCustomerScreenWelcome()
      }

      if (showDepositProducts) {
        enableDepositProductsView()
      }

      window.addEventListener('print-receipt', handleReprint)

      if (settings.skipTransactionModal) {
        setToastMessage('recovery_transaction_complete', { ref: `#${transaction.receipt_id}` })
        handleCloseDialog()
      }

      return () => {
        window.removeEventListener('print-receipt', handleReprint)
      }
    }, [])

    const handleReprint = () => {
      setIsReprint(true)
    }

    const handleCloseDialog = feedback => {
      handleClose(feedback === true)

      // Show employee dialog if employee lock is enable
      if (settings.employeeLock) {
        showEmployeesModal(true)
      }

      if (StoreUtils.hasFloorplans(store) && settings.floorplanOnNewSale) {
        const e = new CustomEvent('handle-floorplan')
        window.dispatchEvent(e)
      }
    }

    const returnPrintCallback = () => ({
      setToastMessage
    })

    const shouldAutoPrintReceipt = () => {
      const printKitchen = settings.kitchenPrint && !cartUtils.isThirdPartySource(transaction)
      const firstPayment = transaction.payments[0]
      const paymentMethod = methods.find(
        m => m.method === firstPayment.method || m.method === firstPayment.provider
      )

      if (paymentMethod) {
        const customPayment = paymentMethod.isCustom
          ? store.options.extraPaymentMethods.find(
              m => m.name === paymentMethod.method || m.method === paymentMethod.method
            )
          : null

        printReceipt(!!customPayment ? customPayment : paymentMethod, printKitchen)
      }

      // Auto-print tickets if any available
      if (DesktopUtils.isExternalPrintersAvailable()) {
        PrinterUtils.externalOSPrint(transaction)
      }

      // Print order if kitchen print is enable
      if (printKitchen) {
        PrinterUtils.order(transaction, returnPrintCallback(), settings.kitchenPrint)
      }
    }

    const printReceipt = (method = { printReceipt: true }, printKitchen) => {
      if (!checkFiscalPrint()) {
        setToastMessage('fiscal_print_error')
        return
      }

      // If method has auto print set, print receipt
      if (method.printReceipt) {
        const copyTransaction = { ...transaction, merchant: user._id }
        copyTransaction.issued = copyTransaction.issued ? copyTransaction.issued : isReprint
        const receipt = user.receipt
        const currency = store.currency.code
        const printEAN = settings.showEANSKUReceipt
        const printBrand = settings.showBrandReceipt
        const printQR = settings.printQR
        const printDescription = settings.printDescription
        const printTickets = settings.printTickets
        const qrCodeForLineItems = settings.qrCodeForLineItems
        const openCashDrawer = method.cashDrawer && !isReprint
        // usb printer need a delay to print the receipt
        const timeout = printKitchen && PrinterUtils.isUSBPrinter() ? 2000 : 10
        setTimeout(() => {
          PrinterUtils.receipt(
            copyTransaction,
            receipt,
            store,
            currency,
            returnPrintCallback(),
            printEAN,
            printBrand,
            printQR,
            printTickets,
            printDescription,
            openCashDrawer,
            qrCodeForLineItems
          )
        }, timeout)
      } else if (method.cashDrawer) {
        PrinterUtils.openCashDrawer()
      }
    }

    const shouldCustomerScreenWelcome = () => {
      if (settings.enableCustomerScreen) {
        const port = settings.customerScreenPort
        if (!!port) {
          // After payment, it show paid amount, so lets wait sometime to show welcome
          setTimeout(() => {
            ScreenUtils.welcome(store, port)
          }, 3000)
        }
      }
    }

    const checkFiscalPrint = () => {
      if (StoreUtils.isFiscalStore(store, device)) {
        return !!transaction.fiscal_info && Object.keys(transaction.fiscal_info).length > 0
      }

      return true
    }

    const handleEmail = event => {
      setAchorEl(event && event.currentTarget ? event.currentTarget : null)
    }

    const sendReceiptEmail = async email => {
      const lastTransaction = transactions.length ? transactions[0] : { receipt_id: '1' }

      if (transaction.receipt_id === lastTransaction.receipt_id) {
        const body = JSON.stringify({ type: 'email', value: email })
        const countr = await AppInstances.getCountrSdk()
        countr.transactions.readOne.send(lastTransaction._id, body).then(transaction => {
          handleEmail()
          handleUpdateTransactionIssued(email)
          handleCloseDialog(true)
        })
      } else {
        setToastMessage('transaction_not_sync_yet')
      }
    }

    const handleUpdateTransactionIssued = (email = null) => {
      const e = new CustomEvent('print-receipt', {
        detail: {
          id: transaction.receipt_id,
          email
        }
      })
      window.dispatchEvent(e)
    }

    const isInvoicePayment = () => {
      return transaction && transaction !== 'transaction_in_queue'
        ? transaction.payments.findIndex(payment => payment.method === 'invoice') >= 0
        : false
    }

    const handleAddTip = () => {
      setOpenTipModal(true)
    }

    const handleCloseTipModal = () => {
      setOpenTipModal(false)
    }

    return (
      <Dialog open={open} onClose={handleCloseDialog} testid="transaction-finish-modal">
        <DialogTitle id="form-dialog-title" style={style}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={10}>
              <Text id="payment_succeeded" />
            </Grid>
            <Grid item xs={1}>
              {checkFiscalPrint() && (
                <IconButton className="transaction-email" onClick={$event => handleEmail($event)}>
                  <span className="icon-email-receipt" style={{ color: style.color }} />
                </IconButton>
              )}
              <TransactionListSendEmail
                anchorEl={anchorEl}
                handleCloseSendEmail={() => handleEmail()}
                sendReceiptEmail={sendReceiptEmail}
                initCustomer={
                  transaction.customer && transaction.customer.email
                    ? transaction.customer.email
                    : ''
                }
              />
            </Grid>
            {checkFiscalPrint() && (
              <Grid item xs={1}>
                <PrintButton
                  isDesktop={app.isDesktop}
                  a4Print={settings.a4Print}
                  a4ContentRef={printRef.current}
                  printReceipt={printReceipt}
                  handleUpdateTransaction={handleUpdateTransactionIssued}
                />
              </Grid>
            )}
          </Grid>
        </DialogTitle>
        <DialogContent ref={printRef} style={style}>
          {isInvoicePayment() ? (
            <Invoice transaction={transaction} />
          ) : (
            <Receipt transaction={transaction} transactionComplete={true} />
          )}
          {openTipModal && (
            <TipModal
              open={openTipModal}
              handleClose={handleCloseTipModal}
              handleUpdateTransaction={handleUpdateTransaction}
              currency={store.currency.symbol}
              transaction={transaction}
            />
          )}
        </DialogContent>
        <DialogActions style={style}>
          {settings.askTips && (
            <div className="tip-btn-container">
              <Button label={<Text id="add_tip" />} onClick={handleAddTip} className="primary" />
            </div>
          )}
          <Button label={<Text id="close" />} onClick={handleCloseDialog} className="secondary" />
        </DialogActions>
      </Dialog>
    )
  }
)

export default connect(mapStateToProps, mapDispatchToProps)(FinishedTransactionDialog)
