import store from './../index'
import { setIsDesktop as setIsDesktopAction } from './../store/actions/app'
import { AppInstances } from './countrSdkInstance'

export default class DesktopUtils {
  static async setIsDesktop(deviceFingerPrintId) {
    const desktop = JSON.parse(localStorage.getItem('isDesktop'))
    const version = localStorage.getItem('DesktopVersion')

    const countr = await AppInstances.getCountrSdk()

    try {
      const serverDevice = await countr.devices.readOne(`desktop-${deviceFingerPrintId}`)
      
      localStorage.setItem('CountrLite:LocalDesktop', JSON.stringify(serverDevice.settings))

      const localDesktop = serverDevice.settings
      !!desktop &&
        store.dispatch(
          setIsDesktopAction({
            version: version ?? '',
            ip: localDesktop?.local_ip ?? ''
          })
        )
      
      return Promise.resolve(true)
    } catch (error) {
      return Promise.resolve(false)
    }
  }

  static isExternalPrintersAvailable() {
    return DesktopUtils.isDesktop() && store.getState().devices.store.options?.externalPrinters
  }

  static getDesktopListenerPort() {
    const localstorageDesktopPortSaved = localStorage.getItem('DL_PORT')
    const port =
      localstorageDesktopPortSaved ??
      (store.getState().devices.device?.settings?.desktopListenerPort ||
        process.env.REACT_APP_DESKTOP_PORT ||
        2222)

    console.log('🚀 DL_PORT', port)

    return port
  }

  static isDesktop() {
    return store.getState().app.isDesktop
  }

  static getDesktopVersion() {
    return store.getState().app.desktopVersion
  }

  static getDesktopIP() {
    return store.getState().app.desktopIP
  }

  static isVersionValid(currentVersion, majorVersion) {
    const version = !!currentVersion ? currentVersion.split('.') : 0
    return !!version.length ? +version[0] > majorVersion : false
  }
}
