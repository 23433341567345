import { memo, useState, useEffect } from 'react';
import { Text } from 'react-internationalization'
import { useSelector } from 'react-redux'
import { Button, Input } from '@countr/ui'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'

import '../Modals/Modal.scss'

const ExternalServiceproductModal = memo(props => {
  const [code, setCode] = useState('')
  const theme = useSelector(state => state.app.theme)
  const style = {
    color: theme.colors.text,
    backgroundColor: theme.colors.body
  }

  const handleChange = value => {
    setCode(value)
  }

  useEffect(() => {
    setCode('')
  }, [props.open])

  return (
    <Dialog open={props.open} onClose={props.close}>
      <DialogTitle style={style}>
        <Text id="scan_or_type" />
      </DialogTitle>
      <DialogContent
        testid="external-service-product-modal"
        style={style}
        className="modal-content">
        <Grid container alignItems="center" justifyContent="center" className="external-modal">
          <Grid item xs={12}>
            <CircularProgress className="barcode-spinner" />
          </Grid>
        </Grid>
        {/* Input field */}
        <Input
          variant="outlined"
          label={<Text id="type_code" />}
          value={code}
          onChange={handleChange}
          style={{ width: 250 }}
        />

      </DialogContent>
      <DialogActions style={style}>
        <Button label={<Text id="cancel" />} onClick={props.close} className="secondary" />
        <Button
          testid="ok-buttonexternal-service-product-moda"
          className="primary"
          label={<Text id="confirm" />}
          onClick={() => props.handleProductsExtrenalService(code)}
          disabled={!code.length}
        />
      </DialogActions>
    </Dialog>
  )
})

export default ExternalServiceproductModal
