/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
const itLang = {
  cart: 'Carrello',
  item: 'Oggetto',
  items: 'Oggetti',
  subtotal: 'Subtotale',
  discount: 'Sconto',
  total: 'Totale',
  pay: 'Paga',
  card: 'Carta',
  cash: 'Contanti',
  invoice: 'Fattura',
  giftcard: 'Giftcard',
  seqr: 'SEQR',
  bitpay: 'Bitpay',
  payment_method: 'Metodo di pagamento',
  cash_received: 'Quantità ricevuta',
  or_enter_amount: 'O inserisci l"importo...',
  payment_succeeded: 'Pagamento riuscito',
  change_due: 'Resto dovuto',
  total_paid: 'Totale pagato',
  send_receipt: 'Invia ricevuta',
  send_invoice: 'Invia fattura',
  send: 'Invia',
  delete: 'Elimina',
  new_sale: 'Nuova vendita',
  custom_price: 'Prezzo su misura',
  item_description: 'Descrizione oggetto',
  products: 'Prodotti',
  transactions: 'Transazioni',
  reports: 'Resoconti',
  report: 'Resoconto',
  settings: 'Impostazioni',
  sign_out: 'Disconnetti',
  receipt: 'Ricevuta',
  refund: 'Rimborso',
  home: 'Home',
  clear: 'Cancella',
  add: 'Aggiungi',
  sign_up: 'Iscriviti',
  sign_up_email: 'Iscriviti via mail',
  sign_in: 'Accedi',
  company_name: 'Nome azienda',
  merchant_name: 'Nome commerciante',
  email_address: 'Indirizzo email',
  phone_number: 'Numero di telefono',
  password: 'Password',
  confirm_password: 'Conferma password',
  forgot_password_question: 'dDmenticato la password?',
  next: 'Avanti',
  demo: 'Demo',
  skip: 'Salta',
  skip_registration: 'Salta registratione',
  take_a_tour: 'Take a tour',
  items_returned: 'Oggetti restituiti',
  wrong_billing: 'Errore fatturazione',
  order_cancelled: 'Ordine cancellato',
  other: 'Altro',
  enter_valid_amount: 'Inserisci un importo valido',
  invalid_merchant_name: 'Nome commerciante non valido',
  invalid_business_name: 'Nome azienda non valido',
  invalid_email_address: 'Indirizzo email non valido',
  invalid_phone_number: 'Numero di telefono non valido',
  invalid_password: 'Password non valida',
  password_mismatch: 'Le password non coincidono',
  coming_soon: 'Prossimamente...',
  to_use_signup: 'Iscriviti per usare questa funzionalità',
  login_error: 'Errore login',
  signup_error: 'Errore registrazione',
  transaction_history_empty: 'Lo storico transazioni è vuoto.',
  categories: 'Categorie',
  all_products: 'Tutti i prodotti',
  search_product: 'Cerca prodotti',
  reissue_receipt: 'Riemetti ricevuta',
  print_receipt: 'Stampa ricevuta',
  or_send_receipt: 'O invia la ricevuta',
  save: 'Salva',
  saved: 'Salvato',
  search: 'Cerca',
  custom_item: 'Oggetto personalizzato',
  submit: 'Invia',
  submitted: 'Inviato',
  note: 'Note',
  message: 'Messaggio',
  amount: 'Quantità',
  number: 'Numero',
  taxes: 'Tasse',
  tax: 'Tassa',
  enter_valid_number: 'Per favore inserisci un numero valido',
  enter_valid_text: 'Per favore inserisci un testo valido',
  enter_valid_phone: 'Per favore inserisci un numero di telefono valido',
  enter_valid_email: 'Per favore inserisci un indirizzo email valido',
  feature_not_in_demo: 'Questa funzionalità non è disponibile nella modalità demo',
  provide_refund_reason: 'Devi selezionare la ragione del rimborso',
  provide_valid_refund_amount: 'Devi inserire una quantità valida per il rimborso',
  refund_exceed_refundable:
    'La quantità del rimborso eccede la massima quantità rimborsabile per questa ricevuta',
  refund_saved: 'Rimborso salvato',
  or_customize_discount: 'o personalizza lo sconto',
  enter_percentage: 'inserisci percentuale',
  manual_card_payment: 'Pagamento manuale con carta',
  did_card_payment_succeed: 'Il pagamento è andato a buon fine?',
  manual_card_refund: 'Rimborso manuale via carta',
  did_card_refund_succeed: 'Il rimborso ha avuto successo?',
  giftcard_refund: 'Rimborso giftcard',
  did_giftcard_refund_succeed: 'La nuova giftcard è stata rilasciata al cliente?',
  yes: 'Si',
  no: 'No',
  general: 'Generale',
  printer: 'Stampante',
  barcode_scanner: 'Scanner codici a barre',
  payment: 'Pagamenti',
  profile: 'Profilo',
  currency: 'Valuta',
  printing_receipt: 'Stampa ricevuta in corso...',
  enter_amount: 'Inserisci quantità',
  expected_change: 'Resto previsto',
  still_remaining: 'Rimanente',
  issue_invoice: 'Emetti Fattura',
  URL_pricing: 'http://www.countrhq.com/#pricing',
  address: 'Inditizzo',
  address_continued: 'Indizitto [Facoltativo]',
  city: 'Città',
  zip: 'Codice Postale',
  country: 'Stato',
  country_iso: 'Paese nel formato ISO 3166-2 (ad es. "Nl", "de", "gb")',
  invalid_address: 'Indirizzo non valido',
  invalid_city: 'Città non valida',
  invalid_country: 'Stato non valido',
  invalid_ecommerce: 'Nessuna piattagorma di eCommerce selezionata',
  signup_error_contact_support: 'Errore registrazione, per favore contatta support@countrhq.com',
  open_orders: 'Ordini Aperti',
  order: 'Ordine',
  enter_weight_g: 'Inserisci peso (g)',
  search_scanner: 'Cerca scanner Socket CHS',
  order_settings: 'Impostationi ordine',
  order_amount: 'Quantità ordini',
  receipts: 'Ricevute',
  print_receipts: 'Stampa Ricevute',
  open_cash_drawer: 'Apri cassetto',
  backup: 'Backup',
  email_backup: 'Email Backup',
  server_sync: 'Sincronizzazione Server',
  external_payment: 'Terminale altro per pagamento con carta',
  search_printer: 'Cerca stampante',
  sms_successfully_sent: 'SMS inviato correttamente',
  error_sending_sms: 'Errore invoi SMS, riprova più tardi',
  email_successfully_sent: 'Email inviata correttamente',
  error_sending_email: 'Errore invio email, riprova più tardi',
  transaction: 'Transazione',
  logging_in: 'Accesso in corso...',
  downloading_products: 'Download prodotti...',
  add_to_cart: 'Aggiungi al carrello',
  invalid_price: 'Prezzo non valido',
  invalid_discount: 'Sconto non valido',
  invalid_new_price: 'Nuovo prezzo non valido - deve essere minore del prezzo originale',
  invalid_amount: 'Quantità non valida',
  edit_price: 'Modifica Prezzo',
  discount_perc: 'Sconto (%)',
  quantity: 'Quantità',
  remove: 'Rimuovi',
  sales_info: 'Informazioni vendite',
  summary: 'Riepilogo',
  refunded_information: 'Informazioni rimbordi',
  total_sale: 'Totale Vendite',
  total_amount_refunded: 'Quantità totale rimborsata',
  remaining: 'Restante',
  sold_by: 'Venduto Da',
  source_receipt_number: 'Numero ricevuta sorgente',
  issued_date: 'Data rilascio',
  issued_time: 'Ora rilascio',
  refunded_by: 'Rimborsato da',
  reason: 'Ragione',
  receipt_number: 'Numbero ricevuta',
  refunded_date: 'Date rimborso',
  refunded_time: 'Ora rimborso',
  first_name: 'Nome',
  last_name: 'Cognome',
  shop_category: 'Categoria negozio',
  choose_country: 'Seleziona stato...',
  you_will_receive_email: 'Congratulazioni, la tua regiztrazione è competa.',
  wait_email:
    'Riceverai una email con ulteriori istruzioni sucome connettere la tua piattaforma e-commerce a Countr POS.',
  open_dashboard:
    'Ora puoi gestire il tuo account e creare prodotti nella tua dashboard personale Countr.',
  by_signing_up: 'Registrandomi accetto i',
  terms_and_conditions: 'Termini e Condizioni',
  and: 'e',
  privacy_policy: 'Politiche sulla Riseratezza',
  refund_items: 'Rimborsa Oggetti',
  forgot_password: 'Dimenticato la password?',
  register_device: 'Registra Dispositivo',
  device_not_registered: 'Questo dispositivo non è registrato',
  want_to_add_to_account: 'Vuoi aggiungerlo al tuo account?',
  if_ios_previously_registered:
    'Se hai re-installato recentemente Countr, devi registrare il tuo dispositivo nuovamente.',
  new_device_info: 'Ti verrà chiesto nella prossima schermata di collegarlo ad uno dei tuo negozi',
  not_now: 'Non ora',
  proceed: 'Procedi',
  more_info_click: 'Più Info? Premi qui',
  your_detail: 'I tuoi dettagli',
  vat_no: 'P.IVA',
  billing_details: 'Dettagli fatturazione',
  add_store: 'Aggiungi negozio',
  or_add_to_existing_store: 'O aggiungi ad un negozio esistente',
  add_new_store: 'Aggiungi nuovo negozio',
  store_information: 'Informazioni negozio',
  store_name: 'Nome negozio',
  go_to_web_dashboard: 'Vai alla Web Dashboard',
  error_create_account: 'Email già registrata.',
  error_creation_error: 'Errore durante la registrazione.',
  error_wrong_phone_number: 'Inserisci un numero di telefono valido.',
  error_try_again: 'Problema connessione. Riprova.',
  error_from_the_server: 'Imposibile stabilire una connessione con il server.',
  error_update_cc_info: 'Impossibile aggiornare le informazioni della carta di credito.',
  error_add_device: 'Impossibile aggiungere dispositivo al negozio corrente.',
  error_add_store: 'Impossibile aggiungere nuovo negozio.',
  are_not_valid: 'non sono validi',
  is_not_valid: 'non è valido',
  are_empty: 'sono vuoti',
  is_empty: 'è vuoto',
  are_not_selected: 'non sono selezionati',
  is_not_selected: 'non è selezionato',
  bill: 'Conto',
  URL_dashboard: 'https://backoffice.countrhq.com/',
  URL_support: 'mailto:support@countrhq.com',
  URL_forgot_password: 'https://backoffice.countrhq.com/#/auth/forgot',
  close: 'Chiudi',
  no_product_found: 'Nessun prodotto trovato',
  please_go_to_dashboard: 'Visita https://backoffice.countrhq.com',
  select_interface: 'Seleziona interfaccia',
  refund_all: 'Rimborsa tutti',
  purchased_items: 'Oggetti acquistati',
  refundable: 'Rimborsabili',
  to_be_refunded: 'Da rimborsare',
  out_of: 'di',
  enter_a_printer_name: 'Inserisci nome stampante',
  cart_is_empty: 'Il carrello è vuoto',
  searching_printers: 'Ricerca stampanti...',
  no_printers_found: 'Nessuna stampante trovata.',
  total_due: 'Totale dovuto',
  payment_not_successful: 'Pagamento non riuscito.',
  error_processing_payment: 'Errore nel processare il pagamento.',
  purchase: 'Acquisto',
  transaction_aborted: 'Transazione annullata',
  authorization_error: 'Errore autorizzazione',
  authorization_ok: 'Autorizzazione OK',
  transaction_cancelled: 'Transazione cancellata',
  clearance_error: 'Errore autorizzazione',
  clearance_ok: 'Autorizzazione confermata OK',
  transaction_declined: 'Transazione declinata',
  transaction_complete: 'Transazione completata',
  initiating: 'Inizializzazione',
  application_selected: 'In attesa del teminare di pagamento',
  scheduled_for_clearance: 'Programmata clearance off-line',
  terminal_error: 'Errore terminale',
  unknown_status: 'Stato sconosciuto',
  waiting_for_clearance: 'In attesa di conferma clearance off-line',
  amount_exceeded: 'Quantità massima superata',
  authorization_timeout: 'Tempo scaduto autorizzazione',
  batch_processing_failed: 'Elaborazione batch non riuscira',
  cancelled: 'Cancellato',
  card_blocked: 'Carta bloccata',
  card_invalid: 'Carta invalida',
  card_declined: 'Carta declinata',
  transaction_failed: 'Transaciont non riuscita',
  insufficient_funds: 'Fondi insufficienti',
  terminal_not_paired: 'Terminale non accoppiato',
  pin_incorrect_last_retry: 'PIN incorretto - ultimo tentativo',
  pin_incorrect_retry: 'PIN incorretto - riprova',
  printer_busy: 'Stampante occupata',
  printer_error: 'Errore stampante',
  printer_not_present: 'Stampante non presente',
  printer_out_of_paper: 'Stampante senza carta',
  success: 'Successo',
  system_error: 'Errore sistema',
  transaction_not_found: 'Transazione non trovata',
  unknown_device: 'Dispositivo sconosciuto',
  apply_discount: 'Applica Sconto',
  seqr_id: 'ID commerciante SEQR',
  seqr_password: 'Password commerciante SEQR',
  bitpay_api_token: 'Token API Bitpay',
  registration_successful: 'Terminale registrato con successo',
  unregistration_successful: 'Terminale annullato con successo',
  payment_init_not_successful: 'Pagamento non inizializzato - controlla la connessione di rete.',
  always_show_addons: 'Mostra sempre i componenti aggiuntivi',
  show_product_image: 'Mostra l"immagine del prodotto',
  settings_connected_printers: 'Stampanti collegate',
  settings_add_new_printer: 'Aggiungi nuova stampante',
  wifi_lan: 'WiFi o LAN',
  bluetooth: 'Bluetooth',
  usb: 'USB',
  paired_barcode_scanners: 'Scanner di codici a barre accoppiati',
  account: 'account',
  refresh_products: 'Aggiorna i prodotti',
  server_synchronisation: 'Sincronizzazione del server',
  sales: 'I saldi',
  today: 'Oggi',
  refunds: 'Rimborsi',
  product: 'Prodotto',
  product_by_category: 'Prodotti per categoria',
  see_categories_details: 'Vedi i dettagli della categoria',
  open_orders_finish_payment_or_empty:
    'Ci sono carrelli aperti. Completa il pagamento o svuota il carrello.',
  name: 'Nome',
  loading_products: 'Caricamento dei prodotti',
  you_should_select_at_least_one_item: 'Devi selezionare almeno un elemento',
  select_card_provider: 'Seleziona un fornitore di carte',
  user_cancelled: 'Pagamento annullato dal cliente',
  card_payment: 'Pagamento con carta',
  manage_products: 'Gestisci i prodotti',
  edit_mode: 'Modalità Modifica',
  lock: 'serratura',
  loyalty: 'lealtà',
  account_card: 'Carta del conto',
  account_lookup: 'Ricerca account',
  Activate: 'Attivare',
  add_anonymous_customer: 'Aggiungi cliente anonimo',
  add_customer: 'Aggiungi cliente',
  add_customer_to_cart: 'Seleziona cliente',
  add_new_account: 'Aggiungi un nuovo account',
  add_new_customer: 'Aggiungi nuovo cliente',
  add_note_hint: 'Aggiungi nota',
  add_tax_to_price: 'I prezzi escludono le tasse',
  add_tip: 'Aggiungi la mancia',
  add_to_store: 'Aggiungi negozio',
  addons: 'Addons',
  admin_pin: 'PIN amministratore',
  admin_pin_saved: 'Nuovo PIN amministratore salvato',
  admin_pin_should_be: 'Il PIN amministratore deve essere un numero compreso tra 4 e 6 cifre.',
  aid: 'AIUTO',
  aidCard: 'aidCard',
  allow_cart_discounts: 'Consenti sconti a livello di carrello',
  allow_custom_cart_discounts: 'Consenti sconti personalizzati a livello di carrello',
  allow_custom_items: 'Consenti articoli personalizzati',
  allow_product_discounts: 'Consenti sconti a livello di prodotto',
  allow_custom_product_discounts: 'Consenti sconti personalizzati a livello di prodotto',
  amount_hint: '999',
  app_version: 'Versione',
  applicationLabel: 'applicationLabel',
  applicationName: 'Nome dell"applicazione',
  applicationPreferredName: 'applicationPreferredName',
  appName: 'nome dell"applicazione',
  ask_tips: 'Abilita le mance',
  authorizationCode: 'codice di autorizzazione',
  available_points: 'Punti disponibili',
  available_rewards: 'Ricompense disponibili',
  back: 'Indietro',
  barcode_scanner_disconnected:
    'Il tuo codice a barre è connesso ma potrebbe essere spento. Prova ad accenderlo.',
  barcode_scanner_not_found: 'Nessuno scanner di codici a barre trovato',
  barcode_scanner_not_found_desc:
    'Collega un nuovo scanner di codici a barre: attiva il Bluetooth nelle impostazioni del dispositivo e cerca uno scanner di seguito',
  bunq: 'bunq',
  bunq_admin_approval:
    'Un amministratore di bunq esaminerà e approverà il tuo registratore di cassa prima che possa essere utilizzato.',
  bunq_instructions:
    '1) Apri l"app bunq e inserisci il PIN (se richiesto) \n2) Scegli "Scansiona codice QR" o "QR" dalla schermata principale \n3) Scansiona il codice sullo schermo \n4) Aggiungi una mancia se lo desideri \n5) Conferma il pagamento',
  can_only_refund_entire_amount:
    'Puoi rimborsare l"intero importo della ricevuta solo tramite {{payment_method}}',
  cancel: 'Annulla',
  cancel_payment: 'Annulla il pagamento',
  cannot_pay_later_more_than_remaining_value:
    'Non puoi pagare più del valore rimanente ({{amount}}) con il metodo paga in seguito.',
  cannot_refund_transaction_with_partial_postponed_payments:
    'Spiacenti, questa transazione contiene uno o più pagamenti posticipati parziali, paga prima la transazione o rimborsa tutto',
  cant_find_products:
    'Non riesci a trovare tutti i tuoi prodotti nel POS? Utilizza la casella di ricerca in alto o un lettore di codici a barre per trovarli.',
  card_scheme: 'Schema della carta',
  cardBrand: 'cardBrand',
  cardExpiryMonth: 'Mese di scadenza della carta MM',
  cardExpiryYear: 'Anno di scadenza della carta YY',
  cardType: 'tipo di carta',
  cart_is_frozen_finish_payment:
    'Il carrello è congelato. Completa il pagamento prima di eseguire qualsiasi azione.',
  cash_difference: 'Differenza',
  cash_drawer: 'Registratore di cassa',
  cash_in: 'Incassare',
  cash_in_out: 'Cash in / out',
  cash_out: 'Incassare',
  change: 'Modificare',
  change_currency: 'Cambia valuta',
  change_given: 'cambiamento dato',
  change_open_orders_amount: 'Modifica l"importo del carrello aperto',
  check_location_settings: 'Controlla le impostazioni della posizione',
  check_network: 'Controlla la connessione di rete',
  choose_category: 'Scegli una categoria di attività ...',
  choose_mobile_provider: 'Più provider abilitati: scegline uno',
  clear_settings: 'Cancella impostazioni',
  clear_settings_successful: 'Impostazioni cancellate con successo',
  clear_settings_warning:
    'La cancellazione di queste impostazioni ripristinerà le informazioni di connessione. Fare clic su conferma per procedere.',
  client_ticket: 'clientTicket',
  close_shift: 'Chiudi registro',
  company_address: 'Recapito di lavoro',
  company_registration_no: 'Numero di registrazione della società',
  completed_payments: 'Pagamenti completati',
  confirm: 'Confermare',
  confirm_cash_payment: 'Conferma l"importo del pagamento',
  confirm_new_currency: 'Conferma il cambio di valuta',
  confirm_open_orders_amount_change: 'Confermare la modifica dell"importo dei carrelli in sospeso?',
  confirm_payment: 'Conferma il pagamento',
  confirm_with_change: 'Conferma con modifica',
  confirm_with_tip: 'Conferma con la mancia',
  connect_barcode: 'Connetti barcodescanner (Socket CHS)',
  connect_to_webshop: 'Connetti il ​​tuo webshop?',
  connected_printer: 'Stampanti collegate',
  contact_information: 'Informazioni sui contatti',
  contact_us: 'support@countrhq.com',
  create_product: 'Crea prodotto',
  currently_trial_mode_transaction_wiped_visit_dashboard:
    'Il POS è attualmente in modalità test. Le tue transazioni verranno cancellate ogni quattro ore.',
  currently_trial_mode_visit_dashboard:
    'Il POS è attualmente in modalità test. Puoi passare alla modalità live visitando la dashboard online.',
  customer: 'Cliente',
  customer_card: 'Scheda cliente',
  customer_info: 'Informazioni per il cliente',
  customer_instructions: 'Istruzioni per il cliente',
  customer_lookup: 'Ricerca clienti',
  customer_lookup_error: 'Errore del server durante la ricerca del cliente',
  customer_name: 'Cliente',
  customer_not_valid: 'Cliente non valido',
  customer_receipt: 'Ricevuta del cliente',
  customer_since: 'Cliente dal',
  Date: 'Data',
  deregister_terminal: 'Annullare la registrazione del dispositivo',
  description_mandatory: 'Una descrizione è obbligatoria per le transazioni di prelievo e prelievo',
  device_locked: 'Dispositivo bloccato',
  device_locked_enter_pin:
    'Questo dispositivo è bloccato. \nInserisci il tuo PIN amministratore per sbloccarlo',
  did_cheque_refund_succeed: 'Assegno restituito al cliente?',
  did_mobile_payment_succeed: 'Il pagamento mobile è riuscito?',
  did_mobile_refund_succeed: 'Il rimborso del cellulare è riuscito?',
  direct_connection: 'Direttamente connesso',
  disabled_product: 'Prodotto disabilitato',
  discount_currency: 'Sconto ({{currency}})',
  do_you_want_remove_payment: 'Vuoi rimuovere il pagamento?',
  download_adyen_app: 'Scarica l"app Adyen',
  download_izettle_app: 'Scarica l"app iZettle',
  download_payleven_app: 'Scarica l"app Payleven',
  download_sumup_app: 'Scarica l"app Sumup',
  download_yodo_app: 'Scarica l"app Yodo Rocket',
  downloading_categories: 'Download delle categorie ...',
  downloading_taxes: 'Download delle tasse ...',
  downloading_transactions: 'Download delle transazioni ...',
  ecommerce_none: 'Creerò i prodotti da solo',
  ecrId: 'ecrId',
  eft_transaction_code: 'EFTTxnID',
  email_invoice: 'Fattura tramite posta elettronica',
  employee_name: 'Dipendente',
  employee_pin: 'PIN dipendente',
  empty_cart: 'Carrello vuoto',
  enable_staff_pin: 'Abilita l"accesso tramite PIN personale',
  enter_a_valid_search_value: 'Immettere un valore di ricerca valido',
  enter_api_key: 'Inserisci la tua chiave di attivazione',
  enter_base_url: 'Inserisci l"URL (incluso "http: //")',
  enter_merchant_auth_code_payconiq: 'Inserisci la tua intestazione di autorizzazione Payconiq',
  enter_merchant_iban_payconiq: 'Inserisci il tuo IBAN registrato su Payconiq',
  enter_merchant_id_payconiq: 'Inserisci il tuo ID commerciante Payconiq',
  enter_order_name: 'Inserisci il nome del carrello',
  enter_reseller_id: 'Inserisci il tuo ID rivenditore',
  enter_user_number: 'Immettere il numero utente',
  enter_user_password: 'Immettere la password utente',
  error_adding_device: 'Si è verificato un errore durante la registrazione del dispositivo',
  error_refreshing_merchant: 'Errore durante il recupero delle informazioni sull"account.',
  error_retrieve_categories:
    'Errore durante il recupero delle categorie. \nControlla la connessione a Internet e riprova',
  error_retrieve_products:
    'Errore durante il recupero dei prodotti. \nControlla la connessione a Internet e riprova',
  error_retrieve_taxes:
    'Errore durante il recupero delle tasse. \nControlla la connessione a Internet e riprova',
  error_while_retrieving_results: 'Errore durante il recupero dei risultati',
  errorCode: 'codice di errore',
  errorMessage: 'messaggio di errore',
  ersReference: 'ersReference',
  excess_amount: 'Importo in eccesso',
  expected_amount: 'Importo previsto',
  expected_change_gratuity: 'Cambiamento previsto o mancia',
  external_url: 'URL esterno',
  failed_cancel: 'Impossibile annullare la transazione',
  feature_only_android: 'Questa funzione è disponibile solo per Android',
  find_terminal: 'Cerca terminali',
  for_security_enter_password: 'Per motivi di sicurezza, inserisci nuovamente la tua password',
  for_this_device: 'Solo per questo dispositivo',
  for_this_store: 'Solo per questo negozio',
  four_inch: '4 pollici',
  generate_keys: 'Genera chiavi',
  generating_keypair:
    'Generazione coppia di chiavi in ​​corso ... \nL"operazione può richiedere diversi minuti',
  generating_reports: 'Generazione rapporto in corso ...',
  generic_usb: 'USB generico',
  get_your_free_pos: 'Ottieni il tuo POS su www.countrhq.com',
  gift_code: 'Codice regalo',
  gratuity_amount: 'Importo della mancia',
  gratuity_percentage: 'Percentuale di mancia',
  group_items_in_cart: 'Raggruppa gli stessi articoli nel carrello',
  shared_secret: 'SharedSecret',
  impossible_refresh_you_have_transactions:
    'Hai {{transaction_number}} transazioni non sincronizzate con il server. \nLa sincronizzazione di queste transazioni verrà ora tentata in background ...',
  impossible_signout_you_have_transactions:
    'Impossibile signout. \nHai {{transaction_number}} transazioni non sincronizzate con il server. \nLa sincronizzazione di queste transazioni verrà ora tentata in background ...',
  insert_printer_name: 'Inserisci un nome (3 o più caratteri) per la tua stampante.',
  inserted_login_not_valid: 'Login errato',
  inserted_value_not_valid: 'Il valore inserito non è valido',
  installmentAmount: 'installmentAmount',
  invalid_credit_card_number: 'Il numero della carta di credito deve essere di 16 cifre',
  invoiceReference: 'invoiceReference',
  invoiceStatus: 'invoiceStatus',
  ip_address: 'Indirizzo',
  is_business: 'Dettagli aziendali',
  is_correct_amount_tendered: '{{Amount}} è l"importo corretto?',
  isDone: 'Non più da caricare ...',
  isError: 'Tocca per riprovare ...',
  isTap: 'Tocca per caricare altro ...',
  join_thousands_of_merchants:
    'Migliaia di commercianti si sono uniti a Countr. Oggi tocca a te. Iscriviti gratis',
  language: 'linguaggio',
  last_visit: 'L"ultima visita',
  leave_tour: 'Esci dal tour',
  leaving_tour: 'Uscita dal tour ...',
  loyal_customer: 'Cliente fedele',
  mac_address: 'Indirizzo MAC',
  mailing_list_permission: 'Aggiungi alla mailing list',
  manual_mobile_payment: 'Pagamento mobile',
  manual_mobile_refund: 'Rimborso mobile',
  max_amount_exceeded: 'L"importo supera il massimo consentito per questo metodo di pagamento',
  menu_item_12char: 'Il nome della voce di menu non può contenere più di 12 caratteri',
  menu_item_name: 'Nome della voce di menu (max.12 caratteri)',
  merchant_receipt: 'Ricevuta del commerciante',
  merchantDisplayName: 'merchantDisplayName',
  merchantId: 'identificativo del venditore',
  mid: 'MID',
  mobile_number: 'Numero di cellulare',
  mobile_payment: 'Mobile',
  mobile_payments: 'Pagamenti mobili',
  new_price: 'Nuovo prezzo ({{currency}})',
  no_available_addons_for_product:
    'Nessun componente aggiuntivo disponibile per il prodotto selezionato.',
  no_connected_printer: 'Nessuna stampante collegata',
  no_connected_terminal: 'Nessun terminale collegato',
  no_customer_found: 'Nessun cliente trovato',
  no_customer_selected: 'Nessun cliente selezionato',
  no_printer_configured: 'Nessuna stampante configurata',
  no_printer_found:
    'Nessuna stampante trovata \nFai clic qui se hai una stampante Star collegata tramite USB',
  no_product_in_cart: 'Nessun prodotto nel carrello',
  no_terminals_found: 'Nessun terminale trovato',
  no_url_specified: 'Errore, nessun URL specificato.',
  none: 'Nessuna',
  note_mandatory_cash_difference:
    'Una nota è obbligatoria se c"è una differenza tra gli importi previsti e quelli effettivi',
  note_optional: 'Nota (opzionale)',
  numberOfInstallments: 'numero di installazioni',
  numpad: 'Numpad',
  offline: 'disconnesso',
  ok: 'Va bene',
  one_or_more_carts_are_frozen_finish_payment:
    'Uno o più carrelli sono congelati. Completa il pagamento prima di eseguire qualsiasi azione.',
  online: 'in linea',
  open_market: 'Vuoi aprire il mercato?',
  open_register_before_transaction:
    'Devi aprire il registro prima di poter effettuare qualsiasi transazione',
  open_shift: 'Apri registro',
  order_printer: 'Stampante per carrello',
  orderId: 'ID ordine',
  orderRef: 'orderRef',
  other_text: 'Altro testo',
  paid: 'Pagato',
  paid_so_far: 'Pagato finora',
  paid_with_method: 'Pagato con {{method}}',
  pan: 'PADELLA',
  panLastDigits: 'Pan ultime cifre',
  panMasked: 'Pan mascherato',
  PANSeq: 'PANSeq',
  partial_payment: 'Pagamento parziale',
  cashdrawer_partial_payment: 'Cassetto contanti per pagamento parziale',
  cashdrawer_partial_payment_desc: 'Apri il cassetto contanti per ogni pagamento parziale',
  partial_payment_not_allowed_for: 'Non sono ammessi pagamenti parziali',
  partial_payment_paid_successfully: 'Il pagamento parziale è stato pagato con successo',
  pay_later: 'Paga dopo',
  pay_later_allows_only_refund_all:
    'Con il pagamento successivo, puoi rimborsare solo tutto. Per rimborsi parziali, completare il pagamento.',
  pay_later_refund: 'Annulla paga dopo',
  pay_on_account: 'Paga in acconto',
  payconiq: 'Payconiq',
  paying_now: 'Pagando adesso',
  payment_cancelled: 'Pagamento annullato',
  payment_error: 'errore di pagamento',
  payment_info: 'Informazioni sul pagamento',
  payment_insert_card: 'Inserire la carta',
  payment_method_not_configured:
    '{{payment_method}} non configurato correttamente su questo dispositivo',
  payment_number: 'Numero di pagamento',
  payment_processor_options: 'Opzioni del processore di pagamento',
  payment_provider: 'Fornitore di servizi di pagamento',
  payment_success: 'Pagamento riuscito',
  payment_terms_in_net_days: 'Termini di pagamento (giorni netti)',
  personal_information: 'Informazione personale',
  pickup_rewards: 'Raccogli i premi',
  pin: 'PIN',
  please_insert_a_valid_amount: 'Si prega di inserire un importo valido',
  posEntryMode: 'posEntryMode',
  prefName: 'prefName',
  print: 'Stampa',
  print_also_product_details: 'Vuoi stampare anche i dettagli del prodotto?',
  print_error: 'Errore di stampa',
  print_product_details: 'Stampa i dettagli del prodotto',
  print_successful: 'Stampa riuscita',
  printer_connect_desc:
    'Le stampanti WiFi devono essere collegate alla stessa rete di questo dispositivo. \nLe stampanti Bluetooth devono essere accoppiate tramite le impostazioni di sistema prima di essere disponibili qui',
  printer_removed: 'Stampante rimossa',
  printer_test: 'Test della stampante',
  printing_report: 'Generazione e stampa del rapporto ...',
  proceed_with_next_payment: 'Procedi con il prossimo pagamento.',
  processorName: 'processorName',
  product_is_disable_continue_anyway:
    'Il prodotto selezionato non è disponibile (forse esaurito). Vuoi aggiungerlo comunque al carrello?',
  product_sync: 'Sincronizzazione del prodotto',
  profile_average: 'Acquisto medio',
  protected_sections: 'Sezioni protette',
  receipt_printer: 'Stampante per ricevute',
  redeem_reward: 'Riscatta la ricompensa',
  referenceNumber: 'numero di riferimento',
  refresh_customers: 'Aggiorna l"elenco dei clienti',
  refresh_merchant: 'Aggiorna le informazioni sull"account',
  refresh_transactions: 'Aggiorna le transazioni',
  refreshing_merchant: 'Aggiornamento delle informazioni sull"account ...',
  refund_number: 'Numero di rimborso',
  refunds_not_available_for_provider:
    'Rimborsi non disponibili per il fornitore: {{payment_provider}}',
  register_terminal: 'Registra dispositivo',
  reissue: 'Ristampa',
  remaining_payment: 'Pagamento rimanente',
  remove_customer_from_cart: 'Rimuovere dal carrello',
  remove_payment: 'Rimuovi pagamento',
  remove_printer: 'Rimuovi stampante',
  removing_products: 'Rimozione dei vecchi prodotti ...',
  reopen_shift: 'Riapri il registro',
  replace_customer: 'Sostituisci cliente',
  report_by_shift: 'Abilita apertura / chiusura giornaliera',
  report_printer: 'Report printer',
  requested_amount: 'Importo richiesto',
  reward: 'Ricompense',
  round_cash_transactions: 'Arrotonda i pagamenti in contanti allo 0,05 più vicino',
  save_printer: 'Salva stampante',
  scan: 'Scansione',
  scan_barcode_instruction_one:
    'Scansiona il codice a barre per mettere lo scanner Bluetooth Socket in modalità di accoppiamento',
  scan_barcode_instruction_two:
    'Nelle impostazioni del dispositivo, assicurati che il Bluetooth sia attivato, quindi associa lo scanner Socket CHS',
  scan_code_not_exist: 'Codice scansionato {{barcode}} non riconosciuto',
  scan_qr_code: 'Scansiona il codice QR con l"app di pagamento',
  scanner: 'Scanner',
  scanner_connected: 'Scanner di codici a barre collegato',
  scanner_disconnected: 'Barcodescanner scollegato',
  scanner_pair_completed: 'Abbinamento del codice a barre completato',
  search_for_customer: 'Cerca cliente',
  search_report: 'Rapporti di ricerca',
  search_transaction: 'Cerca transazione',
  searching_terminals: 'Ricerca dei terminali in corso ...',
  security_and_permissions: 'Sicurezza e autorizzazioni',
  select_one_payment_method: 'Deve essere abilitato almeno un metodo di pagamento',
  select_payment_method: 'Si prega di selezionare la carta AID dall"elenco seguente',
  select_terminal: 'Seleziona un terminale',
  send_email: 'Invia una email',
  send_to_another_customer: 'Invia a un altro cliente',
  send_to_current_customer: 'Invia al cliente attuale',
  send_to_customer: 'Invia al cliente',
  sending_receipt: 'Invio ricevuta in corso ...',
  server_error: 'Errore del server',
  session: 'Sessione',
  set_new_price: 'Nuovo prezzo',
  setting_saved: 'Impostazione salvata',
  settings_connected_terminals: 'Terminale collegato',
  settings_printer_available_printers: 'Stampanti disponibili',
  settlementDate: 'data di regolamento',
  setup_admin_pin_in_settings:
    'Imposta il tuo PIN amministratore in Impostazioni, nella scheda Account.',
  shift_closed: 'Il registro è stato chiuso',
  shift_opened: 'Il registro è stato aperto',
  shifts: 'Turni',
  shopper_reference: 'ShopperReference',
  show_customer: 'Mostra le informazioni del cliente al ricevimento',
  show_employee: 'Mostra il nome del dipendente al ricevimento',
  show_customer_payconiq:
    'Scegli un cliente registrato o scansiona il codice a barre / token NFC del cliente',
  show_new_product_tile: 'Mostra il riquadro "crea prodotto"',
  show_password: 'Mostra password',
  sign_out_header: 'Sei sicuro?',
  sign_out_warning:
    'La disconnessione potrebbe ripristinare le impostazioni dell"app, inclusi i provider di pagamento e le stampanti. Utilizzare la funzione di blocco (con un PIN amministratore o dipendente) se non si desidera cancellare le impostazioni.',
  sign_up_to_print:
    'Registrati per un account di prova gratuito per stampare una ricevuta. Nessuna carta di credito richiesta, annulla in qualsiasi momento.',
  sign_up_to_send_email:
    'Registrati per un account di prova gratuito per inviare una ricevuta e-mail. Nessuna carta di credito richiesta, annulla in qualsiasi momento.',
  signing_out: 'Uscita in corso ...',
  signup_for_mailing_list: 'Chiedi ai clienti di iscriversi alla mailing list',
  sorry_report_not_available: 'Rapporto non disponibile.',
  start_using_pos: 'Inizia a vendere con Countr',
  state: 'Stato / Provincia',
  status: 'Stato',
  store_powered_by: 'Questo negozio è alimentato da',
  street: 'strada',
  suggested_amount: 'Importo suggerito',
  suggested_tip: 'Mancia suggerita',
  support: 'Supporto',
  telephone: 'Telefono',
  terminal_port: 'Porta configurata per il terminale',
  terminal_selected: 'Terminale salvato',
  terminalId: 'terminalId',
  test: 'Questo è un test',
  three_inch: '3 pollici',
  tid: 'TID',
  Time: 'Tempo',
  tip: 'Mancia',
  tip_added: 'Mancia aggiunta',
  to_pay: 'Pagare',
  total_amount: 'Importo totale',
  transaction_code: 'transactionCode',
  transaction_date: 'TransactionDate',
  transaction_finalized: 'Transazione finalizzata',
  transaction_pending: 'Transazione in sospeso',
  transaction_started: 'Transazione iniziata',
  transaction_succeeded: 'Transazione riuscita',
  transaction_time: 'Tempo di transazione',
  transaction_timeout: 'Timeout transazione',
  transactionSeq: 'transazioneSeq',
  transactionType: 'transactionType',
  try_it_now: 'Provalo ora',
  tsi: 'STI',
  tvr: 'TVR',
  two_inch: '2 pollici',
  uncategorized: 'Nessuna categoria',
  valid_phone_number: 'Numero di telefono valido',
  validating_fields: 'Convalida dei campi ...',
  validation_in_progress: 'Convalida in corso',
  verification_code: 'Codice di verifica',
  verification_method: 'Metodo di verifica',
  waiting_for_customer: 'Aspettando il cliente',
  want_to_sync_products: 'Vorresti sincronizzare i tuoi prodotti?',
  yodopay: 'YodoPaY',
  you_must_select_a_store: 'Devi selezionare un negozio.',
  add_name_device:
    'Aggiungi un nome al tuo tablet / telefono per identificarlo facilmente in seguito (obbligatorio)',
  card_number: 'Numero di carta',
  change_language: 'Cambia lingua',
  choose_currency: 'Scegli la valuta principale',
  clear_cart_after_finish: 'Reimposta il nome del carrello / tabella dopo il pagamento',
  confirm_new_language:
    'Confermare la nuova lingua? \nDovrai chiudere e riavviare completamente l"app per applicare questa modifica',
  customer_signature: 'Firma del cliente',
  day: 'Giorno',
  device_name_mandatory: 'Il nome del dispositivo è obbligatorio',
  downloading_refunds: 'Download rimborsi in corso ...',
  email_report: 'Rapporto e-mail',
  email_successfully_sent_test: 'Email di prova inviata al tuo account',
  enter_table_name: 'Immettere il nome della tabella',
  error: 'Errore',
  error_retrieve_refunds: 'Errore durante il recupero dei rimborsi',
  error_retrieve_transactions: 'Errore durante il recupero delle transazioni',
  for_this_device_see_dashboard:
    'Solo per questo dispositivo: vedere Dashboard per ulteriori rapporti',
  hide_new_sale: 'Nascondi la schermata "Nuova vendita" dopo il pagamento',
  last_transaction_result: 'Ultimo risultato della transazione',
  merge_warning: 'Questo unirà il contenuto di questi carrelli / tabelle',
  month: 'Mese',
  post_code: 'Codice postale',
  print_order: 'Mostra stampante da inviare a ordine',
  products_added_removed_refresh: 'I prodotti sono stati aggiunti o rimossi. Aggiornare l"elenco?',
  quarter: 'Trimestre',
  refresh: 'ricaricare',
  refreshing_products: 'Prodotti rinfrescanti ...',
  removing_categories: 'Rimozione delle vecchie categorie ...',
  removing_taxes: 'Rimozione delle vecchie tasse ...',
  select_interface_mandatory: 'La selezione di un"interfaccia è obbligatoria',
  sms_successfully_sent_test: 'SMS di prova inviati al tuo account',
  splitOrderPrintByCategory: 'Stampa divisa degli ordini per categoria',
  stored_digitally: 'Archiviato digitalmente',
  stripe_id: 'stripeID',
  sync_support_info: 'Sincronizza le informazioni di supporto',
  syncing_support_info: 'Sincronizzazione delle informazioni di supporto ...',
  table: 'tavolo',
  table_is_empty: 'La tabella è vuota',
  tables_or_carts: 'Mostra i carrelli come tabelle',
  uncertain_card_payment_success: 'Incerto pagamento con carta di successo',
  unknown_category: 'Categoria sconosciuta',
  unsynced_transactions_report_incorrect:
    '{{transaction_number}} transazioni non sincronizzate - rapporti non aggiornati',
  update_terminal_firmware: 'Aggiorna il firmware del terminale',
  week: 'Settimana',
  year: 'Anno',
  email_queued: 'E-mail in coda',
  order_received: 'Ordine nuovo / aggiornato ricevuto',
  go_to_orders: 'Visualizza gli ordini',
  open_carts: 'Carrelli aperti',
  auto_print_order: 'Stampa ordini non stampati a pagamento',
  auto_print_order_cart: 'Stampa ordini non stampati su cambio carrello / tabella',
  external: 'Altro',
  no_printer_found_tcp:
    'Nessuna stampante trovata \nFai clic qui per inserire manualmente le informazioni sulla stampante',
  downloading_carts: 'Download carrelli ...',
  pay_later_hybrid: 'Paga dopo',
  error_retrieve_carts: 'Errore durante il recupero dei carrelli',
  no_ip_address: 'Nessun indirizzo',
  building_number: 'Numero edificio',
  confirm_manual_payment: 'Conferma il pagamento manuale',
  shipping_address: 'Indirizzo di spedizione',
  birth_date: 'Data di nascita',
  for_this_store_see_dashboard: 'Solo per questo negozio: vedi Dashboard per ulteriori rapporti',
  customers: 'I clienti',
  tickets_in: 'Biglietti in',
  tickets_out: 'Biglietti fuori',
  tickets_in_out: 'Biglietti in / out',
  unsynced_transaction_cant_refund:
    'Transazione non ancora sincronizzata con il server - rimborso non possibile',
  show_stock_level: 'Visualizza l"attuale livello di scorte (se disponibile)',
  available: 'disponibile',
  stock_not_available: 'stock non tracciabile',
  order_another_store: 'Ordina da un"altra posizione',
  order_products: 'Abilita l"ordinazione di prodotti da altre posizioni',
  trial_expired: 'Il tuo periodo di prova è scaduto. La funzionalità POS è stata disabilitata.',
  left_menu: 'Barra di navigazione sinistra',
  right_menu: 'Barra di navigazione destra',
  direct_pay_if_one_method: 'Avvio automatico del pagamento se 1 metodo impostato',
  processing_vouchers: 'Elaborazione della fedeltà del carrello e dei buoni in corso ...',
  display_2_rows_of_categories: 'Visualizza 2 righe di categorie',
  unknown_barcodes_as_vouchers: 'Considera i codici a barre non riconosciuti come buoni',
  validate_signature: 'Firma del cliente valida?',
  group_items_in_receipt_and_cart: 'Raggruppa gli stessi articoli nel carrello e nella ricevuta',
  ask_customer_subscribe_email: 'Chiedere al cliente di fornire l"indirizzo e-mail',
  hide_categories: 'Nascondi le tessere delle categorie',
  hide_all_products_category: 'Nascondi il riquadro della categoria Tutti i prodotti',
  provisional_receipt: '*** PROVVISORIO ***',
  reprint: '*** RISTAMPA ***',
  block_reprints: 'Non consentire la ristampa degli articoli',
  reports_at_store_level: 'Mostra rapporti per negozio, non dispositivo',
  max_addon_qty_exceeded:
    'Massimo di {{maxQty}} componenti aggiuntivi consentiti per questo gruppo',
  min_addon_qty_unmet: 'Minimo di {{minQty}} componenti aggiuntivi richiesti da {groupName}',
  press_button_to_wake_terminal: 'Premere il pulsante verde per riattivare il terminale PIN',
  payments: 'Pagamenti',
  average_sale_duration_mins: 'Durata media della vendita (min)',
  save_order_move_next: 'Salva ordine, passa alla successiva tabella vuota',
  max_orders_reached: 'Numero massimo di ordini aperti raggiunto',
  show_open_orders: 'Mostra gli ordini aperti per primi nell"elenco degli ordini',
  tender_created: 'Transazione iniziata',
  invalid_request: 'Richiesta non valida',
  already_processed: 'Transazione già elaborata',
  invalid_terminal: 'Terminale di pagamento non valido',
  network_error: 'Errore di rete',
  bad_credentials: 'Credenziali non valide',
  server_issue: 'Impossibile contattare il server',
  pin_entered: 'PIN inserito',
  initial: 'Inizializzazione',
  card_inserted: 'Carta inserita',
  card_swiped: 'Carta strisciata',
  wait_app_selection: 'In attesa di selezione dell"applicazione',
  wait_amount_adjustent: 'In attesa di adeguamento dell"importo',
  ask_gratuity: 'Chiedi la mancia',
  gratuity_entered: 'La mancia è entrata',
  ask_dcc: 'Richiedi DCC',
  dcc_accepted: 'DCC accettato',
  dcc_rejected: 'DCC rifiutato',
  processing_tender: 'Offerta di elaborazione',
  wait_pin: 'In attesa del PIN',
  provide_card_details: 'Fornisci i dettagli della carta',
  card_details_provided: 'Dettagli della carta forniti',
  receipt_printed: 'Ricevuta stampata',
  referral: 'Referral',
  referral_checked: 'Referral controllato',
  check_signature: 'Controlla la firma',
  signature_checked: 'Firma controllata',
  additional_data_available: 'Dati aggiuntivi disponibili',
  transaction_acknowledged: 'Transazione riconosciuta',
  device_already_registered: 'Dispositivo già registrato',
  add_in_progress: 'Sto già aggiungendo il dispositivo',
  app_not_registered: 'App non registrata',
  unknown_error: 'Errore sconosciuto',
  timeout: 'Fuori tempo',
  verify_failed: 'Impossibile verificare il dispositivo',
  identify_failed: 'Impossibile identificare il dispositivo',
  registration_error: 'Errore di registrazione',
  sync_issue: 'Impossibile sincronizzare il dispositivo',
  show_select_customer: 'Mostra selettore clienti',
  store_name_mandatory: 'Il nome del negozio è obbligatorio',
  choose_store_dashboard_for_more:
    'Scegli negozio: è possibile aggiungerne altri tramite la dashboard del back-office',
  recovering_incomplete_payment:
    'Recupero del pagamento incompleto: l"operazione può richiedere fino a 30 secondi',
  allow_negative_sale: 'Consenti importo di vendita negativo',
  move_current_table_to: 'Sposta la tabella corrente ({{table_name}}) A',
  store: 'Negozio',
  print_specific_categories: 'Stampa categorie specifiche',
  customer_display_attached: 'Display cliente allegato',
  sale: 'Vendita',
  reps_and_waste_button_in_cart: 'Mostra ripetizioni e pulsante di scarto sul carrello',
  small_product_tiles: 'Riduci le dimensioni delle piastrelle del prodotto',
  large_product_tiles: 'Aumenta le dimensioni delle piastrelle del prodotto',
  show_product_price_on_tile: 'Prezzo del prodotto sulla piastrella',
  printer_roles: 'Ruoli della stampante',
  paper_size: 'Dimensioni del foglio',
  customer_display: 'Display del cliente',
  enable_realtime_updates: 'Abilita gli aggiornamenti in tempo reale dal server',
  show_open_orders_first: 'Mostra gli ordini aperti per primi nell"elenco degli ordini',
  blank_lines_order_ticket: 'Righe vuote all"inizio del biglietto dell"ordine',
  blank_lines_bottom_order_ticket: 'Righe vuote in fondo al ticket dell"ordine',
  scan_card: 'Scansione della carta',
  scan_nfc_or_barcode: 'Scansiona la carta o il codice NFC',
  show_product_keypad: 'Mostra la tastiera sul carrello',
  extra_payment_methods: 'Metodi di pagamento extra',
  failed_recovery: 'Impossibile recuperare il pagamento',
  blank_line_between_order_items: 'Riga vuota tra le voci del biglietto dell"ordine',
  look_up_customers_using_barcodes: 'Aggiungi il cliente al carrello utilizzando lo scanner',
  error_loading_configuration: 'Errore durante il caricamento della configurazione',
  error_verifying_payment_terminal: 'Errore durante la verifica del terminale PIN',
  error_identifying_payment_terminal: 'Errore durante l"identificazione del terminale PIN',
  error_saving_configuration: 'Errore durante il salvataggio della configurazione',
  conflicting_server_modes:
    'Il terminale pin e il server hanno modalità in conflitto (TEST vs PRODUZIONE)',
  nothing_to_print: 'Nessun elemento da stampare',
  large_item_name: 'Aumenta le dimensioni dell"elemento stampato',
  print_ean_sku: 'Stampa EAN e SKU al ricevimento',
  select_category_on_startup: 'Seleziona la categoria una volta all"avvio',
  split_tickets_item: 'Stampa divisa degli ordini per articolo',
  split_tickets_amount: 'Stampa divisa degli ordini per quantità',
  split_print: 'Stampa divisa',
  refreshing_customers: 'Rinfrescare i clienti ...',
  downloading_customers: 'Download dei clienti ...',
  error_retrieve_customers:
    'Errore durante il recupero dei clienti. \nControlla la connessione a Internet e riprova',
  server_error_please_retry: 'Errore del server. Per favore riprova',
  vibrate_on_click: 'Feedback con vibrazione al clic sull"elemento',
  keep_screen_on: 'Non consentire allo schermo di dormire',
  refund_same_method: 'Forza i rimborsi con il metodo di pagamento originale',
  cannot_refund_more_than_paid:
    'Posso rimborsare solo un massimo di {{max_amount}} tramite {payment_method}',
  tablet_date_incorrect:
    'Data del dispositivo impostata in modo errato: possibile rischio per la sicurezza',
  extra_non_revenue_info:
    'Richiedi informazioni aggiuntive per gli elementi non relativi alle entrate',
  enter_extra_info: 'Immettere informazioni aggiuntive sull"articolo',
  giftcard_or_reference_no: 'Giftcard o numero di riferimento',
  inserted_code_not_valid: 'Codice errato',
  validate_user: 'Convalida utente ({{user_address}})',
  enter_validation_code: 'Inserisci il codice di convalida ricevuto tramite SMS o e-mail',
  tax_rate: 'Aliquota fiscale',
  pretax: 'Netto',
  deposit: 'Depositare',
  can_not_refund_with_different_provider:
    'Impossibile rimborsare {{txn_payment_method}} transazioni utilizzando {selected_payment_method}',
  external_scanner_connected: 'Scanner esterno collegato',
  cancelling: 'Annullamento',
  sumup: 'SumUp',
  ccv: 'CCV',
  payplaza: 'PayPlaza',
  worldline: 'Worldline',
  adyen: 'Adyen',
  rabosmartpin: 'Rabo SmartPin',
  exchange: 'Scambio',
  ask_exchange: 'Consenti lo scambio di prodotti',
  exchange_error: 'Errore di scambio',
  exchange_items: 'Scambia oggetti',
  exchangeable: 'Scambiabile',
  to_be_exchanged: 'Da scambiare',
  exchange_all: 'Scambia tutto',
  negative_sale_amount: 'L"importo da pagare è inferiore a 0',
  download_rabosmartpin_app: 'Scarica l"app Rabo SmartPin',
  transaction_disabled: 'Pagamenti di terze parti non consentiti in questo contratto',
  transaction_in_progress: 'Un altro pagamento è già in corso',
  refund_offline: 'I rimborsi non sono possibili in modalità limitata',
  refund_not_allowed: 'Non sono consentiti rimborsi per l"utente che ha effettuato l"accesso',
  cash_forbidden: 'Pagamenti in contanti non consentiti su questo contratto',
  notify_new_online_order: 'Mostra la notifica quando viene ricevuto un nuovo ordine online',
  print_new_online_order: 'Stampa automaticamente i nuovi ordini online una volta ricevuti',
  delivery_information: 'Informazioni sulla consegna',
  voucher_already_processed: 'Buono già elaborato',
  no_applicable_items_in_cart: 'Nessun articolo applicabile trovato nel carrello',
  discount_applied: 'Sconto applicato',
  invalid_voucher: 'Voucher non valido',
  allow_voucher_scan: 'Verificare la presenza di buoni utilizzando lo scanner di codici a barre',
  enable_drop_shipping: 'Abilita il drop shipping',
  ask_no_covers_or_takeaway: 'Chiedi sempre la configurazione della tabella',
  table_configuration: 'Tipo di tabella',
  num_covers: 'Numero di invitati',
  takeaway: 'Porta via',
  guests: 'Ospiti',
  show_floorplan_after_sale: 'Mostra la planimetria dopo ogni vendita',
  lock_after_x_minutes: 'Blocca dopo x minuti di inattività',
  show_deposit_items: 'Mostra elementi di deposito',
  pickup: 'Raccogliere',
  delivery: 'Consegna',
  transaction_fee: 'Costo della transazione',
  print_description: 'Stampa la descrizione dell"articolo al ricevimento',
  only_cash_for_negative_amounts:
    'Quando l"importo è inferiore a 0, è possibile selezionare solo il pagamento in contanti o manuale con carta',
  phone_number_example: 'Telefono - ad es. 31611111111',
  whatsapp_not_available_on_this_device: 'WhatsApp non è installato su questo dispositivo',
  receipt_not_yet_synchronised:
    'Sincronizzazione della transazione con il server: riprova tra qualche istante',
  notify_online_orders_with_sound: 'Riproduci suono quando ricevi ordini online',
  restrict_online_orders_by_floor: 'Accetta ordini online solo per determinati piani',
  remove_product: 'Rimuovi prodotto',
  admin_access_needed: 'Questa funzione è disponibile solo per gli utenti Admin',
  customer_or_employee_name: 'Nome del cliente o del dipendente',
  show_cart_on_left: 'Mostra il carrello sul lato sinistro',
  groupOrderPrintByCategory: 'Stampa di gruppo degli ordini per categoria',
  handpoint_shared_secret: 'SharedSecret',
  create_bundle: 'Crea pacchetto',
  select_items_to_bundle: 'Seleziona gli elementi da raggruppare',
  bundle_price: 'Prezzo del pacchetto',
  create_bundle_items: 'Crea un insieme di prodotti nel carrello',
  bundle: 'Bundle',
  loss: 'Loss',
  stolen: 'Stolen',
  own_use: 'Own Use',
  giveaway: 'Giveaway',
  breakage: 'Breakage',
  deviceid_mandatory: 'Il nome del dispositivo è obbligatorio',
  send_order: 'Invia ordine',
  no_description:
    'Questo prodotto non ha descrizione. Puoi aggiungerne uno premendo il pulsante "Cambia" o visitando la tua dashboard.',
  change_description: 'Si prega di inserire la descrizione del prodotto.',
  main_cart_is_empty: 'Il carrello principale è vuoto',
  apply_to_all_carts: 'Applica a tutti i carrelli',
  per_value_payment: '(Pagamento per valore)',
  select_a_seat: 'Seleziona un posto',
  open_kds: 'KDS',
  save_table: 'Salva',
  save_cart: 'Salva',
  select_items: 'Seleziona gli elementi',
  partial_payment_not_allowed_by_value: 'Non sono ammessi pagamenti parziali',
  store_insights: 'Informazioni sul negozio',
  allow_ticket_scan: 'Verificare la presenza di biglietti utilizzando lo scanner di codici a barre',
  no_pin_employee: 'Dipendente senza PIN',
  allow_ticket_scan_desc: 'Usa lo scanner di codici a barre per cercare i biglietti.',
  admin_only_custom_item: 'Abilita l"elemento personalizzato solo per l"amministratore',
  admin_only_custom_item_desc:
    'Solo gli utenti con diritti di amministratore possono creare un elemento personalizzato. Devi attivare anche il PIN dipendente.',
  mandatory_employee_pin: 'Attivazione obbligatoria del PIN dipendente.',
  admin_only_refunds: 'Solo gli amministratori possono eseguire rimborsi',
  admin_only_refunds_desc:
    'Solo gli amministratori possono effettuare rimborsi. Devi attivare anche il PIN dei dipendenti.',
  round_cash_transactions_desc:
    'Arrotonda automaticamente i pagamenti in contanti allo 0,05 più vicino',
  unlimited_usage: 'Utilizzo illimitato',
  infinite: 'Illimitato',
  disable_custom_discounts: 'Disabilita gli sconti personalizzati',
  disable_custom_discounts_desc:
    'Disabilita gli sconti personalizzati ma consenti quelli predefiniti.',
  guest: 'Ospiti',
  select_method: 'Seleziona il metodo',
  manual_insert: 'Inserimento manuale',
  enter_code: 'Inserisci il codice',
  existing_by_value_payment:
    '* Non è possibile combinare pagamento in base al valore e pagamento in base al posto e/o all"articolo',
  order_sent: 'Ordine Inviato',
  select_multiple_seats: 'Seleziona più posti',
  change_seat_or_guest_num: 'Cambia posto o numero ospite',
  change_guest_num: 'Cambia numero ospite',
  giftcard_low_value:
    '* Il saldo della Giftcard è inferiore al valore degli articoli selezionati. Prova invece a pagare in base al valore.',
  order_by_seat_desc: 'Abilita la funzione di ordinazione per posto.',
  received_amount: 'Importo ricevuto',
  order_products_action: 'Ordina prodotti',
  ordered_products: 'Prodotti ordinati',
  select_table: 'Seleziona tabella',
  ordered: 'Ordinato',
  scan_or_type: 'Scansiona o digita',
  type_code: 'Digitare il codice',
  number_extension: 'Estensione del numero',
  move_processing_ticket_first: 'Sposta prima i ticket di elaborazione',
  disable_adblocker: 'Disattiva il tuo AdBlocker e aggiorna la pagina',
  remove_partial_payment_by_item:
    'Pagamento parziale per articolo rilevato! Si prega di rimuoverlo per procedere con questa azione!',
  add_nutrients: 'Aggiungi sostanze nutritive',
  giftcard_warning_msg:
    'La validità standard di una carta regalo è di 2 anni. Sei sicuro di volerlo diminuire?',
  edit_devicess_main_details: 'Modifica i dettagli principali dei dispositivi.',
  create_custom_payment_methods_for_your_device:
    'Crea metodi di pagamento personalizzati per il tuo dispositivo.',
  show_open_carts_from_device: 'Mostra gli ordini aperti dal dispositivo',
  create_discounts_for_your_stores: 'Crea sconti per la tua posizione.',
  create_reps_waste_for_your_stores: 'Crea ripetizioni e rifiuti per la tua posizione.',
  show_cat_bar: 'Mostra la barra delle categorie',
  bulk_update: 'Articoli di aggiornamento collettivo',
  show_category_product_info: 'Mostra le informazioni sulla categoria nel prodotto',
  ready_items: 'Articoli pronti',
  disabled: 'Disabilitato',
  ready_orders: 'Ordini pronti',
  changes_list: 'Elenco modifiche',
  action_type: 'Tipo di azione',
  previous_change: 'Modifica precedente',
  new_changes: 'Nuovi cambiamenti',
  removed_fields: 'Campi rimossi',
  updated_fields: 'Campi aggiornati',
  full_history: 'Storia completa',
  changes_history: 'Cambia la cronologia',
  product_history: 'Storia del prodotto',
  category_history: 'Storia della categoria',
  device_history: 'Cronologia del dispositivo',
  enable_mandatory_customer_address: 'Indirizzo del cliente obbligatorio',
  please_fill_all_fields:
    'Si prega di compilare tutti i campi richiesti: numero edificio, via, città, stato/provincia, CAP o CAP, Paese, nome e cognome, indirizzo e-mail o numero di telefono',
  select_file: 'Seleziona il file',
  drop_file: 'Elimina file',
  create_media: 'Crea file multimediali per',
  upload_image: 'Carica immagine',
  upload_file: 'Caricare un file',
  add_custom_img: 'Aggiungi la tua immagine personalizzata',
  select_media: 'Seleziona il tipo di supporto',
  external_url_iframe: 'Iframe URL esterno',
  select_ticket_print: 'Seleziona il tipo da stampare',
  external_service_provider: 'Fornitore di servizi esterno',
  barcode_input: 'Inserimento codice a barre',
  stock_load_msg:
    'Controlla tutti i prodotti per lo stock. Questo potrebbe richiedere del tempo. Attendere prego!',
  no_available_stores: 'Nessun negozio disponibile.',
  buy_combination_get_deal_new_price: 'Combina gli articoli per un prezzo contrattuale',
  discount_specific_products_categories: 'Sconto su prodotti o categorie specifiche',
  discount_all_prodcucts: 'Sconto su tutti i prodotti',
  buy_x_items_get_cheapest_discount: 'Acquista x articoli, ottieni il più economico con uno sconto',
  spend_certain_amount_get_discount: 'Spendi una certa cifra, ottieni uno sconto',
  customer_group_item_discount: 'Sconto su specifici gruppi di clienti',
  item_surcharge: 'Supplemento su prodotti, categorie o gruppi di clienti specifici',
  cart_surcharge: 'Supplemento sull"intero ordine',
  import: 'Importare',
  export: 'Esportare',
  simple: 'Semplice',
  advanced: 'Avanzate',
  delete_import: 'Elimina importazione prodotti',
  upload_csv: 'Carica il tuo file CSV',
  open_file: 'Apri il file usando',
  save_file: 'Salva il file come .csv',
  value_remaining: 'Valore residuo:',
  shared_shift: 'Condividi il turno',
  shared_shift_desc: 'Il cambio di dipendente non richiederà la riapertura del registro',
  cant_merge_same: 'Impossibile unire la stessa tabella, seleziona un altra tabella da unire',
  id_check: 'alunno',
  id_check_alert: 'Cliente: {{customer}}. Si prega di controllare i clienti seguenti ID: {{id}}.',
  student: "Verifica dell'identità"
}

export default itLang
