import { Component } from 'react'
import { connect } from 'react-redux'

import { Button } from '@countr/ui'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Text, translate } from 'react-internationalization'
import { isMobileOnly, deviceDetect } from 'react-device-detect'

import ErrorUtils from '../../utils/ErrorUtils'
import { AppInstances } from '../../utils/countrSdkInstance'
import { RequestQueue } from '../../utils/RequestQueue'
import Util from '../../utils/Util'
import { returnUpdatableDevice } from '../../utils/DeviceUtils'
import DesktopUtils from '../../utils/DesktopUtils'
import { generateFingerprintUuid, detectAdBlock } from '../../utils/DeviceUtils'
import IntercomUtils from '../../utils/IntercomUtils'
import { handleItemWithoutCategoryName } from '../../utils/TransactionUtils'

import { addUser } from '../../store/actions/user'
import { setToastMessage, setTrialExpired, setTheme } from '../../store/actions/app'
import { addDevice } from '../../store/actions/devices'
import { loadingTrue, loadingFalse, setLoadingMsg } from '../../store/actions/loading'
import { addTransactionHead } from '../../store/actions/transactions'
import { setSettings } from '../../store/actions/settings'
import {
  setPayments,
  loadPaymentsMethods,
  loadPaymentsMethodsExtra
} from '../../store/actions/payments'

import ResourceLoader from '../../ResourceLoader'
import VerifcationModal from './VerificationModal'
import themes from './../../themes/default.json'

import './Login.scss'

const DEVICE_NOT_FOUND = 'DEVICE_NOT_FOUND'
const MERCHANT = 'Merchant'

const mapStateToProps = state => {
  return {
    user: state.user,
    devices: state.devices,
    settings: state.settings,
    payments: state.payments,
    transactions: state.transactions
  }
}

const parseQuery = queryString => {
  var query = {}
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

const mapDispatchToProps = dispatch => {
  return {
    loadingTrue: () => dispatch(loadingTrue()),
    loadingFalse: () => dispatch(loadingFalse()),
    setToastMessage: (msg, opt, action) => dispatch(setToastMessage(msg, opt, action)),
    setLoadingMsg: msg => dispatch(setLoadingMsg(msg)),
    addUser: user => dispatch(addUser(user)),
    addDevice: device => dispatch(addDevice(device)),
    setSettings: settings => dispatch(setSettings(settings)),
    setPayments: payments => dispatch(setPayments(payments)),
    loadPaymentsMethods: payments => dispatch(loadPaymentsMethods(payments)),
    loadPaymentsMethodsExtra: extras => dispatch(loadPaymentsMethodsExtra(extras)),
    addTransactionHead: transaction => dispatch(addTransactionHead(transaction)),
    setTrialExpired: () => dispatch(setTrialExpired()),
    setTheme: theme => dispatch(setTheme(theme))
  }
}

const appVersion = process.env.REACT_APP_VERSION

class Login extends Component {
  state = {
    user: {},
    username: '',
    password: '',
    showPassword: false,
    rememberLogin: false,
    showVerification: false,
    deltaCheckerInterval: false
  }

  // Resouce loader
  resource = null

  handleChangeRemeber = () => {
    const old = this.state.rememberLogin
    this.setState({ rememberLogin: !old })
    localStorage.setItem('CountrLite:RememberLogin', !old)
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  handleMouseDownPassword = event => {
    event.preventDefault()
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.login()
    }
  }

  isReady = async (username, password) => {
    const countr = await AppInstances.getCountrSdk()
    return new Promise((resolve, reject) => {
      countr.once('registered', token => {
        localStorage.setItem('token_type', token.token_type)
        localStorage.setItem('access_token', token.access_token)
        localStorage.setItem('refresh_token', token.refresh_token)
        countr.setToken({
          access_token: token.access_token,
          refresh_token: token.refresh_token
        })
      })

      countr.register(username, password).then(
        user => {
          localStorage.setItem('user', JSON.stringify(user))
          return resolve(user)
        },
        error => {
          return reject(error)
        }
      )
    })
  }

  initSettingsMobile = () => {
    if (isMobileOnly) {
      const settings = JSON.parse(JSON.stringify(this.props.settings))
      settings.hideCategoriesTile = true
      settings.cartAlwaysOpen = false
      settings.showCalculator = false
      this.props.setSettings(settings)
    }
  }

  checkAllSettings = web_settings => {
    const reduxKeys = Object.keys(this.props.settings)
    const webKeys = Object.keys(web_settings)

    const hasInWeb = webKeys.filter(i => reduxKeys.indexOf(i) < 0)
    const hasInRedux = reduxKeys.filter(i => webKeys.indexOf(i) < 0)

    if (hasInRedux.length) {
      hasInRedux.forEach(d => {
        web_settings[d] = this.props.settings[d]
      })
    }

    if (hasInWeb.length) {
      hasInWeb.forEach(d => {
        delete web_settings[d]
      })
    }

    return web_settings
  }

  checkIfVerified = user => {
    return new Promise((resolve, reject) => {
      if (user.verification && user.verification.code) {
        if (user.verification.validated) {
          resolve(user)
        } else {
          reject(user)
        }
      } else {
        // old user, doesn't have verification obj inside user, just login normally
        resolve(user)
      }
    })
  }

  openVerificationDialog = user => {
    this.props.setLoadingMsg('')
    this.props.loadingFalse()
    this.setState({ showVerification: true, user: user })
  }

  closeVerificationDialog = async result => {
    this.setState({ showVerification: false })

    if (result) {
      this.props.loadingTrue()
      const countr = await AppInstances.getCountrSdk()
      countr.me
        .update({ 'verification.validated': true })
        .then(result => {
          console.log('### Verified and patched merchant', result)
        })
        .catch(error => {
          console.log('### Merchant Patch Error: ' + JSON.stringify(error))
        })
      this.loadResources(this.state.user)
    }
  }

  login = () => {
    this.props.loadingTrue()
    this.props.setLoadingMsg(translate('signing_in'))

    const { username, password } = this.state

    this.isReady(username, password).then(
      user => {
        if (user.__t !== MERCHANT) {
          localStorage.clear()
          this.props.loadingFalse()
          this.props.setToastMessage('access_denied')
          return
        }

        this.props.setLoadingMsg(`${user.email} logged!`)
        this.verifyTrialExpired(user)
        this.checkIfVerified(user).then(
          async user => {
            const hasStore = await this.checkMerchantStore()
            if (hasStore) {
              this.loadResources(user)
            } else {
              this.showNoStoreError()
            }
          },
          user => {
            // Show verification dialog
            this.openVerificationDialog(user)
          }
        )
      },
      error => {
        console.log(error)
        this.props.loadingFalse()
        this.props.setToastMessage('login_error')
      }
    )
  }

  async autoLogin() {
    const query_params = parseQuery(this.props.location.search)
    if (query_params.accessToken) {
      localStorage.setItem('access_token', query_params.accessToken)
      localStorage.setItem('CountrLite:RememberLogin', true)
    }

    const access_token = localStorage.getItem('access_token')
    const refresh_token = localStorage.getItem('refresh_token')

    const countr = await AppInstances.getCountrSdk()
    countr.setToken({
      access_token: access_token,
      refresh_token: refresh_token
    })

    this.props.loadingTrue()
    this.props.setLoadingMsg(translate('signing_in'))

    countr.me.read().then(
      user => {
        if (user.__t !== MERCHANT) {
          localStorage.clear()
          this.props.loadingFalse()
          this.props.setToastMessage('access_denied')
          return
        }
        this.props.setLoadingMsg(`${user.email} logged!`)

        this.verifyTrialExpired(user)
        this.checkIfVerified(user).then(
          async user => {
            const hasStore = await this.checkMerchantStore()
            if (hasStore) {
              this.loadResources(user)
            } else {
              this.showNoStoreError()
            }
          },
          user => {
            // Show verification dialog
            this.openVerificationDialog(user)
          }
        )
      },
      error => {
        console.log(error)
        this.props.loadingFalse()
        this.props.setToastMessage('login_error')
      }
    )
  }

  verifyTrialExpired = user => {
    if (user.trial_expires_at !== null) {
      const expireDate = new Date(user.trial_expires_at)

      if (expireDate < new Date()) {
        this.props.setTrialExpired()
        this.props.setToastMessage('trial_expired')
      }
    }
  }

  getDeviceFromUUID = (countr, uuid) => {
    return countr.devices.readOne.detailed(uuid).then(
      device => {
        if (device && Object.keys(device).length) {
          return device
        }
      },
      error => {
        return DEVICE_NOT_FOUND
      }
    )
  }

  loadTheme = ({ settings, store }) => {
    if (!!store?.extras) {
      const { web_settings } = settings
      const { themes, currentTheme } = store.extras

      if (!!themes) {
        const allThemes = Object.keys(themes.data).map(key => ({
          ...themes.data[key]
        }))

        if (!!web_settings && !!web_settings.theme && !!web_settings.theme.length) {
          this.props.setTheme(allThemes.find(theme => theme._id === web_settings.theme))
        } else if (!!currentTheme && !!currentTheme.length) {
          this.props.setTheme(allThemes.find(theme => theme._id === currentTheme))
        }
      }
    }
  }

  loadResources = async user => {
    this.props.addUser(user)
    
    const deviceFingerPrintId = await generateFingerprintUuid()
    
    if (!deviceFingerPrintId) {
      const msg = `Failed trying to generateFingerprintUuid on Login Load Resources - ${user.username || ''}`
      ErrorUtils.logError({
        msg,
        stack: JSON.stringify({})
      })

      throw Error(msg)
    }

    DesktopUtils.setIsDesktop(deviceFingerPrintId)

    // Checking pending queue list
    const queue = JSON.parse(localStorage.getItem('CountrWeb:QueueList'))
    if (queue && queue.length) {
      RequestQueue.dequeueActions(queue)
      localStorage.removeItem('CountrWeb:QueueList')
    }

    IntercomUtils.trackEvent('home:signin')

    const findDeviceServer = Util.getDeviceUuid(deviceFingerPrintId)
    const countr = await AppInstances.getCountrSdk()
    let device = await this.getDeviceFromUUID(countr, findDeviceServer)

    if (device !== DEVICE_NOT_FOUND) {
      //TODO Temporary workaround for fingerprints
      // If !deviceUuidUpdated and findDeviceServer does not equal the deviceFingerPrintId then update the local to the deviceFingerPrintId,
      // patch the server, set deviceUuidUpdated
      const deviceUuidUpdated = localStorage.getItem('deviceUuidUpdated')
      if (!deviceUuidUpdated && findDeviceServer != deviceFingerPrintId) {
        await countr.devices
          .update(device._id, {
            uuid: deviceFingerPrintId
          })
          .then(res => {})
          .catch(err => console.log(err))
        localStorage.setItem('StoredDeviceUuid', deviceFingerPrintId)
        localStorage.setItem('deviceUuidUpdated', true)
        device = await this.getDeviceFromUUID(countr, deviceFingerPrintId)
      } else {
        localStorage.setItem('deviceUuidUpdated', true)
      }

      this.loadTheme(device)
      this.resource = await new ResourceLoader(user, device)

      const lastNumber = localStorage.getItem('CountrLite:LastTransaction')
      await countr.devices.readOne.lastTransaction(device._id).then(last => {
        if (parseInt(last) >= (lastNumber || 0) || isNaN(lastNumber)) {
          localStorage.setItem('CountrLite:LastTransaction', last)
        } else {
          localStorage.setItem('CountrLite:LastTransaction', lastNumber || 0)
        }
      })

      this.resource.loadAllResources(device).then(
        success => {
          localStorage.setItem('CountrLite:Device', JSON.stringify(device))

          this.props.setLoadingMsg('')

          // Updating device info
          const dev = device

          if (dev.settings && dev.settings.web_settings) {
            const web = this.checkAllSettings(dev.settings.web_settings)
            this.props.setSettings(web)
            const currLang = localStorage.getItem('CountrWeb:Language')
            // If language changed on web side, set localstorage and reload the page in the correct language
            if (
              dev.settings.web_settings.language &&
              dev.settings.web_settings.language != currLang
            ) {
              localStorage.setItem('CountrWeb:Language', dev.settings.web_settings.language)
              document.documentElement.lang = dev.settings.web_settings.language
              window.location.reload()
            }
          }

          if (dev.settings) {
            this.checkPaymentsMethods(dev.settings, dev.store.options)
            dev.settings.language = this.props.settings.language
          } else {
            this.checkPaymentsMethods('no_settings', dev.store.options)
          }

          this.initSettingsMobile()

          dev.info = {
            platform: navigator.platform,
            language: this.props.settings.language,
            version: 'webpos - ' + appVersion,
            ...deviceDetect()
          }

          countr.devices.update(dev._id, returnUpdatableDevice(dev))
          this.props.addDevice(dev)

          // Check if it has a pending transaction in local storage
          this.checkLastTransaction()

          const deltaCheckerFlag = localStorage.getItem('freshdelta')
          if (!deltaCheckerFlag || deltaCheckerFlag !== 'true') {
            this.props.loadingFalse()
            this.props.history.push({
              pathname: '/main'
            })
          } else if (deltaCheckerFlag === 'true') {
            const self = this
            this.setState({
              deltaCheckerInterval: setInterval(() => {
                if (localStorage.getItem('freshdelta') === 'false') {
                  this.props.loadingFalse()
                  this.props.history.push({
                    pathname: '/main'
                  })
                }
              }, 1000)
            })
          }
        },
        error => {
          this.showError(error, device)
          this.props.loadingFalse()
        }
      )
    } else {
      this.props.loadingFalse()
      this.props.history.push({
        pathname: '/registration',
        state: {
          fingerPrintId: deviceFingerPrintId,
          userId: user._id,
          deviceInfo: { name: '', store: { _id: '' } }
        }
      })
    }
  }

  showError = (error, device) => {
    if (!!error && !!error.length) {
      const errorMsg = `[${Util.returnPOSType()}] Login - ResourceLoader error`
      const stack = error.map(e => e.error || JSON.stringify(e)).join(' - ')
      this.props.setToastMessage(`Login fail: ${stack}`)
      this.logError(errorMsg, stack, error, device)
    }
  }

  logError = (errorMsg, stack, info = {}, device = null) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const currentDevice = !!device ? device : JSON.parse(localStorage.getItem('CountrLite:Device'))

    if (!!user && !!currentDevice) {
      const errorMessage = `[${Util.returnPOSType()}] ${user.email} - ${user._id} - ${errorMsg}`
      const errorObj = {
        source: Util.returnPOSType(),
        message: errorMessage,
        user: user,
        store: currentDevice.store._id,
        device: currentDevice._id,
        date: new Date(),
        stack,
        info
      }

      AppInstances.logError(errorObj)
    }
  }

  checkPaymentsMethods = (settings, storeOptions) => {
    const paymentMethods = Object.assign([], this.props.payments.paymentMethods)

    if (settings !== 'no_settings') {
      if (settings.web_payments) {
        settings.web_payments.forEach((method, index) => {
          if (paymentMethods[index] && method.method === paymentMethods[index].method) {
            paymentMethods[index].enabled = method.enabled
            paymentMethods[index].extra = method.extra
            paymentMethods[index].cashDrawer = method.cashDrawer
            paymentMethods[index].printReceipt = method.printReceipt
          }
        })
      }
    }

    this.props.loadPaymentsMethods(paymentMethods)

    if (
      storeOptions &&
      storeOptions.extraPaymentMethods &&
      storeOptions.extraPaymentMethods.length
    ) {
      this.props.loadPaymentsMethodsExtra(storeOptions.extraPaymentMethods)
    }
  }

  checkLastTransaction = async () => {
    const countr = await AppInstances.getCountrSdk()
    const recoveryTransaction = []
    const transactionToBeSyncBkp = []
    const successTransactions = []
    const failTransactions = []

    for (const key in localStorage) {
      if (key.indexOf('CountrWeb:LastTransactionInProgress-') >= 0) {
        recoveryTransaction.push(key)
      }
    }

    recoveryTransaction.forEach(transactionKey => {
      const lastTransaction = JSON.parse(localStorage.getItem(transactionKey))

      if (!!lastTransaction) {
        transactionToBeSyncBkp.push(lastTransaction)
        // Handle item.product with category without name property
        countr.transactions
          .create(handleItemWithoutCategoryName(lastTransaction))
          .then(transaction => {
            successTransactions.push(transaction)
            this.props.addTransactionHead(transaction)
            localStorage.removeItem(transactionKey)
          })
          .catch(error => {
            // If something wrong with this transaction and it connot be synced
            // log transaction body
            failTransactions.push(lastTransaction)
            this.logError(
              `Syncing transaction in progress receipt id ${lastTransaction.receipt_id} fail`,
              JSON.stringify(error),
              { transaction: lastTransaction }
            )
          })
      }
    })

    // Loging all transactions in progress to error api
    if (transactionToBeSyncBkp.length) {
      const receipt_ids = transactionToBeSyncBkp.map(t => t.receipt_id).join(', ')
      this.logError(
        `Syncing ${transactionToBeSyncBkp.length} transactions in progress (${receipt_ids})`,
        `receipt ids: ${receipt_ids}`,
        { success: successTransactions, fail: failTransactions }
      )
    }
  }

  checkMerchantStore = async () => {
    const countr = await AppInstances.getCountrSdk()
    const stores = await countr.stores.read()

    return stores && stores.length
  }

  showNoStoreError = () => {
    this.props.loadingFalse()
    this.props.setToastMessage('need_store_register_device', {}, 'go_dashboard')
  }

  componentWillUnmount() {
    clearInterval(this.state.deltaCheckerInterval)
  }

  async componentDidMount() {
    const remember = JSON.parse(localStorage.getItem('CountrLite:RememberLogin'))
    const deviceUuidUpdated = localStorage.getItem('deviceUuidUpdated')
    const hasAdBlock = deviceUuidUpdated ? false : await detectAdBlock()

    if (remember !== null && remember && !hasAdBlock) {
      this.setState({ rememberLogin: remember })
      this.autoLogin()
    } else {
      this.setState({ rememberLogin: true })
      localStorage.setItem('CountrLite:RememberLogin', JSON.stringify(true))
      if (hasAdBlock) {
        setTimeout(() => {
          this.props.setToastMessage('disable_adblocker')
        }, 800)
      }
    }

    Util.getNotificationPermission()
  }

  render() {
    return (
      <div>
        <Grid container className="login">
          <Grid item xs={12}>
            <TextField
              id="username"
              label={<Text id="username" />}
              type="email"
              className="login-input-field login-input-field-label"
              value={this.state.username}
              onChange={this.handleChange('username')}
              onKeyPress={this.handleKeyPress}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="password"
              label={<Text id="password" />}
              type={this.state.showPassword ? 'text' : 'password'}
              className="login-input-field login-input-field-label"
              value={this.state.password}
              onChange={this.handleChange('password')}
              onKeyPress={this.handleKeyPress}
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}>
                      {this.state.showPassword ? (
                        <span className="icon-not-visible" />
                      ) : (
                        <span className="icon-visible" />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              className="remember-password"
              control={
                <Checkbox
                  checked={this.state.rememberLogin}
                  onChange={this.handleChangeRemeber}
                  style={{ color: themes.data.countrLight.colors.buttonPrimary.background }}
                />
              }
              label={translate('remember_password')}
            />
          </Grid>
          <Grid item xs={12} style={{ width: '100%' }}>
            <Button
              testid="login-btn"
              id="login-btn"
              label={<Text id="sign_in" />}
              onClick={this.login}
              styles={{
                width: '100%'
              }}
            />
          </Grid>
        </Grid>
        {this.state.showVerification && (
          <VerifcationModal
            user={this.state.user}
            open={this.state.showVerification}
            handleClose={this.closeVerificationDialog}
            setToastMessage={this.props.setToastMessage}
          />
        )}
      </div>
    )
  }
}

const LoginConnected = connect(mapStateToProps, mapDispatchToProps)(Login)
export default LoginConnected
