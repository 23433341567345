import { Component } from 'react';
import { AppInstances } from '../utils/countrSdkInstance'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null
    }
  }

  logError = async obj => {
    const errorObj = {
      source: process.env.REACT_APP_ERROR_SOURCE,
      message: `${obj.msg}, users: ${this.props.user.username}, _ID: ${this.props.user._id}, device id: ${this.props.device._id}`,
      user: this.props.user._id,
      store: this.props.device.store._id,
      device: this.props.device._id,
      stack: JSON.stringify(obj.stack),
      date: new Date().toISOString(),
      info: obj.info
    }

    await AppInstances.getCountrSdk()
    AppInstances.logError(errorObj)
  }

  componentDidCatch(e, errorInfo) {
    this.logError({
      msg: `General Bounday Error`,
      stack: errorInfo.componentStack,
      info: {
        error: e,
        errorInfo: errorInfo
      }
    })

    if (e.toString().indexOf('WebSocket') < 0) {
      // Catch errors in any child components and re-renders with an error message
      this.setState({
        error: e,
        errorInfo: errorInfo
      })
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <h2>{'Oh-no! Something went wrong'}</h2>
          <p>{this.state.error && this.state.error.toString()}</p>
          <div>{'Component Stack Error Details: '}</div>
          <p>{this.state.errorInfo.componentStack}</p>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
