import { useState, useEffect } from 'react';
import * as React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import TextField from '@material-ui/core/TextField'

import Radio from '@material-ui/core/Radio'

import { Text } from 'react-internationalization'

import PrinterDetails from './PrinterDetails'
import { AppInstances } from './../../utils/countrSdkInstance'
import { setToastMessage } from '../../store/actions/app'
import { PrinterUtils } from '../../utils/PrinterUtils'
import { returnUpdatableDevice } from './../../utils/DeviceUtils'
import DesktopUtils from '../../utils/DesktopUtils'

const { compact } = require('lodash')

import './PrinterSettings.css'

function PrinterOrientation({ osPrinter, index, desktop }) {
  const desktopCopy = { ...desktop }
  const printer = osPrinter

  const [orientation, setOrientation] = useState('')
  const [scale, setScale] = useState('')
  const [paper_width, setWidth] = useState('')
  const [paper_length, setLength] = useState('')

  useEffect(() => {
    setOrientation(printer.orientation)
    setScale(printer.scale)
  }, [])

  const orientationChanged = async evt => {
    setOrientation(evt.target.value)

    printer.orientation = evt.target.value

    // saveDesktop()
  }

  const widthChanged = async evt => {
    setWidth(evt.target.value)

    printer.paper_width = evt.target.value

    // saveDesktop()
  }

  const lengthChanged = async evt => {
    setLength(evt.target.value)

    printer.paper_length = evt.target.value

    // saveDesktop()
  }

  const scaleChanged = async evt => {
    setScale(evt.target.value)

    printer.scale = evt.target.value

    // saveDesktop()
  }

  // const saveDesktop = async () => {
  //   console.log('Saving os printers')
  //   desktopCopy.settings.osprinters_saved[index] = printer

  //   desktopCopy.settings.osprinters_saved = compact(desktopCopy.settings.osprinters_saved)

  //   console.log('Printers at save', desktopCopy.settings.osprinters_saved)

  //   localStorage.setItem('CountrLite:LocalDesktop', JSON.stringify(desktopCopy.settings))

  //   const countr = await AppInstances.getCountrSdk()
  //   countr.devices.update(desktopCopy._id, returnUpdatableDevice(desktopCopy))
  // }

  return (
    <div className="extra-osprintersettings">
      <RadioGroup
        row
        value={scale}
        aria-label="scale"
        name="scale"
        onChange={evt => scaleChanged(evt)}
        defaultValue="noscale">
        shrink
        <Radio value="shrink" checked={scale === 'shrink'} />
        fit
        <Radio value="fit" checked={scale === 'fit'} />
        noscale
        <Radio value="noscale" checked={scale === 'noscale'} />
      </RadioGroup>

      <RadioGroup
        row
        value={orientation}
        aria-label="orientation"
        name="orientation"
        onChange={evt => orientationChanged(evt)}
        defaultValue="portrait">
        Portrait
        <Radio
          value="portrait"
          checked={orientation === 'portrait'}
          onChange={evt => orientationChanged(evt)}
        />
        Landscape
        <Radio
          value="landscape"
          checked={orientation === 'landscape'}
          onChange={evt => orientationChanged(evt)}
        />
      </RadioGroup>
      {navigator.platform !== 'Win32' && (
        <>
          <TextField
            value={paper_width}
            aria-label="paper_width"
            name="paper_width"
            onChange={evt => widthChanged(evt)}
            placeholder="Width (e.g. 2.125in)"
          />
          x
          <TextField
            value={paper_length}
            aria-label="paper_length"
            name="paper_length"
            onChange={evt => lengthChanged(evt)}
            placeholder="Length (e.g. 3.25in)"
          />
        </>
      )}
    </div>
  )
}

function PrinterCheckbox({ osPrinter, type, index, desktop }) {
  const [checked, setChecked] = useState(false)
  const [printer, setPrinter] = useState(osPrinter)

  const desktopCopy = { ...desktop }

  useEffect(() => {
    setChecked(osPrinter.types[type])
  }, [])

  const onChanged = async () => {
    if (!printer || !Object.keys(printer).length) {
      return
    }

    setChecked(!checked)
    printer.types[type] = !checked

    if (desktopCopy.settings && !desktopCopy.settings.osprinters_saved) {
      desktopCopy.settings.osprinters_saved = []
    }

    // if (!desktopCopy.settings.osprinters_saved[index]) {
    //   desktopCopy.settings.osprinters_saved[index] = {}
    // }

    // desktopCopy.settings.osprinters_saved[index] = printer

    // localStorage.setItem('CountrLite:LocalDesktop', JSON.stringify(desktopCopy.settings))

    // desktopCopy.settings.osprinters_saved = desktopCopy.settings.osprinters_saved.filter(
    //   printer => !!printer
    // )

    // const countr = await AppInstances.getCountrSdk()
    // countr.devices.update(desktopCopy._id, returnUpdatableDevice(desktopCopy))
  }

  return (
    <>
      <Checkbox
        key={`${osPrinter.name}-${index}-${type}`}
        name={type}
        checked={checked}
        onChange={onChanged}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    store: state.store
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setToastMessage: (msg, opt) => dispatch(setToastMessage(msg, opt))
  }
}

/**
 */
export const PrinterSettings = React.memo(props => {
  const [printers, setPrinters] = useState([])
  const [osSavedPrinters, setOsSavedPrinters] = useState([])
  const [desktop, setDesktop] = useState({})

  const [orientation, setOrientationValue] = React.useState('landscape')

  const handleChangeOrientation = event => {
    setOrientationValue(event.target.value)
  }

  useEffect(() => {
    const localDesktop = JSON.parse(localStorage.getItem('CountrLite:LocalDesktop'))

    if (localDesktop && localDesktop !== null) {
      getPrintersDetails(localDesktop)
      getDesktopDetails(localDesktop)
    }
  }, [])

  const getPrintersDetails = localDesktop => {
    if (localDesktop.printers_saved && localDesktop.printers_saved.length) {
      localDesktop.printers_saved.forEach(printer => {
        if (!printer.categories) {
          printer.categories = []
        }
      })

      setPrinters(localDesktop.printers_saved)
    }

    if (localDesktop.osprinters_saved && localDesktop.osprinters_saved.length) {
      setOsSavedPrinters(localDesktop.osprinters_saved)
    }
  }

  const resyncOsPrinter = () => {
    const localDesktop = JSON.parse(localStorage.getItem('CountrLite:LocalDesktop'))
    if (localDesktop && localDesktop.local_ip) {
      const web = AppInstances.getDesktopConnection(
        `${process.env.REACT_APP_WS_TYPE}://${
          localDesktop.local_ip
        }:${DesktopUtils.getDesktopListenerPort()}`
      )

      if (web && web.readyState === 1) {
        const data = JSON.stringify({
          type: 'getPrintersSaved'
        })

        web.send(data)
        web.onmessage = message => {
          try {
            const result = JSON.parse(message.data)

            const mergedOsPrinters = result.data.map(savePrinter => {
              const exist =
                // localDesktop?.osprinters_saved?.length &&
                localDesktop?.osprinters_saved?.find(osprint => osprint.name === savePrinter.name) 
              return exist ?? savePrinter
            })

            setOsSavedPrinters(compact(mergedOsPrinters))
          } catch (error) {
            console.log(error)
          }
        }
      }
    }
  }

  const getDesktopDetails = async localDesktop => {
    if (localDesktop.local_ip) {
      const countr = await AppInstances.getCountrSdk()
      countr.devices
        .readOne('search/type', { text: 'desktop', sort: '-updated_at' })
        .then(devices => {
          const desk = devices.find(
            dev => dev.settings && dev.settings && ((dev.settings.local_ip !== 'localhost' && dev.settings.local_ip === localDesktop.local_ip) || (dev.settings.network_ip === localDesktop.network_ip))
          )

          if (desk) {
            setDesktop(desk)
          }
        })
    }
  }

  const handleChangePrinter = (field, value, index) => {
    const printersCopy = JSON.parse(JSON.stringify(printers))
    printersCopy[index][field] = value

    if (field === 'categories') {
      printersCopy[index].printSpecificCategories = printersCopy[index].categories.length > 0
    }

    setPrinters(printersCopy)
  }

  const removePrinter = index => {
    const printersCopy = JSON.parse(JSON.stringify(printers))
    printersCopy.splice(index, 1)
    setPrinters(printersCopy)
  }

  const addPrinter = () => {
    const printer = {
      categories: [],
      custom_name: 'printer',
      device: 'ip',
      displayAttached: false,
      name: 'printer ' + (printers.length + 1),
      printSpecificCategories: false,
      roles: ['order', 'receipt', 'report'],
      type: 'printer'
    }
    const printersCopy = JSON.parse(JSON.stringify(printers))
    printersCopy.push(printer)
    setPrinters(printersCopy)
  }

  const saveDesktop = async () => {
    const desktopCopy = { ...desktop }
    desktopCopy.settings.printers_saved = printers
    desktopCopy.settings.osprinters_saved = osSavedPrinters
    localStorage.setItem('CountrLite:LocalDesktop', JSON.stringify(desktopCopy.settings))
    const countr = await AppInstances.getCountrSdk()
    countr.devices.update(desktopCopy._id, returnUpdatableDevice(desktopCopy)).then(updated => {})
    props.setToastMessage('printers_settings_saved')
  }

  const testPrinters = () => {
    PrinterUtils.sendToPrinter('print-test', null, { setToastMessage: props.setToastMessage })
  }

  return (
    <Dialog open={props.openPrinterSettings} onClose={props.handleClosePrinterSettings}>
      <DialogTitle>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={11}>
            <Text id="printer_settings" />
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={addPrinter}>
              <span className="icon-plus" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        {!!printers?.length && (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              Current Local IP:{' '}
              {desktop && desktop.settings && (desktop.settings.local_ip || desktop.settings.network_ip) ? (
                <strong>{desktop.settings.network_ip || desktop.settings.local_ip}</strong>
              ) : (
                <CircularProgress color="primary" style={{ width: 18, height: 18 }} />
              )}
            </Grid>
            {printers.map((printer, index) => (
              <Grid item xs={12} key={`${printer.name}-${printer.device}`} className="printer-item">
                <Card>
                  <CardContent>
                    <PrinterDetails
                      printer={printer}
                      index={index}
                      handleChangePrinter={handleChangePrinter}
                      removePrinter={removePrinter}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        {!!osSavedPrinters?.length && <p>OS Printers</p>}
        {!!osSavedPrinters?.length && (
          <>
            <Grid item xs={12} className="printer-item">
              {osSavedPrinters.map((osPrinter, i) => {
                return (
                  <CardContent key={`${osPrinter.name}-${i}-${Math.random()}`}>
                    <p>
                      {osPrinter.isDefault ? <Text id="standard" /> : ''} {osPrinter.displayName}
                    </p>
                    {['ticket', 'card', 'other'].map((type, j) => {
                      return (
                        <React.Fragment key={`-${Math.random()}`}>
                          {type}
                          <PrinterCheckbox
                            desktop={desktop}
                            osPrinter={osPrinter}
                            type={type}
                            index={i}
                          />
                        </React.Fragment>
                      )
                    })}

                    <div className="orientation-osprinter">
                      <PrinterOrientation desktop={desktop} osPrinter={osPrinter} index={i} />
                    </div>

                    <hr />
                  </CardContent>
                )
              })}
            </Grid>
          </>
        )}
      </DialogContent>

      <DialogActions>
        {!!DesktopUtils.isExternalPrintersAvailable() && (
          <Button
            variant="contained"
            color="primary"
            className="button-primary-color"
            onClick={resyncOsPrinter}>
            <Text id="search_printer" />
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          className="button-primary-color"
          onClick={testPrinters}>
          <Text id="test_printers" />
        </Button>
        <Button variant="contained" color="secondary" onClick={props.handleClosePrinterSettings}>
          <Text id="close" />
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="button-primary-color"
          onClick={saveDesktop}>
          <Text id="save" />
        </Button>
      </DialogActions>
    </Dialog>
  )
})

PrinterSettings.propTypes = {
  openPrinterSettings: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(PrinterSettings)
