import DesktopUtils from './DesktopUtils'
export default class Util {
  static NOTIFICATION = 'Notification'
  static DEFAULT = 'default'
  static GRANTED = 'granted'

  static getDeviceUuid(result) {
    const storedDeviceUuid = localStorage.getItem('StoredDeviceUuid')

    if (storedDeviceUuid && storedDeviceUuid !== '' && storedDeviceUuid !== 'undefined') {
      result = storedDeviceUuid
    } else {
      localStorage.setItem('StoredDeviceUuid', result)
    }

    return result
  }

  static getProp(obj = {}, props = []) {
    return props[0] && obj[props[0]] ? this.getProp(obj[props[0]], props.slice(1)) : obj
  }

  static printField(obj, field) {
    return !!obj && !!obj[field] ? obj[field] : ''
  }

  static sortAlphabeticalByField(obj, fieldName, language = 'en') {
    return obj.sort((a, b) => {
      const aValue =
        !!a.translations && !!a.translations.names && !!a.translations.names[language]
          ? a.translations.names[language]
          : a[fieldName]
      const bValue =
        !!b.translations && !!b.translations.names && !!b.translations.names[language]
          ? b.translations.names[language]
          : b[fieldName]

      if (aValue) {
        return aValue.localeCompare(bValue)
      } else {
        return false
      }
    })
  }

  static sortByPositionName(a, b) {
    if (a.position < b.position) {
      return -1
    } else if (a.position > b.position) {
      return 1
    } else {
      if (a.name < b.name) {
        return -1
      } else if (a.name > b.name) {
        return 1
      }
    }

    return 0
  }

  static isCountrDesktop() {
    console.log('isCountrDesktop')
    const isDesktop = localStorage.getItem('isDesktop')
    return isDesktop !== null && isDesktop === 'true'
  }

  static checkIntegration(type) {}

  static returnPOSType() {
    return DesktopUtils.isDesktop() ? 'desktop' : 'webpos'
  }

  static returnIntercomEventType() {
    return this.isCountrDesktop() ? 'pos_desktop' : 'pos_web'
  }

  static isInternetOnline() {
    return navigator.onLine
  }

  static formatPrice(price) {
    if (isNaN(price)) {
      return 0
    }

    return parseFloat(parseFloat(price).toFixed(2))
  }

  static formattedDate(d) {
    return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
      .map(n => (n < 10 ? `0${n}` : `${n}`))
      .join('/')
  }

  static getInitWeek(date) {
    const day = date.getDay(),
      diff = date.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
    return this.formattedDate(new Date(date.setDate(diff)))
  }

  static getInitMonth(date) {
    const month =
      date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1).toString()
    return `01/${month}/${date.getFullYear()}`
  }

  static hasNotification = () => {
    return this.NOTIFICATION in window
  }

  static getNotificationPermission = () => {
    if (this.hasNotification() && Notification.permission === this.DEFAULT) {
      Notification.requestPermission()
      return
    }
  }

  static showNotification = body => {
    if (this.hasNotification() && Notification.permission === this.GRANTED) {
      new Notification(process.env.REACT_APP_NAME, {
        body,
        icon: 'https://countrhq.com/wp-content/uploads/2016/03/color_tag_H.png'
      })
    }
  }

  static playSound(shouldPlay) {
    if (shouldPlay) {
      new Audio(require('./../assets/sounds/definite.mp3')).play()
    }
  }

  static validateIPAddress(ip) {
    return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ip
    )
  }

  static isBgColorlightOrDark(color) {
    let r = null
    let g = null
    let b = null

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

      r = color[1]
      g = color[2]
      b = color[3]
    } else {
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

      r = color >> 16
      g = (color >> 8) & 255
      b = color & 255
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

    // Using the HSP value, determine whether the color is light or dark
    // If bgColor light, font color is back, otherwise it is white
    if (hsp > 127.5) {
      // return 'light'
      return '#000000'
    } else {
      // return 'dark'
      return '#FFFFFF'
    }
  }

  static handleModalDisableBackdrop(reason, callback) {
    if (reason !== 'backdropClick') {
      return callback()
    }
  }

  static arrayOverlap(arr, arr2) {
    for (var i in arr2) {
      if (arr.indexOf(arr2[i]._id || arr2[i]) > -1) {
        return true;
      }
    }
    return false;
  }
}
