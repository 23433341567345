export const handleItemWithoutCategoryName = transaction => {
  transaction.items.forEach(item => {
    if (Array.isArray(item.product.categories)) {
      item.product.categories = item.product.categories.map(category => ({
        ...category,
        name: !!category.name ?? 'Uncategorized'
      }))
    } else {

    }
  })

  return transaction
}
