import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Text } from 'react-internationalization'
import { cartUtils } from '../../utils/cartUtils'
import CartDiscount from './Optional/CartDiscount'
import './CartSubTotal.scss'

const CartSubTotal = ({ cart, settings }) => {
  const [openDiscount, setOpenDiscount] = useState(false)
  const currentCart = useSelector(state => state.carts.selectedCart)

  const fixedDecimalNumber = value => {
    return parseFloat(value).toFixed(2)
  }

  const calculateCartDiscountPercent = cart => {
    if (cart.discount && cart.discount > 0) {
      return `(${cartUtils.calculateCartDiscountPercent(cart)})`
    }
  }

  const openDiscountModal = () => {
    if (settings.cartLevelDiscount && !settings.kioskMode) {
      setOpenDiscount(true)
    }
  }

  return (
    <>
      <div
        className="cart-sub-total"
        onClick={openDiscountModal}
        style={{ cursor: settings.cartLevelDiscount ? 'pointer' : 'default' }}>
        <div className="icon">
          <span className="icon-billing-info-sidebar" />
        </div>
        <div className="details">
          <div className="item-line">
            <span className="title">
              <Text id="discount" />
            </span>
            <span className="value">
              {calculateCartDiscountPercent(cart)}
              {cart.currency.symbol} {fixedDecimalNumber(cartUtils.calculateCartDiscount(cart))}
            </span>
          </div>
          <div className="item-line">
            <span className="title">
              <Text id="subtotal" />
            </span>
            <span className="value">
              {cart.currency.symbol}{' '}
              {Object.keys(cart).length ? fixedDecimalNumber(cart.sub_total) : '0.00'}
            </span>
          </div>
          <div className="item-line">
            <span className="title">
              <Text id="taxes" />
            </span>
            <span className="value">
              {cart.currency.symbol}{' '}
              {Object.keys(cart).length ? fixedDecimalNumber(cart.total - cart.sub_total) : '0.00'}
            </span>
          </div>
        </div>
      </div>
      {openDiscount && (
        <CartDiscount
          openCartDiscount={openDiscount}
          handleCloseCloseDiscount={() => setOpenDiscount(false)}
          cart={cart}
        />
      )}
    </>
  )
}

export default CartSubTotal
