import { useEffect, useState } from 'react'
import { CartUtils } from '@countr/utils'
import { cartUtils as cartUtilities } from '../../utils/cartUtils'

import Grid from '@material-ui/core/Grid'

import CartItem from '../Lists/CartItem'
import { Text, translate } from 'react-internationalization'

import PayPerItemButtons from './PayPerItemButtons'

import './NewPaymentModal.scss'

const PartialPaymentItems = props => {
  const [toPay, setToPay] = useState(0)
  const [currentArray, setCurrentArray] = useState([])
  const cart = props.activeCart
  const device = props.device

  useEffect(() => {
    const current = []
    cart.items.forEach(item => {
      current.push({
        id: cartUtilities.getCartEntryId(item),
        current: 0
      })
    })
    setCurrentArray(current)
    setToPay(0)
    props.handleChangeValue('expectedAmount', 0)
  }, [cart.payments])

  const handleUpdateCurrent = (item, type) => {
    const arr = [...currentArray]
    const currentIndex = arr.findIndex(el => el.id === cartUtilities.getCartEntryId(item))

    if (type === 'add' && arr[currentIndex].current < item.amount - getPaidAmount(item)) {
      arr[currentIndex].current++
    } else if (type === 'remove' && arr[currentIndex].current > 0) {
      arr[currentIndex].current--
    } else {
      return
    }

    setCurrentArray(arr)
    updateToPayValue(arr)
  }

  const updateToPayValue = toPayListArray => {
    let sum = 0
    toPayListArray.forEach(current => {
      if (!!current.current) {
        const item = cart.items.find(i => cartUtilities.getCartEntryId(i) === current.id)
        const price = CartUtils.calculateCartEntryPrice({ ...item, amount: current.current })
        sum += price * (1 - cart.discount)
      }
    })

    setToPay(sum)
    props.handleChangeValue('expectedAmount', sum)

    const paidProducts = []
    toPayListArray.forEach(el => {
      if (el.current > 0) {
        const index = cart.items.findIndex(i => cartUtilities.getCartEntryId(i) === el.id)

        paidProducts.push({
          id: cartUtilities.getCartEntryId(cart.items[index]),
          amount: el.current
        })
      }
    })
    props.handlePaidProducts(paidProducts)
  }

  const getPaidAmount = item => {
    return cartUtilities.getCartEntryPaidAmount(cart, item)
  }

  const getCurrentValue = item => {
    const current = currentArray.find(el => el.id === cartUtilities.getCartEntryId(item))
    return !!current && !!current.current ? current.current : 0
  }

  const handleGroupSameItems = () => {
    try {
      const itemsArray = cart.items

      if (!cartUtilities.showSeats(cart, device)) {
        return itemsArray
      }

      const naturalCollator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base'
      })

      itemsArray.sort((a, b) => naturalCollator.compare(a?.product?.name, b?.product?.name))

      return itemsArray
    } catch (error) {
      props.handleError(error)
    }
  }

  return (
    <div>
      {handleGroupSameItems().map((item, index) => (
        <Grid
          key={cartUtilities.getCartEntryId(item)}
          container
          justifyContent="center"
          alignItems="center"
          className="payment-grid-type partial-payment-per-item-container">
          <Grid item xs={6}>
            <CartItem isModal cart={cart} item={item} index={index} hasDivider={false} />
          </Grid>
          <Grid item xs={4}>
            <PayPerItemButtons
              cart={cart}
              item={item}
              index={index}
              handleUpdateCurrent={handleUpdateCurrent}
              getPaidAmount={getPaidAmount}
              getCurrentValue={getCurrentValue}
            />
          </Grid>
          <Grid item xs={2}>
            <Grid container style={{ minHeight: 74 }} justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                {translate('paid') + ': '}
                <b>{getPaidAmount(item)}</b>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className="payment-grid-type">
        <Grid item xs={6} className="payment-grid-left">
          <b>
            <Text id="total" />
          </b>
        </Grid>
        <Grid item xs={6} className="payment-grid-right">
          <b>
            {cart.currency.symbol} {parseFloat(toPay).toFixed(2)}
          </b>
        </Grid>
      </Grid>
    </div>
  )
}

export default PartialPaymentItems
