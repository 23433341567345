import ErrorUtils from './ErrorUtils'
import { RequestQueue } from './RequestQueue'
import store from './../index'

export default class RegisterOperationsUtils {
  static applyOperation = async operationArgs => {
    try {
      const state = store.getState()

      const operationBody = {
        merchant: state.user.user,
        store: state.devices.store._id,
        device: state.devices.device._id,
        date: new Date().toISOString(),
        timezone: state.devices.store.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        action: operationArgs?.action,
        cash: operationArgs?.cash,
        tickets: operationArgs?.tickets,
        currency: state.devices.store.currency,
        note: operationArgs?.note,
        employee: state.employees?.selectedEmployee?._id,
        extras: operationArgs?.extras
      }

      RequestQueue.enqueueAction({
        type: 'registerOperations',
        action: 'create',
        payload: operationBody
      })
    } catch (error) {
      ErrorUtils.logError({
        msg: `Failed Applying Operation with RegisterOperationsUtils`,
        stack: JSON.stringify(error)
      })
    }
  }
}
