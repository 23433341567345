import { translate } from 'react-internationalization'
import { cartUtils } from './cartUtils'
import { CountrRequest } from './CountrRequest'
import WorkerResourceLoader from './../../src/WorkerResourceLoader'
import { AppInstances } from './countrSdkInstance'
import store from '../index'

const NOT_TRACKED = 'not_tracked'
const NONE = 'none'

export default class ProductUtils {
  static PRODUCT_ACTION_ORDER = {
    DEFAULT: 'DEFAULT',
    VARIANTS: 'VARIANTS',
    SOLD_BY_WEIGHT: 'SOLD_BY_WEIGHT',
    DYNAMIC: 'DYNAMIC',
    ADDONS: 'ADDONS',
    ADDON_GROUPS: 'ADDON_GROUPS',
    DEPOSIT: 'DEPOSIT',
    NON_REVENUE: 'NON_REVENUE',
    GIFTCARD: 'GIFTCARD',
    BARCODE: 'BARCODE'
  }

  static getProductActionOrder = (product, isDeposit = false) => {
    const order = []

    if (isDeposit && this.isDepositProduct(product)) {
      order.push(this.PRODUCT_ACTION_ORDER.DEPOSIT)
      return order
    }
    if (this.isVariantsProduct(product)) {
      order.push(this.PRODUCT_ACTION_ORDER.VARIANTS)
    }
    if (this.isGiftcard(product)) {
      order.push(this.PRODUCT_ACTION_ORDER.GIFTCARD)
    }
    if (this.isNonRevenueProduct(product)) {
      order.push(this.PRODUCT_ACTION_ORDER.NON_REVENUE)
    }
    if (this.isSoldByWeightProduct(product)) {
      order.push(this.PRODUCT_ACTION_ORDER.SOLD_BY_WEIGHT)
    }
    if (this.isDynamicPriceProduct(product)) {
      order.push(this.PRODUCT_ACTION_ORDER.DYNAMIC)
    }
    if (this.isAddonsProduct(product)) {
      order.push(this.PRODUCT_ACTION_ORDER.ADDONS)
    }
    if (this.needsBarcode(product)) {
      order.push(this.PRODUCT_ACTION_ORDER.BARCODE)
    }

    if (!order.length) {
      order.push(this.PRODUCT_ACTION_ORDER.DEFAULT)
    }

    return order
  }

  static getProductList = async (sortBy, category) => {
    const products =
      category === 'all_categories'
        ? await WorkerResourceLoader.getProducts(sortBy)
        : await WorkerResourceLoader.searchDbByFields(
            category,
            ['i_categories'],
            'products',
            sortBy === 'name'
          )

    return products
  }

  static getDepositProductList = async (sortBy, category) => {
    const products =
      category === 'all_categories'
        ? await WorkerResourceLoader.getAllProducts(sortBy)
        : await WorkerResourceLoader.searchDbByFields(
            category,
            ['i_categories'],
            'products',
            sortBy === 'name'
          )

    return products.filter(product => this.isDepositProduct(product))
  }

  static searchProducts = async (value, sortBy, language, deposit = false) => {
    let searched = []

    if (value.length > 2) {
      searched = await WorkerResourceLoader.searchDbByFields(
        value,
        ['name', 'ean'],
        'products',
        sortBy === 'name'
      )

      if (!searched.length) {
        searched = await WorkerResourceLoader.getAllProducts()
        searched = searched.filter(
          product =>
            this.getProductName(product, language).toLowerCase().indexOf(value.toLowerCase()) >= 0
        )
      } else {
        // Avoiding duplicate products (e.g. searched partial barcode that both variants has)
        const result = []
        const map = new Map()
        for (const item of searched) {
          if (!map.has(item._id)) {
            map.set(item._id, true)
            result.push({ ...item })
          }
        }

        searched = result
      }
    }

    return deposit ? searched.filter(p => this.isDepositProduct(p)) : searched
  }

  static getProductName = (product, lang = 'en') => {
    if (!!product.translations && !!product.translations.names) {
      return !!product.translations.names[lang] ? product.translations.names[lang] : product.name
    }

    return product.name
  }

  static getVariantName = (variant, lang = 'en') => {
    if (!!variant.translations && !!variant.translations.names) {
      return !!variant.translations.names[lang] ? variant.translations.names[lang] : variant.name
    }

    return variant.name
  }

  static getProductDescription = (product, lang = 'en') => {
    if (!!product.translations && !!product.translations.descriptions) {
      return !!product.translations.descriptions[lang]
        ? product.translations.descriptions[lang]
        : product.description
    }

    return !!product.description ? product.description : ''
  }

  static hasTranslatedDescription = (product, lang = 'en') => {
    return (
      !!product.translations &&
      !!product.translations.descriptions &&
      !!product.translations.descriptions[lang]
    )
  }

  static createCartEntry = (product, variant, addons, amount, storeId) => {
    const prod = {
      ...product,
      seat: store.getState().carts?.seatNo || 0,
      current_addons: addons,
      current_variant: {
        ...variant,
        price: this.getProductPrice(storeId, product, variant._id),
        cost_price: this.getProductPrice(storeId, product, variant._id, 'cost_price')
      }
    }

    const cartEntry = {
      product: prod,
      amount: amount,
      note: '',
      discount_note: '',
      status: this.createCartEntryStatus(amount)
    }

    return cartEntry
  }

  static createDepositProduct = (product, currentCart) => {
    const deposit = this.createCustomProductObj(product.name + ' - ' + translate('deposit'))
    deposit.variants[0].tax = product.options.deposit.tax
    deposit.variants[0].price = product.options.deposit.price * -1
    deposit.categories = product.categories[0]

    const index = currentCart.items.findIndex(item => item.product.name === deposit.name)

    if (index >= 0) {
      deposit._id = currentCart.items[index].product._id
      deposit.variants[0]._id = currentCart.items[index].product.current_variant._id
    }

    return deposit
  }

  static calculateItemAmount = (calculator, soldByWeightQuantity) => {
    return soldByWeightQuantity !== null ? soldByWeightQuantity : calculator > 0 ? calculator : 1
  }

  static createCustomProductObj = name => {
    const custom = {}
    custom._id = cartUtils.ObjectId()
    custom.name = name
    custom.image = ''
    custom.price = 0
    custom.addons = []
    custom.variants = [
      {
        price: 0,
        name: 'Default',
        _id: cartUtils.ObjectId(),
        tax: {},
        ean: '',
        sku: '',
        local_prices: [],
        parts: [],
        default: true
      }
    ]
    custom.options = {
      is_custom: true,
      stock: 'none'
    }
    custom.inventory = []
    custom.categories = []

    return custom
  }

  static createCartEntryStatus = amount => {
    return [{ last_update: new Date().toISOString(), state: 'new', amount: amount }]
  }

  static getProductPrice = (storeId, product, variantId, type = 'price') => {
    const variantIndex = variantId ? product.variants.findIndex(v => v._id === variantId) : 0
    const variant = variantIndex >= 0 ? product.variants[variantIndex] : product.variants[0]

    if (variant.local_prices && variant.local_prices.length) {
      const priceIndex = variant.local_prices.findIndex(local => local.store === storeId)

      if (priceIndex >= 0) {
        return variant.local_prices[priceIndex][type] ? variant.local_prices[priceIndex][type] : 0
      }
    }

    return variant[type] ? variant[type] : 0
  }

  static hasInventory = product => {
    return (
      !!product.options.stock &&
      product.options.stock === 'self' &&
      !!product.inventory &&
      !!product.inventory.length
    )
  }

  static hasImage = product => {
    return !!product.image && !!product.image.length
  }

  static hasDeposit = product => {
    const { deposit } = product.options || {}
    return !!deposit && !!deposit.price
  }

  static getDepositPrice = product => {
    const { deposit } = product.options || {}
    return !!deposit && !!deposit.price ? deposit.price : 0
  }

  static isCustomProduct = product => {
    return !!product.options && product.options.is_custom
  }

  static isVariantsProduct = product => {
    return product.variants.length > 1
  }

  static isAddonsProduct = product => {
    return (
      (product.addons && product.addons.length >= 1) ||
      (product.addonGroups && product.addonGroups.length >= 1)
    )
  }

  static isSoldByWeightProduct = product => {
    return !!product.options && !!product.options.sold_by_weight
  }

  static isDynamicPriceProduct = product => {
    return !!product.options && !!product.options.dynamic_pricing
  }

  static isNonRevenueProduct = product => {
    return !!product.options && !!product.options.non_revenue // && !(product.options?.voucher?.type === 'Giftcard')
  }

  static isGiftcard = product => {
    return !!product.options && product.options?.voucher?.type === 'Giftcard'
  }

  static needsBarcode = product => {
    return !!product.options && !!product.options.needs_barcode
  }

  static isDepositProduct = product => {
    const deposit = !!product.options && product.options.deposit
    return !!deposit && !!deposit.price
  }

  static getVariantStock = (storeId, product, variantId) => {
    if (!product) {
      return 0
    } else if (product.inventory.length) {
      const inventory = product.inventory.find(inv => inv.store === storeId)
      return inventory &&
        !!inventory.units &&
        (!!inventory.units[variantId] || inventory.units[variantId] === 0)
        ? inventory.units[variantId]
        : NOT_TRACKED
    } else {
      return product.options.stock === NONE ? NOT_TRACKED : 0
    }
  }

  static checkDelta = async (storeId, deviceId) => {
    const delta = localStorage.getItem(`${deviceId}_products_lastDelta`) || new Date().toISOString()
    const getUrl = (countFn = false) =>
      `stores/${storeId}/products${
        countFn ? '/delta/count' : '/delta'
      }?start_date=${delta}&include_deleted=true`

    try {
      return CountrRequest.request('GET', getUrl(true)).then(async count => {
        if (!!count) {
          localStorage.setItem(`${deviceId}_products_lastDelta`, new Date().toISOString())
          const countr = await AppInstances.getCountrSdk()
          const products = await CountrRequest.request('GET', getUrl())
          const promises = []

          products.forEach(async product => {
            if (!!product.deletedAt) {
              promises.push(WorkerResourceLoader.searchByIdAndDelete([product._id], 'products'))
            } else {
              const prodLoad = await countr.products.readOne(product._id)
              if (prodLoad.created_at > delta) {
                promises.push(
                  WorkerResourceLoader.searchByIdAndUpdateOrAdd(prodLoad, 'products', 'create')
                )
              } else {
                promises.push(
                  WorkerResourceLoader.searchByIdAndUpdateOrAdd(prodLoad, 'products', 'update')
                )
              }
            }
          })

          return await Promise.all(promises)
        }

        return Promise.resolve(false)
      })
    } catch (error) {
      this.logError({
        msg: 'Error when trying to Delete product in IndexedDB',
        stack: JSON.stringify(error)
      })

      return Promise.reject(error)
    }
  }

  static checkDeletedAndRemoveFromDB = async (store, delta) => {
    return CountrRequest.request(
      'GET',
      `stores/${store}/products/delta/count?start_date=${delta}&include_deleted=true`
    ).then(async count => {
      if (!!count) {
        const products = await CountrRequest.request(
          'GET',
          `stores/${store}/products/delta?start_date=${delta}&include_deleted=true&limit=200`
        )
        products.forEach(product => {
          if (product.deletedAt) {
            WorkerResourceLoader.searchByIdAndDelete([product._id], 'products').catch(async e => {
              this.logError({
                msg: 'Error when trying to Delete product in IndexedDB',
                stack: e.stack
              })
            })
          }
        })

        return Promise.resolve(true)
      }
    })
  }

  static checkDynamicBarcode = (product, ean) => {
    const prodCopy = JSON.parse(JSON.stringify(product))

    // if first digit of ena is '2' or '5' and product has dynamic pricing then calculate the price
    // from the last 5 digits of the ean (ignoring the last digit)
    if (
      ean.length >= 10 &&
      (ean[0] === '2' || ean[0] === '5') &&
      (prodCopy.options || {}).dynamic_pricing
    ) {
      const calculated_price = cartUtils.formatCurrency(ean.slice(-5, ean.length - 1) / 100)

      prodCopy.variants[0].price = !isNaN(calculated_price)
        ? calculated_price
        : prodCopy.variants[0].price

      return prodCopy
    } else {
      return null
    }
  }

  static updateProduct = async product => {
    try {
      const countr = await AppInstances.getCountrSdk()
      const productUpdated = await countr.products.update(product._id, product)
      return productUpdated
    } catch (error) {
      return error
    }
  }
}
