import { memo } from 'react';
import { Text } from 'react-internationalization'
import { AddRemoveButtons } from '@countr/ui'
import AddonUtils from '../../../utils/AddonUtils'

import './CartItemDetails.scss'

const CartItemAddons = memo(({ currentAddons, setCurrentAddons, addonsList, store }) => {
  const editAddons = (addon, action) => {
    const aux = AddonUtils.editAddon(currentAddons, action, addon, store._id)
    setCurrentAddons(aux)
  }

  return (
    <div className="center">
      <span>
        <Text id="addons" />
      </span>
      <div className="cart-item-addon-list">
        {addonsList.map(addon => (
          <div key={addon._id} className="addon">
            <span>{AddonUtils.getAddonNamePrice(addon, store._id, store.currency.symbol)}</span>
            <AddRemoveButtons
              value={AddonUtils.calculateAddonAmount(currentAddons, addon)}
              handleAdd={() => editAddons(addon, 'add')}
              handleRemove={() => editAddons(addon, 'remove')}
            />
          </div>
        ))}
      </div>
    </div>
  )
})

export default CartItemAddons
