import { AppInstances } from './countrSdkInstance'
import Util from './Util'
import escapeStringRegexp from 'escape-string-regexp'

export default class CustomerUtils {
  static searchCustomerByIdentifier = async (customers, identifier) => {
    let customer = customers.find(cust => {
      return (
        !!cust.options.identifiers &&
        cust.options.identifiers.find(id => id && !!id.code_value && id.code_value === identifier)
      )
    })

    // Customer not found, lets try to find it in customers api
    if (!customer && Util.isInternetOnline()) {
      const countr = await AppInstances.getCountrSdk()
      const search = await countr.customers.search({
        text: escapeStringRegexp(identifier)
      })

      customer = !!search && search.length ? search[0] : null
    }

    return customer
  }
}
