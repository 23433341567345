import { PureComponent } from 'react';
import { connect } from 'react-redux'
import Iframe from 'react-iframe'

import { AppInstances } from './../utils/countrSdkInstance'
import { PaymentUtils } from './../utils/PaymentUtils'
import { PrinterUtils } from './../utils/PrinterUtils'
import { CountrListeners } from './../utils/CountrListeners'
import DesktopUtils from './../utils/DesktopUtils'
import RegisterOperationsUtils from './../utils/RegisterOperationsUtils'

import { cartUtils } from './../utils/cartUtils'
import { RequestQueue } from '../utils/RequestQueue'
import Util from '../utils/Util'
import ScreenUtils from './../utils/ScreenUtils'
import ProductUtils from './../utils/ProductUtils'
import CategoryUtils from '../utils/CategoryUtils'
import StoreUtils from '../utils/StoreUtils'

import { setOutstandingInvoices, showCart } from '../store/actions/app'
import { selectCart, editCart, deleteCart } from './../store/actions/carts'
import {
  addTransactionHead,
  addRefundHead,
  editTransaction,
  setRefundExtras,
  updateTransaction
} from './../store/actions/transactions'
import {
  addAllEmployees
} from './../store/actions/employees'
import { loadingFalse, loadingTrue } from './../store/actions/loading'
import { addStore, updateDevice } from './../store/actions/devices'
import { setToastMessage, setIsDesktopConnected } from './../store/actions/app'
import { showEmployeesModal } from './../store/actions/employees'
import { deleteCategory } from './../store/actions/categories'

import Grid from '@material-ui/core/Grid'

import ErrorBounday from './ErrorBoundary'
import Menu from './Menu'
import Catalog from './Assortment/Catalog'
import Cart from './Cart/Cart'
import Employees from './Employees/Employees'
import PayplazaModal from './Payments/Providers/PayplazaModal'
import NewVersionModal from './generic/NewVersionModal'
import QuotaWarning from './generic/QuotaWarning'

import './Main.css'
import VoucherUtils from '../utils/VoucherUtils'

const mapStateToProps = state => {
  return {
    app: state.app,
    user: state.user,
    carts: state.carts,
    settings: state.settings,
    loading: state.loading,
    devices: state.devices,
    employees: state.employees,
    transactions: state.transactions,
    payments: state.payments
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setOutstandingInvoices: outstanding => dispatch(setOutstandingInvoices(outstanding)),
    showCart: () => dispatch(showCart()),
    selectCart: cart => dispatch(selectCart(cart)),
    editCart: cart => dispatch(editCart(cart)),
    deleteCart: id => dispatch(deleteCart(id)),
    addTransactionHead: transaction => dispatch(addTransactionHead(transaction)),
    addRefundHead: refund => dispatch(addRefundHead(refund)),
    editTransaction: transaction => dispatch(editTransaction(transaction)),
    addStore: store => dispatch(addStore(store)),
    updateDevice: device => dispatch(updateDevice(device)),
    setToastMessage: (msg, opt, action) => dispatch(setToastMessage(msg, opt, action)),
    showEmployeesModal: () => dispatch(showEmployeesModal()),
    loadingFalse: () => dispatch(loadingFalse()),
    loadingTrue: () => dispatch(loadingTrue()),
    setRefundExtras: extras => dispatch(setRefundExtras(extras)),
    updateTransaction: transaction => dispatch(updateTransaction(transaction)),
    setIsDesktopConnected: is => dispatch(setIsDesktopConnected(is)),
    deleteCategory: id => dispatch(deleteCategory(id)),
    addAllEmployees: employees => dispatch(addAllEmployees(employees))
  }
}

class Main extends PureComponent {
  state = {
    openPayplazaModal: false,
    payplazaRecoveryObj: {},
    openNewVersionDialog: false,
    openQuotaWarning: false,
    percent: '',
    deletedPeding: true
  }

  merchantCallback = message => {
    if (!!message && typeof message === 'string' && message.indexOf('outstanding invoices') >= 0) {
      if (!this.props.app.outstandingInvoices) {
        this.props.setOutstandingInvoices(true)
      }
    }
  }

  employeeCallBack = async(employeeId) => {
    const countr = await AppInstances.getCountrSdk()
    const employeeUpdated = await countr.employees.readOne(employeeId);

    const employeeIndex = this.props.employees.employees.findIndex(emp => emp._id === employeeId)
    if (employeeIndex > -1) {
      const updatedEmployees = [...this.props.employees.employees]
      updatedEmployees[employeeIndex] = employeeUpdated
      this.props.addAllEmployees(updatedEmployees)
    }
  }

  deviceCallback = (data, type) => {
    if (type === CountrListeners.getConstants().NOTIFICATION) {
      this.props.setToastMessage(`${data}`)
    }
    else if (type === CountrListeners.getConstants().DEVICE_UPDATED) {
      this.props.updateDevice(data)
    }
  }

  transactionsCallback = async (checkout, type) => {
    const deviceId = this.props.devices.device._id

    if (type === CountrListeners.getConstants().TRANSACTION_CREATED) {
      // If transaction from another device, add to the heade
      if (checkout.device !== deviceId) {
        this.props.addTransactionHead(checkout)
      }

      // If transaction is third party and delivery print feature is on, print order and receipt
      if (cartUtils.isThirdPartySource(checkout)) {
        if (this.props.settings.kitchenDeliveryPrint) {
          const { user, devices, setToastMessage } = this.props
          PrinterUtils.handleKitchenPrint(checkout, user, devices, setToastMessage)
        }
      }

      // If transaction is made by exchange, then update the source transaction
      if (cartUtils.isExchangeableCart(checkout)) {
        this.updateExchangeTransaction(checkout)
      }

      if (cartUtils.isGiftcardTransaction(checkout)) {
        const giftcards = cartUtils.getGiftcardsFromPayments(checkout)

        if (!!giftcards && !!giftcards.length) {
          giftcards.forEach(giftcard => {
            giftcard.activity[giftcard.activity.length - 1].checkout = checkout._id
            VoucherUtils.updateGiftcard(giftcard)
          })
        }
      }
    } else if (type === CountrListeners.getConstants().REFUND_CREATED) {
      // If refund from another device, add to the heade and
      // update the linked transaction of this refund
      if (checkout.device !== deviceId) {
        this.props.addRefundHead(checkout)
        const countr = await AppInstances.getCountrSdk()
        const transaction = await countr.transactions.readOne(checkout.transaction_source)
        this.props.updateTransaction(transaction)
      }
    }
  }

  updateExchangeTransaction = async exchange => {
    const countr = await AppInstances.getCountrSdk()
    const source = await countr.transactions.readOne(exchange.extras.exchange_source)

    if (source && source._id) {
      source.total_exchanged = exchange.extras.total_exchanged
      source.exchanges.push(exchange._id)
    }

    countr.transactions.update(source._id, source).then(
      async updated => {
        console.log('##### transaction updated with exchange id: ' + exchange._id)
        const exchangeCart = this.props.carts.carts.find(
          cart => cart.extras.exchange_source && cart.extras.exchange_source === source._id
        )
        await cartUtils.deleteCartServer(exchangeCart._id)
        const currentCart = JSON.parse(JSON.stringify(this.props.carts.carts[0]))
        this.props.selectCart(currentCart)
        this.props.deleteCart(exchangeCart._id)
        localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(currentCart))
        localStorage.removeItem('EXCHANGE:CountrLite:Cart-' + exchangeCart._id)
        this.props.editTransaction(updated)
      },
      error => {
        RequestQueue.enqueueAction({
          type: 'transactions',
          action: 'update',
          payload: source
        })
      }
    )
  }

  checkPayplazaRecovery = () => {
    const recovery = JSON.parse(localStorage.getItem('CountrWeb:PayplazaRecovery'))

    if (recovery !== null) {
      this.setState({ payplazaRecoveryObj: recovery }, () => {
        this.setState({ openPayplazaModal: true })
      })
    }
  }

  completePayplazaTransaction = resultCode => {
    console.log('​PaymentModal -> handleClosePayplazaModal -> resultCode', resultCode)

    if (resultCode === 'SUCCESS') {
      const isRefund = JSON.parse(localStorage.getItem('CountrWeb:RefundInProgress'))
      const isPayLater = localStorage.getItem('CountrWeb:PayplazaPayLaterInProgress')

      if (isRefund) {
        this.handleRefund()
      } else if (!!isPayLater) {
        this.handlePayLater(isPayLater)
      } else {
        this.handlePayment('payplaza')
      }
    } else {
      localStorage.removeItem('CountrWeb:RefundInProgress')
      localStorage.removeItem('CountrWeb:RefundExtraRecovery')
      localStorage.removeItem('CountrWeb:RefundExtraRecovery-Transaction')
    }
  }

  handleClosePayplazaModal = resultCode => {
    this.setState({ openPayplazaModal: false })
    localStorage.removeItem('CountrWeb:RefundInProgress')
    localStorage.removeItem('CountrWeb:RefundExtraRecovery')
    localStorage.removeItem('CountrWeb:RefundExtraRecovery-Transaction')
  }

  handlePayment = method => {
    const total = parseFloat(this.state.payplazaRecoveryObj.args.amount / 100)
    const change = 0
    const device = Object.assign({}, this.props.devices.device)
    const hasTables = StoreUtils.hasFloorplans(this.props.devices.store)
    const cart = Object.assign({}, this.props.carts.selectedCart)
    cart.employee = PaymentUtils.getEmployee(this.props.employees.selectedEmployee)
    const callbacks = {
      editCart: this.props.editCart,
      selectCart: this.props.selectCart,
      addTransactionHead: this.props.addTransactionHead
    }
    const { kioskMode, showCartsListAlphabeticOrder } = this.props.settings

    const cartIndex = showCartsListAlphabeticOrder
      ? cartUtils
          .sortCartListAlphabetical(this.props.carts.carts)
          .findIndex(c => c._id === cart._id)
      : this.props.carts.carts.findIndex(c => c._id === cart._id)

    const { paymentMethods } = this.props.payments
    const paymentMethod = paymentMethods.find(payment => payment.method === method)
    const provider = !!paymentMethod && !!paymentMethod.provider ? paymentMethod.provider : null
    const body = PaymentUtils.createTransactionBody(
      method,
      provider,
      cart,
      device,
      total,
      change,
      kioskMode
    )

    PaymentUtils.payWithMethod(body, cart, callbacks, cartIndex, hasTables).then(
      result => {
        console.log(`### Transaction #${body.receipt_id} created!`)
      },
      error => {
        console.log(error)
        this.props.setToastMessage('no_connection_sync')
      }
    )

    this.props.setToastMessage('recovery_transaction_complete', {
      ref: this.state.payplazaRecoveryObj.args.reference
    })
  }

  handleRefund = async () => {
    this.props.loadingTrue()
    const refundExtras = JSON.parse(localStorage.getItem('CountrWeb:RefundExtraRecovery'))
    const info = Object.assign({}, this.props.transactions.refundExtras)
    if (info.cardParsedData) {
      refundExtras.payments[0].card_print_info = Object.assign({}, info.cardParsedData)
    }
    refundExtras.payments[0].info = info
    const newNumber = parseInt(localStorage.getItem('CountrLite:LastTransaction'), 10) + 1

    const transactionExtras = JSON.parse(
      localStorage.getItem('CountrWeb:RefundExtraRecovery-Transaction')
    )

    const countr = await AppInstances.getCountrSdk()
    countr.refunds.create(refundExtras).then(
      refundComplete => {
        this.props.addRefundHead(refundComplete)

        // updating transaction with refund
        transactionExtras.refunds.push(refundComplete._id)
        transactionExtras.total_refunded = transactionExtras.total_refunded || 0
        transactionExtras.total_refunded += refundComplete.total
        // updating new last transaction number
        localStorage.setItem('CountrLite:LastTransaction', newNumber)

        countr.transactions
          .update(transactionExtras._id, transactionExtras)
          .then(updatedTransaction => {
            this.props.editTransaction(updatedTransaction)
            this.props.setRefundExtras({})
            this.props.loadingFalse()
          })

        localStorage.removeItem('CountrWeb:RefundInProgress')
        localStorage.removeItem('CountrWeb:RefundExtraRecovery')
        localStorage.removeItem('CountrWeb:RefundExtraRecovery-Transaction')
      },
      error => {}
    )
  }

  handlePayLater = async receipt_id => {
    this.props.loadingTrue()
    const transaction = this.props.transactions.transactions.find(t => t.receipt_id === receipt_id)
    const payment = {
      ...transaction.payments[0],
      date: new Date(),
      payment_started: new Date(),
      method: PaymentUtils.getPaymentMethod('payplaza', this.props.devices.device),
      provider: PaymentUtils.getPaymentProvider('payplaza', this.props.devices.device),
      postponed: false
    }

    const cart = { ...this.props.carts.selectedCart }
    const info = { ...cart.info }
    const card_print_info = { ...cart.card_print_info }

    delete cart.info
    delete cart.card_print_info
    this.props.selectCart(cart)
    this.props.editCart(cart)

    if (!!info && !!card_print_info) {
      payment.info = info
      payment.card_print_info = card_print_info
    }

    transaction.paid = payment.paid
    transaction.payments[0] = payment

    const countr = await AppInstances.getCountrSdk()

    try {
      const updated = await countr.transactions.update(transaction._id, transaction)
      this.props.editTransaction(updated)
      this.props.setToastMessage('recovery_transaction_complete', { ref: `#${updated.receipt_id}` })
    } catch (error) {
      RequestQueue.enqueueAction({
        type: 'transactions',
        action: 'update',
        payload: transaction
      })
    }

    localStorage.removeItem('CountrWeb:PayplazaPayLaterInProgress')
    this.props.loadingFalse()
  }

  registerAction = () => {
    const operationArgs = {
      action: 'app_start'
    }

    RegisterOperationsUtils.applyOperation(operationArgs)
  }

  componentDidMount = () => {
    if (!Object.keys(this.props.user.user).length) {
      // If the user is not defined, go back to login
      this.props.history.push({
        pathname: '/'
      })
    } else {
      this.checkEmployeePin()
      this.checkPayplazaRecovery()
      this.hideIntercomButton()
      this.checkNewVersion()
      this.checkQuotaWarning()
      this.checkDeletedOldResources()
      this.registerAction()

      CountrListeners.initMerchantListeners(this.props.user.user._id, this.merchantCallback)
      CountrListeners.initEmployeeListeners(this.props.user.user._id, this.employeeCallBack)
      CountrListeners.initStoreListeners(this.props.devices.store._id, this.props.addStore)
      CountrListeners.initDeviceListeners(this.props.devices.device._id, this.deviceCallback)
      if (
        this.props?.devices?.device?.settings?.web_settings?.printOrdersForMultipleStores &&
        this.props?.devices?.device?.settings?.onlineOrderStores?.length
      ) {
        const included = this.props.devices.device.settings.onlineOrderStores.find(
          s => s === this.props.devices.store._id
        )

        const storesTolIsten = !included
          ? [...this.props.devices.device.settings.onlineOrderStores, this.props.devices.store._id]
          : this.props.devices.device.settings.onlineOrderStores

        storesTolIsten.forEach(id => {
          CountrListeners.initTransactionsListeners(id, this.transactionsCallback)
        })
      } else {
        CountrListeners.initTransactionsListeners(
          this.props.devices.store._id,
          this.transactionsCallback
        )
      }

      if (this.props.settings.enableCustomerScreen) {
        const port = this.props.settings.customerScreenPort
        if (port && port.length) {
          ScreenUtils.welcome(this.props.devices.store, port)
        }
      }

      // Start the connection if Countr Desktop
      // Will start the Desktop socket Heartbeat interval
      // health check
      const { isDesktop, desktopIP } = this.props.app
      if (isDesktop && !!desktopIP) {
        AppInstances.getDesktopConnection(
          `${process.env.REACT_APP_WS_TYPE}://${desktopIP}:${DesktopUtils.getDesktopListenerPort()}`
        )
      }

      // Checking if is desktop or has A4 print enable
      if (isDesktop || this.props.settings.a4Print) {
        window.addEventListener('print-receipt', this.handlePrintReceipt)
      }

      // Show floorplan if has it and feture is on
      if (
        StoreUtils.hasFloorplans(this.props.devices.store) &&
        this.props.settings.floorplanOnNewSale
      ) {
        const e = new CustomEvent('handle-floorplan')
        window.dispatchEvent(e)
      }
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('print-receipt', this.handlePrintReceipt)
  }

  handlePrintReceipt = event => {
    if (event.detail.id) {
      setTimeout(async () => {
        const transaction = this.props.transactions.transactions.find(
          t => t.receipt_id === event.detail.id
        )
        if (transaction) {
          transaction.issued++

          if (!!event.detail.email) {
            transaction.emails.push(event.detail.email)
          }

          const countr = await AppInstances.getCountrSdk()
          countr.transactions.update(transaction._id, transaction)
        }
      }, 5000)
    }
  }

  checkDeletedOldResources = () => {
    const delta = localStorage.getItem(`${this.props.devices.device._id}_lastDelta_deleted`)

    if (delta) {
      const storeId = this.props.devices.store._id
      const promises = [
        ProductUtils.checkDeletedAndRemoveFromDB(storeId, delta),
        CategoryUtils.checkDeletedAndRemoveFromDB(storeId, delta, this.props.deleteCategory)
      ]

      Promise.all(promises).then(result => {
        if (result[0]) {
          this.setState({ deletedPeding: false })
        }
      })

      localStorage.removeItem(`${this.props.devices.device._id}_lastDelta_deleted`)
    }
  }

  hideIntercomButton = () => {
    const inter = document.querySelector('.intercom-launcher')
    if (inter) {
      inter.style.display = 'none'
    }
  }

  storeHasEmployees = () => {
    return (
      this.props.devices.store.employees !== undefined &&
      this.props.devices.store.employees.length > 0
    )
  }

  checkEmployeePin = () => {
    if (this.props.settings.employeePin && this.storeHasEmployees()) {
      this.props.showEmployeesModal()
    }
  }

  handleCloseEmployeesModal = () => {
    this.props.showEmployeesModal()
  }

  checkNewVersion = () => {
    const newVersionAvailable = JSON.parse(localStorage.getItem('newVersionAvailable'))

    if (newVersionAvailable) {
      this.setState({ openNewVersionDialog: true })
    }
  }

  checkQuotaWarning = async () => {
    if ('storage' in navigator && 'estimate' in navigator.storage) {
      const estimate = await navigator.storage.estimate()
      const quotaPercent = parseFloat((estimate.usage / estimate.quota) * 100)
      const quotaMsg = quotaPercent.toFixed(2) + '% used'

      if (quotaPercent > 90) {
        this.setState({ openQuotaWarning: true, percent: quotaPercent })
        const errorObj = {
          source: Util.returnPOSType(),
          message: `[${Util.returnPOSType()}] Quota is ${quotaMsg}`,
          user: this.props.user.user._id,
          store: this.props.devices.store._id,
          device: this.props.devices.device._id,
          date: new Date(),
          info: {
            quota: quotaMsg
          }
        }
        AppInstances.logError(errorObj)
      }
    }
  }

  handleCloseQuotaWarning = () => {
    this.setState({ openQuotaWarning: false, percent: '' })
  }

  handleCloseNewVersion = () => {
    this.setState({ openNewVersionDialog: false })
  }

  handleReloadApp = () => {
    this.setState({ openNewVersionDialog: false })
    localStorage.removeItem('newVersionAvailable')
    window.location.reload(true)
  }

  handleTableDicount(allCarts) {
    let discountNumeric = 0
    let total = 0
    let subTotal = 0
    let paidSoFar = 0
    const cartsWithDiscount = []
    const tablePayments = []

    allCarts.forEach(cart => {
      const discount = cartUtils.calculateCartDiscount(cart)
      discountNumeric += discount
      total += cart.total
      subTotal += cart.sub_total

      if (discount && discount > 0) {
        cartsWithDiscount.push({
          _id: cart._id,
          discount: cartUtils.calculateCartDiscountPercent(cart),
          numericDiscount: cartUtils.calculateCartDiscount(cart)
        })
      }

      if (cart.payments?.length > 0) {
        paidSoFar += cart.payments.reduce((acc, current) => acc + current.paid, 0)
        tablePayments.push({
          _id: cart._id,
          payments: cart.payments
        })
      }
    })
    return {
      discountNumeric: discountNumeric,
      total: total,
      subTotal: subTotal,
      paidSoFar: paidSoFar,
      cartsWithDiscount: cartsWithDiscount,
      tablePayments: tablePayments
    }
  }

  componentDidUpdate(prevProps) {
    const isMultiStoreEnabled =
      this.props?.settings?.printOrdersForMultipleStores || false

    const prevList = prevProps.settings?.listeningStores?.length
      ? prevProps.settings.listeningStores
      : this.props?.devices?.device?.settings?.onlineOrderStores || []

    if (
      isMultiStoreEnabled &&
      prevProps.settings?.listeningStores !== this.props.settings?.listeningStores
    ) {
      // Remove previous listeners and add the new ones
      prevList.forEach(s =>
        CountrListeners.removeStoreTransactionListeners(s, this.transactionsCallback)
      )
      // add new listeners
      const storesTolIsten = this.props.settings?.listeningStores?.filter(
        s => s !== this.props.devices.store._id
      )

      storesTolIsten.forEach(id =>
        CountrListeners.initTransactionsListeners(id, this.transactionsCallback)
      )
    } else if (
      prevProps.settings.printOrdersForMultipleStores &&
      !this.props.settings.printOrdersForMultipleStores
    ) {
      // if the settings are disabled remove listeners from other stores
      const storesToStopListening = prevList.filter(s => s !== this.props.devices.store._id)
      storesToStopListening.forEach(s =>
        CountrListeners.removeStoreTransactionListeners(s, this.transactionsCallback)
      )
    }
  }

  render() {
    return (
      <ErrorBounday user={this.props.user.user} device={this.props.devices.device}>
        <div id="main-view">
          {this.props.app.showKds && (
            <Iframe
              url={`${process.env.REACT_APP_KDS_URL}/login/${this.props.devices.device._id}`}
              id="kdsIframed"
              className="kdsIframed"
              position="absolute"
            />
          )}
          {this.props.app.showLeftMenu && <Menu />}

          {this.props.settings.cartAlwaysOpen ? (
            <div className="main">
              <div className="main-left" id="main-left">
                <Catalog />
              </div>
              <div className="main-right">
                <Cart selectedCart={this.props.carts.selectedCart} />
              </div>
            </div>
          ) : (
            <div>
              <Grid container>
                <Grid item xs={12}>
                  <Catalog />
                </Grid>
              </Grid>
              {this.props.app.cart && (
                <div>
                  <div className="cart-backdrop" onClick={() => this.props.showCart()} />
                  <div id="cart-arrow-border" className="arrow-up-border" />
                  <div id="cart-arrow" className="arrow-up" />
                  <Cart selectedCart={this.props.carts.selectedCart} />
                </div>
              )}
            </div>
          )}
          {this.props.employees.employeesModal && (
            <Employees
              openEmployeesModal={this.props.employees.employeesModal}
              handleCloseEmployeesModal={this.handleCloseEmployeesModal}
            />
          )}
          {this.state.openPayplazaModal && (
            <PayplazaModal
              openPayplazaModal={this.state.openPayplazaModal}
              handleClosePayplazaModal={this.handleClosePayplazaModal}
              completePayplazaTransaction={this.completePayplazaTransaction}
              type="REPRINT_LAST"
              currency={this.state.payplazaRecoveryObj.args.currency}
              totalToPay={Math.round(this.state.payplazaRecoveryObj.args.amount / 100)}
            />
          )}
          {this.state.openNewVersionDialog && (
            <NewVersionModal
              open={this.state.openNewVersionDialog}
              handleClose={this.handleCloseNewVersion}
              handleReload={this.handleReloadApp}
            />
          )}
          {this.state.openQuotaWarning && (
            <QuotaWarning
              open={this.state.openQuotaWarning}
              handleClose={this.handleCloseQuotaWarning}
              percent={this.state.percent}
            />
          )}
        </div>
      </ErrorBounday>
    )
  }
}

const MainConnected = connect(mapStateToProps, mapDispatchToProps)(Main)
export default MainConnected
