import { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'

import { Text, translate } from 'react-internationalization'

import { AppInstances } from '../../../utils/countrSdkInstance'
import { CountrRequest } from '../../../utils/CountrRequest'
import ScreenUtils from '../../../utils/ScreenUtils'
import RegisterOperationsUtils from '../../../utils/RegisterOperationsUtils'

import { setToastMessage } from '../../../store/actions/app'
import { showEmployeesModal } from '../../../store/actions/employees'

import InputCashHelper from './InputCashHelper'

import {
  getStartDay,
  getEndDay,
  evaluateCurrentShiftState,
  getLocalStorageKeys,
  sharedShiftClearStorage,
  createOperationObj
} from '../../../utils/EmployeeUtils'

const mapStateToProps = state => {
  return {
    devices: state.devices,
    employees: state.employees,
    settings: state.settings,
    queue: state.queue
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setToastMessage: msg => dispatch(setToastMessage(msg)),
    showEmployeesModal: () => dispatch(showEmployeesModal())
  }
}

export const EmployeeShift = memo(props => {
  const [haveStartDay, setStartDay] = useState(true)
  const [haveEndDay, setEndDay] = useState(true)
  const [dialog, toggleDialog] = useState(false)
  const [amount, setAmount] = useState(0)
  const [tickets, setTickets] = useState(0)
  const [reportAmount, setReportAmount] = useState(0)
  const [startAmount, setStartAmount] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const [reason, changeReason] = useState('')
  const [note, changeNote] = useState('')
  const [totalMovement, setTotalMovement] = useState(0)

  useEffect(() => {
    const { haveStartDay, haveEndDay } = evaluateCurrentShiftState(
      getStartDay(props.employeeId || props.devices.device._id),
      getEndDay(props.employeeId || props.devices.device._id)
    )

    const startAmountStored = props.settings.sharedShift
    ? getLocalStorageKeys('_startDayAmount')
    : localStorage.getItem(`${props.employeeId || props.devices.device._id}_startDayAmount`)
    
    setStartAmount(startAmountStored ? parseFloat(startAmountStored) : null)
    setStartDay(haveStartDay)
    setEndDay(haveEndDay)
    setAmount(0)
    setTickets(0)

    if (props.settings.enableCustomerScreen) {
      // If has screen connect (desktop only) add a flag to block change of the screen
      const port = props.settings.customerScreenPort
      if (port && port.length) {
        if (haveStartDay) {
          ScreenUtils.welcome(props.devices.store, port)
        } else {
          ScreenUtils.kassaClosed(true)
          ScreenUtils.sendPayload(ScreenUtils.returnKassaClosedPayload(), port)
        }
      }
    }
  }, [props.employees.selectedEmployee._id, props.settings.sharedShift])

  const getReport = async () => {
    let startDate = props.settings.sharedShift
      ? getLocalStorageKeys('_startDay')
      : localStorage.getItem(`${props.employeeId || props.devices.device._id}_startDay`)
      
    const currentEmployee = props.employeeId || props.devices.device._id

    const deviceOrEmployee = props.employeeId ? 'employee' : 'device'

    if (startDate) {
      startDate = JSON.parse(startDate)
    } else {
      return props.setToastMessage('error_shift_start_day')
    }

    const finalDate = new Date().toISOString()
    const device = props.devices.device
    const store = props.devices.store

    const objToGenerate = {
      start_date: startDate,
      end_date: finalDate,
      date: finalDate,
      devices: [device._id],
      stores: [store._id]
    }

    if (props.employeeId) {
      if(props.settings.sharedShift){
        objToGenerate.employees = props.employees
      }else{
        objToGenerate.employees = [props.employeeId]
      }
    }

    const countr = await AppInstances.getCountrSdk()
    const report = await countr.reports.generate(JSON.stringify(objToGenerate))

    // !TODO use SDK
    const movements = await CountrRequest.request(
      'GET',
      'register-operations',
      `?filter[action]=movement&filter[date]=${startDate}&operator[date]=$gt&filter[${deviceOrEmployee}]=${currentEmployee}`
    )

    const movementTotal = movements.reduce((acumulator, current) => acumulator + current.cash, 0)
    setTotalMovement(movementTotal)

    if (Object.keys(report).length) {
      if (
        store.currency.code &&
        report.content[store.currency.code]?.tables?.transaction_by_method?.details[
          'total cash after tips and change'
        ]
      ) {
        const totalCash =
          report.content[store.currency.code].tables.transaction_by_method.details[
            'total cash after tips and change'
          ].amount
        setReportAmount(totalCash.toFixed(2))
      }

      return report
    } else {
      setReportAmount(0)
    }
  }

  const handleChangeAmount = finalAmount => {
    setAmount(isNaN(finalAmount) ? 0 : finalAmount)
  }

  const handleChangeStartAmount = (event, type) => {
    const amount = isNaN(event.target.value) ? 0 : event.target.value
    switch (type) {
      case 'tickets':
        setTickets(parseFloat(amount))
        break

      default:
        setAmount(parseFloat(amount))
        break
    }
  }

  const checkIfInvalid = () => {
    if (isNaN(amount)) {
      return true
    }

    return (
      isSaving || !amount || amount === '' || (!haveEndDay && parseFloat(amount) !== sumAmounts())
    )
  }

  const saveDay = isStart => {
    let reasonMessage = checkIfInvalid() ? reason : ''

    if (isStart) {
      reasonMessage = note
    }

    setIsSaving(true)

    if (amount === '' || isNaN(amount)) {
      props.setToastMessage('not_valid_input')
      setIsSaving(false)

      return
    }

    // Saving shift
    const currentDate = new Date().toISOString()

    const operationArgs = {
      action: isStart ? 'open' : 'close',
      cash: amount,
      tickets: tickets,
      note: reasonMessage,
      extras: {}
    }

    if(!isStart) {
      const expectedAmount = parseFloat(reportAmount) + parseFloat(startAmount) + parseFloat(totalMovement)
      const cashInRegister = parseFloat(amount)
      const difference = parseFloat(checkDifference())
      const expectedAmountBreakdown = {
          cash_amount : parseFloat(reportAmount),
          opening_balance : parseFloat(startAmount),
          cashed_in_out : parseFloat(totalMovement)
      }
      operationArgs.extras = {expectedAmount, cashInRegister, difference, expectedAmountBreakdown, ...operationArgs.extras}
    }

    RegisterOperationsUtils.applyOperation(operationArgs)

    setIsSaving(false)
    toggleDialog(false)

    if (isStart) {
      localStorage.setItem(
        `${props.employeeId || props.devices.device._id}_startDay`,
        JSON.stringify(currentDate)
      )
      localStorage.setItem(`${props.employeeId || props.devices.device._id}_startDayAmount`, amount)

      setStartAmount(parseFloat(amount))
      setEndDay(false)
      setStartDay(true)

      props.setToastMessage('opening_shift')

      if (props.settings.enableCustomerScreen) {
        // If has screen connect (desktop only) add a flag to block change of the screen
        ScreenUtils.kassaClosed(true)
        const port = props.settings.customerScreenPort
        if (port && port.length) {
          ScreenUtils.welcome(props.devices.store, port)
        }
      }
    } else {
      if (props.settings.sharedShift) {
        sharedShiftClearStorage()
      } else {
        localStorage.removeItem(`${props.employeeId || props.devices.device._id}_startDay`)
        localStorage.removeItem(`${props.employeeId || props.devices.device._id}_startDayAmount`)
      }

      setStartAmount(null)
      setEndDay(true)
      setStartDay(false)

      changeReason('')

      props.setToastMessage('closing_shift')

      if (props.settings.enableCustomerScreen) {
        // If has screen connect (desktop only) add a flag to block change of the screen
        ScreenUtils.kassaClosed(true)
        const port = props.settings.customerScreenPort
        if (port && port.length) {
          ScreenUtils.sendPayload(ScreenUtils.returnKassaClosedPayload(), port)
        }
      }
    }

    if (!isStart && props.employeeId) {
      props.showEmployeesModal()
    }
  }

  const openCloseDay = () => {
    if (!haveEndDay && haveStartDay) {
      cleanStart()
      setReportAmount(0)
      changeReason('')
      getReport()
      toggleDialog(true)
    }
  }

  const cleanStart = () => {
    setAmount(0)
    changeNote('')
    setTickets(0)
  }

  const sumAmounts = () => {
    const handleGetQueueAmount = props.queue.queueList.reduce((acc, current) => {
      if (
        current?.type === 'registerOperations' &&
        (current?.payload?.action === 'movement' || current?.payload?.action === 'open')
      ) {
        const amount = current?.payload?.cash || 0
        return acc + parseFloat(amount)
      }
    }, 0)

    let summed = handleGetQueueAmount + parseFloat(reportAmount) + parseFloat(startAmount)

    summed =
      !~Math.sign(totalMovement) && summed < totalMovement
        ? summed - totalMovement
        : summed + totalMovement
    return summed
  }

  const checkDifference = () => {
    const n = parseFloat(amount) - sumAmounts()
    return !isNaN(n) ? n : 0 - sumAmounts()
  }

  const formatDifference = formatedAmount => {
    const symbol = parseFloat(amount) > sumAmounts() ? '+' : ''
    return `${props.devices.store.currency.symbol}${symbol}${formatedAmount.toFixed(2)}`
  }

  return (
    <div>
      <Button
        className="employee-shift"
        disabled={haveStartDay}
        onClick={() => {
          toggleDialog(true)
          cleanStart()
        }}>
        <span className="employee-icon-span">{translate('open_shift')}</span>
      </Button>

      <Button className="employee-shift-end" disabled={haveEndDay} onClick={() => openCloseDay()}>
        <span className="employee-icon-span">{translate('close_shift')}</span>
      </Button>

      <Dialog open={dialog} className="employee-shift-dialog">
        <DialogTitle className="form-shift-title">
          <Text id={haveEndDay ? 'open_shift' : 'close_shift'} />
          <Divider className="separator" />
        </DialogTitle>

        <DialogContent className="employee-shift-dialog-content">
          <Grid container justifyContent="center" alignItems="center">
            {!haveEndDay && (
              <Grid item xs={12}>
                <InputLabel className="dialog-input-label">
                  <Text id="previous_start_amount" />
                </InputLabel>

                <TextField
                  disabled={true}
                  value={`${props.devices.store.currency.symbol}${sumAmounts().toFixed(2)}`}
                  fullWidth={true}
                />

                <InputLabel className="dialog-input-label">
                  <Text id="cash_difference" />
                </InputLabel>

                <TextField
                  disabled={true}
                  error={checkIfInvalid()}
                  value={formatDifference(checkDifference())}
                  fullWidth={true}
                />

                <div className="shift-form-styles">
                  <form>
                    <Input
                      value={amount}
                      type="number"
                      autoFocus={true}
                      fullWidth={true}
                      placeholder={translate('enter_amount')}
                      onChange={evt => handleChangeAmount(evt.target.value)}
                      startAdornment={
                        <InputAdornment position="start">
                          <i className="icon-cash-thin" />
                        </InputAdornment>
                      }
                    />

                    {/* 
											Removing Tickets interface, functionality remain
											Just uncomment it and Users will be able to use it again
										<Input
											type="number"
											fullWidth={true}
											placeholder={translate('tickets_in')}
											onChange={(evt) => handleChangeStartAmount(evt, 'tickets')}
											startAdornment={
												<InputAdornment position="start">
													<i className="icon-card-thin" />
												</InputAdornment>
											}
										/> */}

                    {parseFloat(amount) !== sumAmounts() && (
                      <div>
                        <TextField
                          placeholder={translate('add_note_hint')}
                          value={reason}
                          fullWidth={true}
                          onChange={event => changeReason(event.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className="icon-note" />
                              </InputAdornment>
                            )
                          }}
                        />
                        <p className="helper_message">{translate('reason_mandatory_helper')}</p>
                      </div>
                    )}
                  </form>
                </div>

                {/* Cash and Coins input's component */}
                <InputCashHelper
                  currency={props.devices.currency ? props.devices.currency : {}}
                  handlerMethod={handleChangeAmount}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              {!haveStartDay && (
                <div className="shift-form-styles">
                  <form>
                    <TextField
                      value={amount.toString()}
                      type="number"
                      autoFocus={true}
                      fullWidth={true}
                      placeholder={translate('enter_amount')}
                      onChange={evt => handleChangeStartAmount(evt, 'amount')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <i className="icon-cash-thin" />
                          </InputAdornment>
                        )
                      }}
                    />

                    <Input
                      type="number"
                      fullWidth={true}
                      placeholder={translate('tickets_in')}
                      onChange={evt => handleChangeStartAmount(evt, 'tickets')}
                      startAdornment={
                        <InputAdornment position="start">
                          <i className="icon-card-thin" />
                        </InputAdornment>
                      }
                    />

                    <TextField
                      placeholder={translate('add_note_hint')}
                      value={note}
                      fullWidth={true}
                      onChange={event => changeNote(event.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <i className="icon-note" />
                          </InputAdornment>
                        )
                      }}
                    />
                  </form>

                  {/* Cash and Coins input's component */}
                  <InputCashHelper
                    currency={props.devices.currency ? props.devices.currency : {}}
                    handlerMethod={handleChangeAmount}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            className="cancel-shift-btn"
            color="secondary"
            onClick={() => toggleDialog(false)}>
            <Text id="cancel" />
          </Button>
          <Button
            disabled={checkIfInvalid() && reason === ''}
            className="save-shift-btn"
            color="primary"
            onClick={() => saveDay(haveEndDay)}>
            <Text id="ok" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
})

EmployeeShift.propTypes = {
  employeeId: PropTypes.string,
  devices: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeShift)
