/* eslint-disable max-len */
import { translate } from 'react-internationalization'
import debounce from 'lodash.debounce'
import CountrClient from '@countr/sdk-convious'
import { AppInstances } from './countrSdkInstance'
import ProductUtils from './ProductUtils'
import store from './../index'
import { selectCart, addCart, editCart } from '../store/actions/carts'
import ScreenUtils from './ScreenUtils'
import ErrorUtils from './ErrorUtils'
// import StoreUtils from './StoreUtils'

export const cartUtils = (() => {
  const countrClient = new CountrClient({
    opts: {
      staging: false,
      local: false,
      enableSocket: false
    }
  })

  const cartUpdateDebounce = cart => {
    AppInstances.getCountrSdk().then(countr => {
      const device = store.getState().devices.device
      const newCart = {
        ...cart,
        updated_at: new Date(),
        originator: device._id,
        server_modified: false
      }

      return countr.carts.update(newCart._id, newCart).then(
        updated => {
          return Promise.resolve(updated)
        },
        error => {
          if (error && error.status && error.status === 500) {
            this.cartNotFound(cart)
          }
          return Promise.reject(error)
        }
      )
    })
  }

  const updateCartServerDebounce = debounce(cartUpdateDebounce, 700, {
    leading: false,
    trailing: true
  })

  return {
    getLatestReceiptId: async (merchantId, device) => {
      const countr = await AppInstances.getCountrSdk()
      const lastReceiptId = await countr.carts.read({ limit: 1, sort: '-created_at' })

      let newReceiptId = null

      if (lastReceiptId?.length && lastReceiptId[0].receipt_id) {
        const receiptId = lastReceiptId[0].receipt_id.split('-')[2]
        newReceiptId = Number(receiptId) + 1
      }

      return newReceiptId
    },
    createCart: function (merchantId, device, index, cartAsTable = false) {
      const lastTransaction = JSON.parse(localStorage.getItem('CountrLite:LastTransaction'))

      const cart = {
        merchant: merchantId,
        store: device.store._id,
        device: device._id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        date: new Date(),
        currency: device.store.currency,
        order_source: 'web_pos',
        receipt_id:
          device.store.store_id + '-' + device.device_id + '-' + (new Date().getTime() + index),
        receipt_number: lastTransaction ? lastTransaction : 1,
        customer: null,
        employee: { _id: null, name: 'None' },
        items: [],
        total: 0,
        sub_total: 0,
        discount: 0,
        change: 0,
        reduction: {
          reduction: 0,
          percentage: 0
        },
        payments: [],
        paid: 0,
        extras: {
          deviceCartNumber: index,
          deviceCartName: `${cartAsTable ? 'TABLE ' : 'CART '} ${index}`,
          appVersion: process.env.REACT_APP_VERSION,
          cartStartedAt: new Date(),
          drop_ship: false,
          takeaway: false,
          buzzer: null,
          covers: 0
        }
      }

      return cart
    },
    createCartServer: async function (cart) {
      const countr = await AppInstances.getCountrSdk()
      return countr.carts.create(cart).then(
        createdCart => {
          return Promise.resolve(createdCart)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    updateCartServerDebounce: async function (cart) {
      return updateCartServerDebounce(cart)
    },
    updateCartServer: async function (cart) {
      return AppInstances.getCountrSdk().then(countr => {
        const device = store.getState().devices.device
        const toUpdateCart = {
          ...cart,
          updated_at: new Date(),
          originator: device._id,
          server_modified: false
        }
        return countr.carts.update(toUpdateCart._id, toUpdateCart).then(
          updated => {
            return Promise.resolve(updated)
          },
          error => {
            if (error && error.status && error.status === 500) {
              this.cartNotFound(cart)
            }
            return Promise.reject(error)
          }
        )
      })
    },
    updateCartLocally: function (cart) {
      if (cart?._id) {
        localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(cart))
        localStorage.setItem('CountrLite:Cart-' + cart._id, JSON.stringify(cart))
        store.dispatch(editCart(cart))
      }
    },
    cartNotFound: function (cart) {
      const index = cart.extras.deviceCartNumber || 1
      const device = store.getState().devices.device
      const newCart = this.createCart(cart.merchant, device, index)
      newCart.items = cart.items
      newCart.total = cart.total
      newCart.sub_total = cart.sub_total
      newCart.discount = cart.discount
      newCart.paid = cart.paid
      newCart.payments = cart.payments
      newCart.customer = cart.customer
      newCart.extras = cart.extras
      return this.createCartServer(newCart).then(
        createdCart => {
          store.dispatch(selectCart(createdCart))
          store.dispatch(addCart(createdCart))
          localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(createdCart))
          localStorage.setItem('CountrLite:Cart-' + createdCart._id, JSON.stringify(createdCart))
          return Promise.resolve(createdCart)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    deleteCartServer: async function (id) {
      const countr = await AppInstances.getCountrSdk()
      return countr.carts.delete(id).then(
        deletedCart => {
          return Promise.resolve(deletedCart)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    addProductToCart: async function (
      currentCart,
      product,
      variant,
      addons = [],
      amount = 1,
      settings
    ) {
      const cart = { ...currentCart }
      const cartEntry = ProductUtils.createCartEntry(product, variant, addons, amount, cart.store)
      const itemID = this.getCartEntryId(cartEntry)
      const itemIndex = cart.items.findIndex(item => this.getCartEntryId(item) === itemID)

      if (!cart.device) {
        const device = store.getState().devices.device
        cart.device = device._id
      }

      if (!cart.items) {
        cart.items = []
      } else if (!cart.items.length) {
        // Updating cart start at
        if (!!cart.extras) {
          cart.extras.cartStartedAt = new Date()
        } else {
          cart.extras = {
            deviceCartName: 'CART',
            appVersion: process.env.REACT_APP_VERSION,
            cartStartedAt: new Date(),
            drop_ship: false,
            takeaway: false,
            buzzer: null,
            covers: 0
          }
        }
      }

      // Adding new Item into carts items array
      if (itemIndex < 0) {
        cart.items.unshift(cartEntry)
      } else if (itemIndex >= 0) {
        cart.items[itemIndex].amount += cartEntry.amount
        cart.items[itemIndex].status = cart.items[itemIndex].status || []
        const newStatus = cart.items[itemIndex].status.findIndex(st => st.state === 'new')

        if (newStatus >= 0) {
          cart.items[itemIndex].status[newStatus].amount += cartEntry.amount
        } else {
          cart.items[itemIndex].status.concat(ProductUtils.createCartEntryStatus(cartEntry.amount))
        }

        // Removing cartEntry from array and add it again to the head of it
        const entryCopy = { ...cart.items[itemIndex] }
        cart.items.splice(itemIndex, 1)
        cart.items.unshift(entryCopy)
      }

      const countr = await AppInstances.getCountrSdk()
      const { totalAmount, totalAmountPreTax } = countr.calculateTotal(cart)

      // Recalculating total/subtotal and update
      cart.total = totalAmount
      cart.sub_total = totalAmountPreTax
      cart.date = new Date()

      // Lets update Redux and Localstorage right away
      // avoiding Cart sync problems
      store.dispatch(selectCart(cart))
      this.updateCartLocally(cart)

      // Throttle function to update Countr
      // leading: false, trailing: true, meaning
      // just one request after 700 milleseconds
      updateCartServerDebounce(cart)

      if (settings.enableCustomerScreen && !!currentCart.items && currentCart.items.length) {
        const port = settings.customerScreenPort
        if (!!port) {
          ScreenUtils.addProduct(cartEntry.product, cart.total, cart.currency.code, port)
        }
      }

      return cartEntry
    },
    getCartTotal: function (cart) {
      if (cart.items.length === 0) {
        return (0).toFixed(2)
      } else {
        let total = 0
        cart.items.forEach(item => {
          total += this.calculateItemPrice(item)
        })

        if (cart.discount && cart.discount > 0) {
          total = parseFloat(total) * (1 - cart.discount)
        } else if (cart.reduction && cart.reduction.numeric) {
          total = parseFloat(total) - cart.reduction.numeric
        }

        return isNaN(total) ? (0).toFixed(2) : total.toFixed(2)
      }
    },
    getCartTotalNoDiscount: function (cart) {
      if (cart.items.length === 0) {
        return (0).toFixed(2)
      } else {
        let total = 0
        cart.items.forEach(item => {
          total += this.calculateItemPrice(item)
        })

        total = parseFloat(total)

        return isNaN(total) ? (0).toFixed(2) : total.toFixed(2)
      }
    },
    getCartTaxes: function (cart) {
      if (cart.items.length === 0) {
        return (0).toFixed(2)
      } else {
        const sdkTaxesCalculated = countrClient.calculateTotal(cart)

        return sdkTaxesCalculated.totalAmount - sdkTaxesCalculated.totalAmountPreTax
      }
    },
    // Return an array of tax obj {name, rate, amount, overValue}
    getTransactionTaxes: async function (transaction) {
      const taxes = []
      const countr = await AppInstances.getCountrSdk()

      let discount = 0

      if (transaction.reduction) {
        if (transaction.reduction.numeric > 0) {
          discount =
            transaction.reduction.numeric /
            (parseFloat(transaction.total) + transaction.reduction.numeric)
        } else if (transaction.reduction.numeric === 0 && transaction.reduction.percentage > 0) {
          discount = transaction.reduction.percentage
        }
      }

      const sdkTaxesCalculated = countr.calculateTotal(transaction)

      const parsedTaxes = Object.entries(sdkTaxesCalculated.taxes_breakdown).map(line => {
        return {
          name: `${line[0]} - ${line[0]}`,
          amount: line[1].preTax,
          above: line[1].postTax,
          rate: line[1].tax
        }
      })

      taxes.push(...parsedTaxes)

      // Calculating items taxes - done in SDK
      // transaction.items.forEach(item => {
      //   const tax = item.product.current_variant.tax
      //   const taxName = `${tax.name} - ${Number(this.getCartItemTaxRate(item) * 100).toFixed(2)}%`
      //   const rate = this.getCartItemTaxRate(item)
      //   const depositValue = this.returnProductDepositValue(item.product) * item.amount
      //   const itemPrice = this.calculateItemPrice(item) - depositValue
      //   const itemPriceDiscounted = itemPrice * (1 - discount)
      //   const itemTax = (itemPriceDiscounted * rate) / (1 + rate)
      //   const depositTax = this.returnProductDepositTax(item.product)

      //   const above = itemPriceDiscounted / (1 + rate)
      //   const aboveTax = depositValue / (1 + depositTax.amount)

      //   if (depositTax.name !== 'Zero - 0%') {
      //     const depositIndex = taxes.findIndex(t => t.name === depositTax.name)
      //     if (depositIndex === -1) {
      //       taxes.push({
      //         name: depositTax.name,
      //         amount: depositTax.amount,
      //         above: aboveTax,
      //         rate: depositTax.rate
      //       })
      //     } else {
      //       taxes[depositIndex].amount += depositTax.amount
      //       taxes[depositIndex].above += aboveTax
      //     }
      //   }
      // })

      // Calulating delivery cost taxes (if it has) - done in SDK
      // if (!!transaction.extras.delivery && !!transaction.extras.delivery.deliveryCost) {
      //   const cost = this.getDeliveryCost(transaction)
      //   const deliveryRate = this.getDeliveryRate(transaction)
      //   const feeTax = (cost * deliveryRate) / (1 + deliveryRate)
      //   const above = cost - feeTax

      //   const index = taxes.findIndex(t => t.rate === deliveryRate)
      //   if (index === -1) {
      //     taxes.push({
      //       name: `${deliveryRate * 100}% - ${deliveryRate * 100}%`,
      //       amount: feeTax,
      //       above: above,
      //       rate: deliveryRate
      //     })
      //   } else {
      //     taxes[index].amount += feeTax
      //     taxes[index].above += above
      //   }
      // }

      const sortTaxes = taxes.sort((a, b) => {
        if (a.rate < b.rate) {
          return -1
        } else if (a.rate > b.rate) {
          return 1
        } else {
          return 0
        }
      })

      return sortTaxes
    },
    getCartSubTotal: function (cart) {
      const total = parseFloat(this.getCartTotal(cart))

      if (total === 0) {
        return (0).toFixed(2)
      }

      let discount = 0

      if (cart.reduction) {
        if (cart.reduction.numeric > 0) {
          discount = cart.reduction.numeric / (parseFloat(cart.total) + cart.reduction.numeric)
        } else if (cart.reduction.numeric === 0 && cart.reduction.percentage > 0) {
          discount = cart.reduction.percentage
        }
      }

      let subTotal = 0

      cart.items.forEach(item => {
        const itemPrice = this.calculateItemPrice(item) // (prod price + addons - discount) * amount
        const taxRate = this.getCartItemTaxRate(item)
        const depositValue = this.returnProductDepositValue(item.product) * item.amount
        const preTaxPrice = (itemPrice - depositValue) / (1 + taxRate)
        subTotal += preTaxPrice + depositValue
      })

      if (discount > 0) {
        subTotal = parseFloat(subTotal) * (1 - discount)
      }

      return parseFloat(subTotal).toFixed(2)
    },
    calculateCartDiscount: function (cart) {
      if (!cart.reduction?.numeric && cart.discount && cart.discount > 0) {
        const total = this.getCartTotalNoDiscount(cart)
        return parseFloat(total * cart.discount)
      } else if (cart.reduction?.numeric) {
        return parseFloat(cart.reduction.numeric)
      } else {
        return 0
      }
    },
    calculateCartDiscountPercent: function (cart) {
      if (!cart.discount || cart.discount === 0) {
        return '0.00%'
      } else {
        return `${(cart.discount * 100).toFixed(2)}%`
      }
    },
    calculateReductionPercent: function (cart) {
      if (cart.reduction && cart.reduction.numeric && cart.reduction.numeric > 0) {
        const total = this.getCartTotalNoDiscount(cart)
        const percent = cart.reduction.numeric / total

        return percent
      }
    },
    calculateCartItemsNum: function (cart) {
      if (cart && cart.items && cart.items.length) {
        return cart.items.reduce(
          (acc, current) => (current && current.amount ? acc + current.amount : 0),
          0
        )
      } else {
        return 0
      }
    },
    getCartName: function (cart) {
      if (
        cart.extras &&
        cart.extras.deviceCartName &&
        typeof cart.extras.deviceCartName === 'string'
      ) {
        return cart.extras.deviceCartName.replace('WEB:', '')
      } else if (
        cart.extras &&
        cart.extras.deviceCartName &&
        typeof cart.extras.deviceCartName === 'number'
      ) {
        return `Cart ${cart.extras.deviceCartName}`
      } else {
        return 'Cart'
      }
    },
    calculateDiscount: function (fullPrice, newPrice) {
      // Return discount percent e.g. 12.50%
      return parseFloat((1 - newPrice / fullPrice) * 100)
    },
    calculateItemPrice: function (item) {
      // (base_price + addons * item_discount) * amount
      const basePrice = item.product.current_variant.price + this.calculateItemAddons(item)
      const discount = item.product.discount ? item.product.discount : 0
      return parseFloat(basePrice * (1 - discount) * item.amount)
    },
    calculateItemAddons: function (item) {
      return !!item.product.current_addons && !!item.product.current_addons.length
        ? item.product.current_addons.reduce((old, current) => {
            return old + current.price * current.amount
          }, 0)
        : 0
    },
    calculateItemPriceNoDiscount: function (item) {
      const basePrice = item.product.current_variant.price + this.calculateItemAddons(item)
      return parseFloat(basePrice * item.amount)
    },
    calculateItemDiscountPrice: function (item) {
      if (item.product.discount && item.product.discount > 0) {
        const itemPrice = this.calculateItemPriceNoDiscount(item)
        return parseFloat(itemPrice * item.product.discount)
      } else {
        return 0
      }
    },
    getCartItemTaxRate: function (item) {
      // Trying to get item tax first from rules array, if doesnt have it, try to get from tax.rate
      const tax = item.product.current_variant.tax
      const itemRate = tax.rules && tax.rules.length ? tax.rules[0].rate : tax.rate ? tax.rate : 0
      return itemRate
    },
    isDiscountedItem: function (item) {
      return (
        (!!item.discount_note && item.discount_note.length) ||
        (!!item.product.discount && item.product.discount > 0)
      )
    },
    hasCustomerCart: function (cart) {
      return cart.customer && Object.keys(cart.customer).length > 0 && cart.customer._id
        ? true
        : false
    },
    returnProductDepositValue: function (product) {
      return product.options.deposit && product.options.deposit.price > 0
        ? product.options.deposit.price
        : 0
    },
    returnProductDepositTax: function (product) {
      const zeroTax = {
        name: 'Zero - 0%',
        rate: 0,
        amount: 0
      }

      if (product.options.deposit && product.options.deposit.price > 0) {
        const tax = product.options.deposit.tax
          ? product.options.deposit.tax
          : product.current_variant.tax

        const rate = tax.rate ? tax.rate : tax.rules[0].rate
        const name = `${tax.name} - ${rate * 100}%`

        return {
          name: name,
          rate: rate,
          amount: (product.options.deposit.price * rate) / (1 + rate)
        }
      } else {
        return zeroTax
      }
    },
    getCartSource: function (cart) {
      return cart.order_source ? translate(cart.order_source) : ''
    },
    isThirdPartySource: function (cart) {
      return (
        cart.order_source &&
        cart.order_source !== 'pos' &&
        cart.order_source !== 'web_pos' &&
        cart.order_source !== 'waiter'
      )
    },
    getDeliveryCost: function (cart) {
      const { delivery } = cart.extras

      if (!!delivery) {
        const cost = delivery.deliveryCost || 0
        const decimal = delivery.decimalDigits || 2

        return cost / Math.pow(10, parseFloat(decimal))
      } else {
        return 0
      }
    },
    getDeliveryRate: function (cart) {
      return !!cart.extras.delivery && !!cart.extras.delivery.deliveryTaxRate
        ? cart.extras.delivery.deliveryTaxRate
        : 0.09
    },
    isExchangeableCart: function (cart) {
      return cart.extras.exchange_source && cart.extras.total_exchanged < 0
    },
    getCartCurrency: function (cart) {
      return !!cart.currency && !!cart.currency.symbol ? cart.currency.symbol : ''
    },
    isDropShipCart: function (cart) {
      return !!cart.extras && !!cart.extras.drop_ship
    },
    sortCartListAlphabetical: function (list) {
      return list.sort((a, b) => {
        if (a.extras && a.extras.deviceCartName && b.extras && b.extras.deviceCartName) {
          return a.extras.deviceCartName.localeCompare(b.extras.deviceCartName)
        } else {
          return false
        }
      })
    },
    hasCoversTakeaway: function (cart) {
      return (
        !!cart.extras.modified ||
        !!cart.extras.takeaway ||
        (!!cart.extras.covers && cart.extras.covers >= 0)
      )
    },
    cartItemHasAddons: function (item) {
      return item.product.current_addons && item.product.current_addons.length > 0
    },
    getDeliveryBy: function (transaction) {
      if (!!transaction.extras) {
        return !!transaction.extras.orderProvider
          ? transaction.extras.orderProvider
          : !!transaction.extras.delivery && !!transaction.extras.delivery.by
          ? transaction.extras.delivery.by
          : 'unknow'
      }

      return 'unknown'
    },
    isGiftcardTransaction: function (transaction) {
      const giftcardPayment = transaction.payments.find(payment => payment.method === 'giftcard')

      return !!giftcardPayment
    },
    getGiftcardsFromPayments: function (transaction) {
      const giftcardPayments = transaction.payments.filter(
        payment => payment.method === 'giftcard' && !!payment.info
      )

      return !!giftcardPayments ? giftcardPayments.map(payment => payment.info) : []
    },
    getItemPaidAmount: function (cart, item) {
      let paidAmount = 0
      if (cart.payments.length) {
        cart.payments.forEach(payment => {
          const { products } = payment.info

          if (products) {
            const product = products.find(p => p.id === this.getCartEntryId(item))

            paidAmount += !!product ? product.amount : 0
          }
        })
      }

      return paidAmount
    },
    getCartEntryId: function (item) {
      if (!item) return

      return countrClient.retrieveCartEntryId(item.product)
    },
    // Generate a ObjectId like mongodb
    ObjectId: function () {
      return (
        Math.floor(Date.now() / 1000).toString(16) +
        ' '.repeat(16).replace(/./g, () => Math.floor(Math.random() * 16).toString(16))
      )
    },
    hashCode: function (s) {
      return s.split('').reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0)
        return a & a
      }, 0)
    },
    /**
     * Format the currency amount according to the locale
     * @param {(Float|String)} amount
     * @param {Object} options
     */
    formatCurrency: function (amount) {
      const separator = '.'

      // parse amount to float if is not already it
      amount = isNaN(amount) ? parseFloat(amount) : amount
      amount = isNaN(amount) ? 0 : amount

      // round amount
      amount = this.roundValue(amount, 2)

      // convert to string
      let stringAmount = amount.toString()
      stringAmount.replace(',', separator).replace('.', separator)

      // add decimals after dot if not present
      if (stringAmount.indexOf(separator) < 0) {
        stringAmount += separator + '00'
      } else {
        if (stringAmount.indexOf(separator) === stringAmount.length - 2) {
          stringAmount += '0'
        }
      }

      // return result string
      return stringAmount
    },
    /**
     * Round the value by trunc according to the number of decimals
     * @param {Number} value Value to round
     * @param {Number} [decimals] Decimals to keep
     */
    roundValue: function (value, decimals) {
      // parse value to float if is not already it
      value = isNaN(value) ? parseFloat(value) : value
      value = isNaN(value) ? 0 : value

      // check decimals value
      decimals = decimals || 2

      // define roundby value
      const roundby = parseInt(Math.pow(10, decimals), 10)

      // trunc amount
      //value = ~~(value * roundby)/roundby;
      value = parseFloat(value * roundby).toFixed(0)

      value = (Math.round(value) | 0) / roundby
      return value
    },
    getCartEntryPaidAmount: function (cart, item, activeSeat) {
      const itemID = this.getCartEntryId(item)
      const hasActiveSeat = activeSeat || activeSeat === 0
      const result = cart.payments.reduce((acc, current) => {
        if (!current?.info?.products?.length) {
          return acc
        }

        const found = hasActiveSeat
          ? current.info.products.find(p => p.position === activeSeat && p.id === itemID)
          : current.info.products.find(p => p.id === itemID)

        if (found) {
          acc += found.amount
        }

        return acc
      }, 0)
      return result
    },
    getIsPartialOptionAvailable: function (type, cart) {
      let status = false
      if (type === 'SEAT') {
        status = cart.payments.some(p => !p?.info?.products?.[0]?.position)
      }
      if (type === 'ITEM') {
        status = cart.payments.some(p => !p?.info?.products)
      }

      return status
    },
    // Return default receipt
    getDefaultReceipt: function (user) {
      if (user && user.receipt) {
        return user.receipt
      } else {
        return {
          header: [
            {
              type: 'image',
              content:
                'https://res.cloudinary.com/countr-prod/image/upload/v1669297673/logo_841.9x595.3.png'
            },
            {
              type: 'text',
              content: 'Your store address',
              style: {
                alignment: 'center',
                fontSize: 'l',
                fontWeight: 'normal',
                fontStyle: 'normal'
              }
            },
            {
              type: 'text',
              content: 'Your text here',
              style: {
                alignment: 'center',
                fontSize: 's',
                fontWeight: 'bold',
                fontStyle: 'normal'
              }
            }
          ],
          footer: [
            {
              type: 'text',
              content: '',
              style: {
                alignment: 'center',
                fontSize: 'm',
                fontWeight: 'normal',
                fontStyle: 'normal'
              }
            }
          ]
        }
      }
    },
    colourNameToHex: function (colour) {
      const colours = {
        black: '#000000',
        grey: '#CCCCCC',
        darkgrey: '#666666',
        darkestgrey: '#333333',
        white: '#FFFFFF',
        darkblue: '#34495E',
        greyblue: '#B6C2CD',
        lightblue: '#00ADEE',
        blue: '#136F8C',
        lightgreen: '#1BBC9B',
        darkgreen: '#26A560',
        purple: '#CA87E6',
        pink: '#F89BBA',
        red: '#E26A6A',
        yellow: '#FFD65C',
        brown: '#B58930'
      }

      if (!colour) {
        return colours.greyblue
      } else if (colour.indexOf('#') >= 0) {
        return colour
      } else if (typeof colours[colour.toLowerCase()] !== 'undefined') {
        return colours[colour.toLowerCase()]
      } else {
        return colours.greyblue
      }
    },
    getTableColor: function (cart, color) {
      if (!cart || !cart.status || !cart.items.length) {
        return color ? color : 'white'
      }

      switch (cart.status) {
        case 'new':
          return '#eb5656'
        case 'pending':
          return '#FFD65C'
        case 'printed':
          return '#FFD65C'
        case 'preparing':
          return '#f79d34'
        case 'ready':
          return '#3bae70'
        case 'completed':
          return '#64c2ea'
        default:
          return '#dedede'
      }
    },
    diff: function (obj1, obj2) {
      const o1 = { ...obj1 }
      const o2 = { ...obj2 }

      return Object.keys(JSON.parse(o1)).reduce((diff, key) => {
        if (o2[key] === JSON.parse(o1)[key]) {
          return diff
        } else {
          return {
            ...diff,
            [key]: JSON.parse(o1)[key]
          }
        }
      }, {})
    },
    tablesInfo: function (table, allCarts) {
      let total = 0
      let items = 0
      let multiEntries = false
      const tableCarts = [{ linked_cart: table.linked_cart }]

      tableCarts.forEach(t => {
        const cart = allCarts.find(c => c._id === t.linked_cart)

        if (cart) {
          if (total != 0 && cart.total != 0) {
            multiEntries = true
          }
          total = total + cart.total
          items = items + cart.items.length
        }
      })

      return {
        total: total,
        items: items,
        multiEntries: multiEntries
      }
    },
    showSeats: function (cart, device) {
      try {
        const settingsEnabled = device?.store?.options?.useSeats || false

        const hasCovers = cart?.extras?.covers > 1
        const isNotTakeaway = !cart?.extras?.takeaway

        return settingsEnabled && hasCovers && isNotTakeaway
      } catch (error) {
        ErrorUtils.logError({
          msg: `Error while checking if should show seats
            (cartUtils.showSeats(cart, device) cart: ${JSON.stringify(
              cart
            )}, device: ${JSON.stringify(device)})`,
          stack: JSON.stringify(error)
        })
      }
    },
    checkCartProps: function (cart) {
      if (
        !('merchant' in cart) ||
        !('store' in cart) ||
        !('device' in cart) ||
        !('timezone' in cart) ||
        !('date' in cart) ||
        !('currency' in cart) ||
        ('currency' in cart && !('symbol' in cart.currency)) ||
        !('order_source' in cart) ||
        !('receipt_id' in cart) ||
        !('receipt_number' in cart) ||
        !('customer' in cart) ||
        !('employee' in cart) ||
        !('items' in cart) ||
        !('total' in cart) ||
        !('sub_total' in cart) ||
        !('discount' in cart) ||
        !('change' in cart) ||
        !('reduction' in cart) ||
        !('payments' in cart) ||
        !('paid' in cart) ||
        !('extras' in cart) ||
        ('extras' in cart &&
          (!('deviceCartNumber' in cart.extras) ||
            !('deviceCartName' in cart.extras) ||
            !('appVersion' in cart.extras) ||
            !('cartStartedAt' in cart.extras) ||
            !('drop_ship' in cart.extras) ||
            !('buzzer' in cart.extras) ||
            !('takeaway' in cart.extras) ||
            !('covers' in cart.extras)))
      ) {
        return false
      } else return true
    },
    checkCartPayments: function (cart, hasItems, itemId) {
      let result = false
      const cartReduction = !!cart?.reduction?.numeric || !!cart?.reduction?.percentage
      if (cart?.payments?.length) {
        if (cartReduction || !itemId) {
          const payments = cart.payments.find(p => !!p?.info?.products?.length === hasItems)
          result = !!payments
        } else if (itemId) {
          const payments = cart.payments.find(
            p => !!p?.info?.products?.length && p.info.products.find(prod => prod.id === itemId)
          )
          result = !!payments
        }
      }
      return result
    },
    getDiscountPercentFromItem: function (product) {
      if (!!product.discount) {
        return product.discount
      } else if (!!product.reduction) {
        const { itemPrice, addons } = countrClient.getPrice(product)
        const price = itemPrice + addons.total
        const { numeric, percentage } = product.reduction || {}

        if (!!numeric || !!percentage) {
          return !!percentage ? percentage : numeric / price
        }
        return 0
      }
    },
    calculateCartEntryPrice: function (item) {
      const { itemPrice, addons } = countrClient.getPrice(item.product)
      return (itemPrice + addons.total) * item.amount
    },
    getProductPrice: function (item) {
      const { itemPrice } = countrClient.getPrice(item.product)
      return itemPrice
    }
  }
})()
