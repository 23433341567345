import { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { cartUtils } from '../../utils/cartUtils'
import { AppInstances } from '../../utils/countrSdkInstance'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

import { Text } from 'react-internationalization'
import { isMobileOnly } from 'react-device-detect'
import { selectCart, addCart } from '../../store/actions/carts'

import ExchangeItemsList from './ExchangeItemsList'

const mapStateToProps = state => {
  return {
    user: state.user.user,
    devices: state.devices,
    transactions: state.transactions,
    carts: state.carts.carts
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectCart: cart => dispatch(selectCart(cart)),
    addCart: cart => dispatch(addCart(cart))
  }
}

const Exchange = memo(props => {
  const [canExchange, setCanExchange] = useState(false)
  const [exchangeableItems, setExchangeableItems] = useState([])
  const [alreadyExchangeableItems, setAlreadyExchangeableItems] = useState([])
  const [exchangeValue, setExchangeValue] = useState(0)

  useEffect(() => {
    initExchangeableList()
  }, [])

  const initExchangeableList = async () => {
    const exchangeableItemsList = []
    const transactionCopy = JSON.parse(JSON.stringify(props.transaction))
    const alreadyExchanged = await getAlreadyExchangedItems(transactionCopy)
    setAlreadyExchangeableItems(alreadyExchanged)

    transactionCopy.items.forEach(item => {
      item.exchange = 0
      exchangeableItemsList.push(item)
    })

    setExchangeableItems(exchangeableItemsList)
  }

  const getExchangeableAmount = item => {
    let amount = item.amount

    if (alreadyExchangeableItems.length) {
      const not = alreadyExchangeableItems.filter(ref => ref.product._id === item.product._id)

      if (not?.length) {
        const sum = not.reduce((acc, current) => acc + current.amount, 0)
        amount = item.amount + sum
      }
    }
    return amount
  }

  const getAlreadyExchangedItems = async transaction => {
    if (transaction.exchanges && transaction.exchanges.length) {
      const list = []
      const countr = await AppInstances.getCountrSdk()
      transaction.exchanges.forEach(ex => {
        list.push(countr.transactions.readOne(ex))
      })

      return Promise.all(list).then(result => {
        const items = result.map(t => t.items.filter(item => item.amount < 0))
        return items.flat()
      })
    } else {
      return []
    }
  }

  const changeExchangeItemButton = (index, type) => {
    const exItems = [...exchangeableItems]
    if (
      type === 'add' &&
      exItems[index].exchange < getExchangeableAmount(props.transaction.items[index])
    ) {
      exItems[index].exchange++
    } else if (type === 'remove' && exItems[index].exchange > 0) {
      exItems[index].exchange--
    }

    setExchangeableItems(exItems)
    setExchangeValue(updateExchangeValue())
    setCanExchange(exItems.reduce((acc, current) => acc + current.exchange, 0) > 0)
  }

  const updateExchangeValue = () => {
    let total = 0
    exchangeableItems.forEach(item => {
      total += item.product.current_variant.price * item.exchange * (1 - item.product.discount)

      if (item.product.current_addons && item.product.current_addons.length && item.exchange > 0) {
        const addonsValue = item.product.current_addons.reduce((oldValue, newValue) => {
          return oldValue + newValue.price * newValue.amount
        }, 0)

        total += addonsValue
      }
    })

    let toExchange = total

    if (props.transaction.discount && props.transaction.discount > 0) {
      toExchange = toExchange * (1 - props.transaction.discount)
    } else if (props.transaction.reduction && props.transaction.reduction.numeric) {
      toExchange = toExchange - props.transaction.reduction.numeric
    }

    return toExchange
  }

  const exchangeAllItems = () => {
    const exItems = [...exchangeableItems]
    exItems.forEach(item => {
      const already = alreadyExchangeableItems.find(i => i.product._id === item.product._id)
      const sub = already ? already.amount : 0
      item.exchange = getExchangeableAmount(item) + sub
      item.exchange = item.exchange < 0 ? 0 : item.exchange
    })
    setExchangeableItems(exItems)
    setExchangeValue(updateExchangeValue())
    setCanExchange(exItems.reduce((acc, current) => acc + current.exchange, 0) > 0)
  }

  const exchangeSelection = async () => {
    const index = props.carts.length
    const cart = cartUtils.createCart(props.user._id, props.devices.device, index)
    cart.items = exchangeableItems
      .map(item => {
        item.amount = item.exchange * -1
        delete item.exchange
        return item
      })
      .filter(item => item.amount !== 0)
    cart.extras.deviceCartName = `EXCHANGE - ${props.transaction.receipt_id}`
    cart.extras.exchange_source = props.transaction._id

    // Check if cart had discount before, then apply it to each individual item (if it has)
    if (!!props.transaction.reduction && !!props.transaction.reduction.percentage) {
      cart.items.map(item => {
        item.product.discount = !!item.product.discount
          ? item.product.discount + item.product.discount * props.transaction.reduction.percentage
          : props.transaction.reduction.percentage

        return item
      })
    }

    const countr = await AppInstances.getCountrSdk()
    const { totalAmount, totalAmountPreTax } = countr.calculateTotal(cart)
    cart.extras.total_exchanged = totalAmount
    cart.total = totalAmount
    cart.sub_total = totalAmountPreTax
    const newCart = await cartUtils.createCartServer(cart)
    // TODO: Need to threat erro here with catch
    props.addCart(newCart)
    localStorage.setItem('EXCHANGE:CountrLite:Cart-' + newCart._id, JSON.stringify(newCart))
    props.selectCart(newCart)
    localStorage.setItem('CountrLite:CurrentCart', JSON.stringify(newCart))
    props.handleClose('CLOSE_TRANSACTION_MODAL')
  }

  return (
    <Dialog open={props.openExchangeDialog} onClose={props.handleClose} fullScreen={isMobileOnly}>
      <DialogTitle>
        <Text id="exchange" />
      </DialogTitle>
      <DialogContent>
        <ExchangeItemsList
          currency={props.devices.store.currency}
          transaction={props.transaction}
          exchangeValue={exchangeValue}
          exchangeItems={exchangeableItems}
          getExchangeableAmount={getExchangeableAmount}
          changeExchangeItemButton={changeExchangeItemButton}
          exchangeAllItems={exchangeAllItems}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.handleClose}>
          <Text id="cancel" />
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: canExchange ? '#318ed5' : '#b0c6d7', color: '#fff' }}
          disabled={!canExchange}
          onClick={exchangeSelection}>
          <Text id="exchange" />
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(Exchange)
