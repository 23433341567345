import { Component } from 'react'
import { connect } from 'react-redux'

import { PaymentUtils } from './../../utils/PaymentUtils'
import { cartUtils } from './../../utils/cartUtils'
import Util from '../../utils/Util'
import IntercomUtils from './../../utils/IntercomUtils'
import ScreenUtils from './../../utils/ScreenUtils'
import StoreUtils from '../../utils/StoreUtils'

import { selectCart, editCart } from '../../store/actions/carts'
import { addTransactionHead } from '../../store/actions/transactions'
import { setToastMessage } from '../../store/actions/app'
import { loadingTrue, loadingFalse } from '../../store/actions/loading'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { Text } from 'react-internationalization'

import ConfirmationModal from './../generic/ConfirmationModal'
import PayplazaModal from './Providers/PayplazaModal'
import CustomPayment from './Providers/CustomPayment'
import CCVModal from './Providers/CCVModal'
import Giftcard from './Providers/Giftcard'
import ExtraInfoModal from './../generic/ExtraInfoModal'

import { AppInstances } from '../../utils/countrSdkInstance'

import './NewPaymentModal.scss'

const mapStateToProps = state => {
  return {
    app: state.app,
    payments: state.payments,
    carts: state.carts,
    devices: state.devices,
    settings: state.settings,
    employees: state.employees,
    user: state.user,
    transactions: state.transactions
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setToastMessage: msg => dispatch(setToastMessage(msg)),
    selectCart: cart => dispatch(selectCart(cart)),
    editCart: cart => dispatch(editCart(cart)),
    addTransactionHead: transaction => dispatch(addTransactionHead(transaction)),
    loadingTrue: () => dispatch(loadingTrue()),
    loadingFalse: () => dispatch(loadingFalse())
  }
}

const MANUAL_CARD_PAYMENT = 'manual_card_payment'
const PAYPLAZA = 'payplaza'
const CCV = 'ccv'
const INVOICE = 'invoice'
const GIFTCARD = 'giftcard'
const CASH = 'cash'

// PAYMENT METHODS THAT SHOULDN'T BE AVAILABLE IN PARTIAL PAYMENT
const NOT_AVAILABLE_METHODS = [INVOICE]

class PaymentSelectionModal extends Component {
  state = {
    manualCardPaymentConfirmation: false,
    openCCVModal: false,
    openPayplazaModal: false,
    customerRequiredModal: false,
    openCustomPaymentModal: false,
    customMethod: {},
    reference: '',
    openGiftcardModal: false,
    shouldRound: false,
    extraInfoModal: false,
    extraInfo: ''
  }

  // Manual card payment modal
  handleOpenManualCardPaymentConfirmation = () => {
    this.setState({ manualCardPaymentConfirmation: true })
  }

  handleCancelManualCardPaymentConfirmation = () => {
    this.setState({ manualCardPaymentConfirmation: false })
  }

  handleConfirmManualCardPaymentConfirmation = () => {
    this.handleCancelManualCardPaymentConfirmation()
    this.handlePayment(MANUAL_CARD_PAYMENT)
  }

  // CCV modal
  handleOpenCCVModal = () => {
    this.setState({ openCCVModal: true })
  }

  handleCloseCCVModal = resultCode => {
    this.setState({ openCCVModal: false })

    if (resultCode.toLowerCase() === 'authorised') {
      this.handlePayment(CCV)
    }
  }

  // Payplaza modal
  handleOpenPayplazaModal = () => {
    this.setState({ openPayplazaModal: true })
  }

  completePayplazaTransaction = resultCode => {
    console.log('​PaymentModal -> handleClosePayplazaModal -> resultCode', resultCode)

    if (resultCode === 'SUCCESS') {
      this.handlePayment(PAYPLAZA)
    }
  }

  handleClosePayplazaModal = resultCode => {
    this.setState({ openPayplazaModal: false })
  }

  // Custom payment
  handleCustomPaymentWithExtras = method => {
    const reference =
      ((this.props.devices.store || {}).store_id || '0') +
      '-' +
      (this.props.devices.device.device_id || '0') +
      '-' +
      Math.round(new Date().getTime(), null)
    this.setState({
      openCustomPaymentModal: true,
      customMethod: {
        ...method,
        extraInfo: this.state.extraInfo
      },
      reference
    })
  }

  handleCloseCustomPaymentWithExtras = () => {
    this.setState({ openCustomPaymentModal: false, customMethod: {} })
  }

  handleClearCustomPaymentExtras = () => {
    this.setState({ extraInfo: '' })
  }

  handleResultCustomPayment = result => {
    if (!!result && !!result.success) {
      const custom = { ...this.state.customMethod }
      this.handleCloseCustomPaymentWithExtras()
      this.handlePayment(custom.name, {
        ...result,
        extraInfo: this.state.extraInfo
      })
    }
  }

  // Customer handler
  handleOpenCustomerRequiredModal = () => {
    this.setState({ customerRequiredModal: true })
  }

  handleOpenExtraInfoModal = methodSelected => {
    this.setState({ extraInfoModal: true, methodSelected })
  }

  handleCloseExtraInfoModal = value => {
    this.setState({ extraInfoModal: false })
    if(value) {
      this.setState({ extraInfo: value }, () =>
        this.handleRunPayment(this.state.methodSelected)
      )
    }
  }

  handleCloseCustomerRequiredModal = () => {
    this.setState({ customerRequiredModal: false })
  }

  // Giftcard handler
  handleOpenGiftCard = () => {
    this.setState({ openGiftcardModal: true })
  }

  handleCloseGiftCard = () => {
    this.setState({ openGiftcardModal: false })
  }

  handleResultGiftcard = result => {
    this.handleCloseGiftCard()
    this.handlePayment(GIFTCARD, result)
  }

  // Payment handler
  handlePaymentMethod = methodSelected => {
    if (
      this.props.settings.roundCashPayment &&
      (methodSelected.method === CASH || methodSelected.usedForChange)
    ) {
      this.setState({ shouldRound: true }, () => this.handleRunPayment(methodSelected))
    } else {
      this.setState({ shouldRound: false }, () => this.handleRunPayment(methodSelected))
    }
  }

  handleRunPayment = method => {
    const {
      app,
      settings,
      devices: { store },
      carts,
      setToastMessage
    } = this.props

    if (method.isCustom) {
      // const custom = store.options.extraPaymentMethods.find(
      //   m => m._id === method.id
      // )

      if (method.extras) {
        method.name = method.method
        method.method = method.provider
      }

      if (method.customer) {
        if (!cartUtils.hasCustomerCart(carts.selectedCart)) {
          this.handleOpenCustomerRequiredModal()
          return
        }
      }

      if (method.addExtraInformation && !this.state.extraInfo) {
        this.handleOpenExtraInfoModal(method)
        return
      }
    }

    // Blocking pin methods if has no internet connection
    if (!Util.isInternetOnline() && (method.method === CCV || method.method === PAYPLAZA)) {
      setToastMessage('no_connection_payment')
      return
    }

    if (method.method === MANUAL_CARD_PAYMENT) {
      this.handleOpenManualCardPaymentConfirmation()
    } else if (method.method === CCV && !method.isCustom && app.isDesktop) {
      this.handleOpenCCVModal()
    } else if (method.method === PAYPLAZA) {
      this.handleOpenPayplazaModal()
      // TODO: Enable it later to workaround no camera
    } else if (method.method === GIFTCARD && settings.enableGiftcardScan) {
      this.handleOpenGiftCard()
    } else if (method.isCustom && method.extras) {
      this.handleCustomPaymentWithExtras(method)
    } else if (method.isCustom && ['cash', 'other'].indexOf(method.provider) >= 0) {
      this.handlePayment(method.method, null, {extraInfo: this.state.extraInfo})
    } else if (method.isCustom) {
      setToastMessage('error_loading_configuration')
      this.logError({
        msg: `PaymentSelectionModal.handleRunPayment could not load payment config`,
        stack: JSON.stringify(method)
      })
      return
    } else {
      this.handlePayment(method.method)
    }
  }

  handlePayment = (method, extra) => {
    if (this.props.onlyButtons) {
      this.handleAddTipToCart(method, extra)
      return
    }
    const { paidProducts } = this.props
    this.handleCartPayment(method, extra, paidProducts)
  }

  handleAddTipToCart = async (method, extra) => {
    const syncedTransaction = this.props.transactions.transactions.find(
      t => t.receipt_id === this.props.openTransaction.receipt_id
    )

    if (syncedTransaction) {
      const device = Object.assign({}, this.props.devices.device)
      const { paymentMethods } = this.props.payments
      const paymentMethod = paymentMethods.find(payment => payment.method === method)
      const provider = paymentMethod?.provider ?? null

      const payment = {
        date: new Date(),
        paid: 0,
        method: PaymentUtils.getPaymentMethod(method, device),
        provider: !!provider ?? PaymentUtils.getPaymentProvider(method, device),
        tip: parseFloat(this.props.change),
        info: {},
        card_print_info: {
          clientTicket: ''
        },
        merchant_card_print_info: null,
        postponed: method === 'pay_later',
        payment_started: new Date()
      }

      syncedTransaction.payments.push(payment)

      try {
        const countr = await AppInstances.getCountrSdk()
        const updated = await countr.transactions.update(syncedTransaction._id, syncedTransaction)
        this.props.handleUpdateTransaction(updated)
        this.props.closeTipsModal()
      } catch (error) {
        console.log(error)
        this.props.setToastMessage('error_retrieve_transactions')
      }
    } else {
      this.props.setToastMessage('unsynced_transaction_cant_reissue')
    }
  }

  handleCartPayment = (method, extra, paidProducts, cartTotal) => {
    this.props.loadingTrue()
    let total = parseFloat(cartTotal ? cartTotal : this.props.total)
    let change = parseFloat(this.props.change)
    let cart = Object.assign({}, this.props.carts.selectedCart)
    const device = Object.assign({}, this.props.devices.device)
    const hasTables = StoreUtils.hasFloorplans(this.props.devices.store)
    cart.employee = PaymentUtils.getEmployee(this.props.employees.selectedEmployee)
    const callbacks = {
      editCart: this.props.editCart,
      selectCart: this.props.selectCart,
      addTransactionHead: this.props.addTransactionHead
    }

    // extra === giftcard
    if (!!extra && !!extra.__t && extra.__t.toLowerCase() === GIFTCARD) {
      if (total > extra.value_remaining) {
        extra.products = !!paidProducts?.length ? paidProducts : false
        cart = PaymentUtils.addPartialGiftPaymentToCart(cart, extra)
        this.props.loadingFalse()
        this.props.handleClosePaymentSelectionModal('partial_payment')
        this.props.selectCart(cart)
        return
      } else {
        cart.info = { ...extra }
        cart.card_print_info = extra.title
      }
    } else if (!!extra) {
      cart = PaymentUtils.extractSdkPaymentExtras(cart, extra)

      if (!!extra.paid) {
        total = extra.paid
      }
      if (!!extra.change) {
        change = extra.change
      }
    }

    if (paidProducts && paidProducts.length) {
      if (cart.info) {
        cart.info.products = paidProducts
      } else {
        cart.info = {
          products: paidProducts
        }
      }
    }

    // Round payment check
    if (this.state.shouldRound) {
      const paid = Number(PaymentUtils.getLastCartPaymentValue(cart))

      if (cart.total - paid <= total) {
        total = PaymentUtils.roundCashPayment(total)
        cart.total = PaymentUtils.roundCashPayment(cart.total)
        change = (PaymentUtils.roundCashPayment(cart.total) - paid - total) * -1
      } else {
        total = PaymentUtils.roundCashPayment(total)
      }
    }

    const { kioskMode, showCartsListAlphabeticOrder, enableCustomerScreen } = this.props.settings
    const cartIndex = showCartsListAlphabeticOrder
      ? cartUtils
          .sortCartListAlphabetical(this.props.carts.carts)
          .findIndex(c => c._id === cart._id)
      : this.props.carts.carts.findIndex(c => c._id === cart._id)
    const { paymentMethods } = this.props.payments
    const paymentMethod = paymentMethods.find(payment => payment.method === method)
    const provider = !!paymentMethod && !!paymentMethod.provider ? paymentMethod.provider : null
    const body = PaymentUtils.createTransactionBody(
      method,
      provider,
      cart,
      device,
      total,
      change,
      kioskMode,
      !!this.props.onlyButtons
    )

    if (enableCustomerScreen) {
      const port = this.props.settings.customerScreenPort
      if (port && port.length) {
        ScreenUtils.pay(body.paid, body.change, cart.currency.code, port)
      }
    }

    const { store } = this.props.devices

    PaymentUtils.payWithMethod(body, cart, callbacks, cartIndex, hasTables).then(
      result => {
        IntercomUtils.trackEvent('payment:partial_payment_succeeded')
      },
      error => {
        this.logError({
          msg: 'PaymentUtils.payWithMethod method returned an error from the server',
          stack: JSON.stringify(error)
        })
        this.props.loadingFalse()
      }
    )

    this.props.loadingFalse()
    if (PaymentUtils.calculatePaidValue(body) >= body.total) {
      this.props.handleClosePaymentSelectionModal(body)
    } else {
      this.props.handleClosePaymentSelectionModal('partial_payment')
    }
  }

  shouldShowPaymentType = method => {
    return method && method.isDesktopOnly && !this.props.app.isDesktop ? false : true
  }

  cartHasPostponedPayment = cart => {
    return cart.payments.length ? cart.payments.findIndex(payment => payment.postponed) >= 0 : false
  }

  hasPayLaterMethod = () => {
    const payLater = this.props.payments.paymentMethods.find(
      method => method.method === 'pay_later'
    )
    return payLater.enabled
  }

  isPaymentMethodAvailable = method => {
    // Only 1 postponed payment per transaction
    if (
      method.method === 'pay_later' &&
      this.cartHasPostponedPayment(this.props.carts.selectedCart)
    ) {
      return false
    }

    return (
      method.enabled &&
      NOT_AVAILABLE_METHODS.indexOf(method.method) === -1 &&
      this.shouldShowPaymentType(method)
    )
  }

  logError = async obj => {
    const errorObj = {
      message: `${obj.msg}, users: ${this.props.user.user.username}, 
        _ID: ${this.props.user.user._id}, device id: ${this.props.devices.device._id}`,
      user: this.props.user.user._id,
      store: this.props.devices.store._id,
      device: this.props.devices.device._id,
      stack: obj.stack,
      date: new Date().toISOString()
    }

    await AppInstances.getCountrSdk()
    AppInstances.logError(errorObj)
  }

  handleClosePaymentSelectionModal() {
    this.props.handleClosePaymentSelectionModal(false)
  }

  handleChildrenRender() {
    return (
      <>
        <Grid
          container
          spacing={!!this.props.onlyButtons ? 1 : 0}
          justifyContent="center"
          alignItems="center">
          {this.props.payments.paymentMethods.map(
            (method, i) =>
              this.isPaymentMethodAvailable(method) && (
                <Grid
                  item
                  xs={!!this.props.onlyButtons ? 6 : 12}
                  key={`${i}_${method.method}`}
                  className="payment-grid">
                  <Button
                    variant="contained"
                    fullWidth={true}
                    className={
                      this.props.disablePaymentButtons
                        ? 'payment-types-button-disabled'
                        : 'payment-types-button'
                    }
                    disabled={this.props.disablePaymentButtons}
                    onClick={() => this.handlePaymentMethod(method)}>
                    <Text id={method.method} />
                  </Button>
                </Grid>
              )
          )}
        </Grid>
        {this.hasPayLaterMethod() && (
          <div className="pay-later-info">
            <span>
              <Text id="pay_later_note_partial" />
            </span>
          </div>
        )}
      </>
    )
  }

  render() {
    return (
      <>
        {this.props.onlyButtons ? (
          this.handleChildrenRender()
        ) : (
          <Dialog
            open={this.props.openPaymentSelectionModal}
            onClose={(event, reason) => {
              Util.handleModalDisableBackdrop(reason, this.handleClosePaymentSelectionModal)
            }}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
              <Text id="select_pay_method" />
            </DialogTitle>
            <DialogContent>{this.handleChildrenRender()}</DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => this.props.handleClosePaymentSelectionModal(false)}>
                <Text id="cancel" />
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {this.state.manualCardPaymentConfirmation && (
          <ConfirmationModal
            openConfirmation={this.state.manualCardPaymentConfirmation}
            handleCloseConfirmation={this.handleCancelManualCardPaymentConfirmation}
            confirmBtn={this.handleConfirmManualCardPaymentConfirmation}
            confirmationTitle={<Text id="manual_card_payment" />}
            confirmationText={<Text id="did_card_payment_succeed" />}
            type="manual_card_payment"
          />
        )}
        {this.state.openCCVModal && (
          // TODO: Remove when new desktop version is launch
          <CCVModal
            openCCVModal={this.state.openCCVModal}
            handleCloseCCVModal={this.handleCloseCCVModal}
            currency={this.props.carts.selectedCart.currency.code}
            total={this.props.total}
            language={this.props.settings.language}
          />
        )}
        {this.state.openPayplazaModal && (
          <PayplazaModal
            openPayplazaModal={this.state.openPayplazaModal}
            handleClosePayplazaModal={this.handleClosePayplazaModal}
            completePayplazaTransaction={this.completePayplazaTransaction}
            type="PURCHASE"
            currency={this.props.carts.selectedCart.currency.code}
            totalToPay={this.props.total}
          />
        )}
        {this.state.openCustomPaymentModal && (
          <CustomPayment
            open={this.state.openCustomPaymentModal}
            handleResultPayment={this.handleResultCustomPayment}
            handleClose={this.handleCloseCustomPaymentWithExtras}
            method={this.state.customMethod}
            amount={this.props.total}
            currency={this.props.carts.selectedCart.currency}
            reference={this.state.reference}
            handleClearExtra={this.handleClearCustomPaymentExtras}
          />
        )}
        {this.state.customerRequiredModal && (
          <ConfirmationModal
            openConfirmation={this.state.customerRequiredModal}
            handleCloseConfirmation={this.handleCloseCustomerRequiredModal}
            noCloseBtn={true}
            confirmBtn={this.handleCloseCustomerRequiredModal}
            confirmationTitle={<Text id="customer_required_title" />}
            confirmationText={<Text id="customer_required_text" />}
            type="customer_required"
          />
        )}
        {this.state.extraInfoModal && (
          <ExtraInfoModal
            openExtraInfo={this.state.extraInfoModal}
            handleClose={this.handleCloseExtraInfoModal}
            handleConfirm={this.handleCloseExtraInfoModal}
          />
        )}
        {this.state.openGiftcardModal && (
          <Giftcard
            hasItems
            open={this.state.openGiftcardModal}
            total={this.props.total}
            handleResult={this.handleResultGiftcard}
            handleClose={this.handleCloseGiftCard}
          />
        )}
      </>
    )
  }
}

const PaymentSelectionModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentSelectionModal)
export default PaymentSelectionModalConnected
