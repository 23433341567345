import {
  SET_PAYMENTS,
  LOAD_PAYMENT_METHODS,
  SET_METHOD,
  SET_METHOD_CASHDRAWER,
  SET_METHOD_PRINT,
  SET_METHOD_EXTRA,
  LOAD_PAYMENT_METHODS_EXTRA,
  GIFTCARD_CODE
} from './../actions/actionTypes'

// If payment method is added to the default list, it must have all this mandatory fields.
// interface Method {
//   method: String
//   enabled: boolean
//   icon: String
//   hasExtra: boolean
//   extraName: String
//   extra: String
//   cashDrawer: boolean
//   printReceipt: boolean
//   isDesktopOnly: boolean
// }

const paymentMethods = require('./../../consts/paymentMethods.json')
const initialState = {
  paymentMethods: paymentMethods,
  giftcardCode: ''
}

const paymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYMENTS:
      return {
        ...state,
        methods: action.payload
      }
    case LOAD_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload
      }
    case SET_METHOD:
      const met = state.paymentMethods.map(method =>
        method.method === action.payload
          ? {
              method: method.method,
              enabled: !method.enabled,
              icon: method.icon,
              hasExtra: method.hasExtra,
              extraName: method.extraName,
              extra: method.extra,
              cashDrawer: method.cashDrawer,
              printReceipt: method.printReceipt,
              isDesktopOnly: method.isDesktopOnly
            }
          : method
      )
      return {
        ...state,
        paymentMethods: met
      }
    case SET_METHOD_CASHDRAWER:
      const meth = state.paymentMethods.map(method =>
        method.method === action.payload
          ? {
              method: method.method,
              enabled: method.enabled,
              icon: method.icon,
              hasExtra: method.hasExtra,
              extraName: method.extraName,
              extra: method.extra,
              cashDrawer: !method.cashDrawer,
              printReceipt: method.printReceipt,
              isDesktopOnly: method.isDesktopOnly
            }
          : method
      )
      return {
        ...state,
        paymentMethods: meth
      }
    case SET_METHOD_PRINT:
      const me = state.paymentMethods.map(method =>
        method.method === action.payload
          ? {
              method: method.method,
              enabled: method.enabled,
              icon: method.icon,
              hasExtra: method.hasExtra,
              extraName: method.extraName,
              extra: method.extra,
              cashDrawer: method.cashDrawer,
              printReceipt: !method.printReceipt,
              isDesktopOnly: method.isDesktopOnly
            }
          : method
      )
      return {
        ...state,
        paymentMethods: me
      }
    case SET_METHOD_EXTRA:
      const ex = state.paymentMethods.map(method =>
        method.method === action.payload.method
          ? {
              method: method.method,
              enabled: method.enabled,
              icon: method.icon,
              hasExtra: method.hasExtra,
              extraName: method.extraName,
              extra: action.payload.extra,
              cashDrawer: method.cashDrawer,
              printReceipt: method.printReceipt,
              isDesktopOnly: method.isDesktopOnly
            }
          : method
      )
      return {
        ...state,
        paymentMethods: ex
      }
    case LOAD_PAYMENT_METHODS_EXTRA:
      const newPayments = action.payload.map(method => {
        return {
          method: method.name,
          provider: method.method,
          enabled: true,
          icon: method.icon,
          hasExtra: false,
          extraName: '',
          extra: '',
          cashDrawer: method.cashDrawer,
          printReceipt: method.printReceipt,
          isDesktopOnly: !method.cloudBased,
          isCustom: method.isCustom,
          usedForChange: method.usedForChange,
          addExtraInformation: method.addExtraInformation,
          id: method._id,
          extras: method.extras,
          customer: method.customer
        }
      })
      return {
        ...state,
        paymentMethods: state.paymentMethods.concat(newPayments)
      }
      case GIFTCARD_CODE:
        return {
          ...state,
          giftcardCode: action.payload
        }
    default:
      return state
  }
}

export default paymentsReducer
