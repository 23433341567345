import { memo, Fragment } from 'react'
import { CartUtils, ProductUtils } from '@countr/utils'
import { cartUtils as CartUtilities } from '../../utils/cartUtils'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { translate } from 'react-internationalization'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { TRACKER_EVENTS } from '../../utils/trackerEvents'
import defaultImg from './../../assets/icon.svg'

import './CartEntry.scss'

const DEFAULT = 'Default'
const CartEntry = memo(
  ({
    cart,
    item,
    index,
    settings,
    currency,
    openCartItemDetailsDialog,
    removeItem,
    seatNo,
    position,
    setTableSeat,
    devices
  }) => {
    const { trackEvent } = useTracking()
    const theme = useSelector(state => state.app.theme)

    if (!item) {
      return null
    }

    const hasDeliveryDetails = () => {
      return (
        (cart.order_source !== 'pos' || cart.order_source !== 'web_pos') &&
        cart.extras &&
        cart.extras.delivery
      )
    }

    const itemCanBeDeleted = () => {
      if (hasDeliveryDetails() || item.amount < 0 || item.product?.options?.auto_created) {
        return false
      }

      if (cart.payments.length) {
        const paidAmount = CartUtilities.getCartEntryPaidAmount(cart, item)
        return paidAmount <= 0 ? true : item.amount > paidAmount
      }

      return true
    }

    const getItemAmount = () => {
      const times = !item.product.options.sold_by_weight ? 'x' : ''
      const grams = item.product.options.sold_by_weight ? ' g' : ''
      return `${times} ${item.amount} ${grams}`
    }

    const openCartEntryDetails = (item, index) => {
      // check if seats setting is active
      // in case it is and the seat is not the active one, change seats
      if (!!devices?.device?.store?.options?.useSeats && seatNo !== position && setTableSeat) {
        setTableSeat(position)
        return
      }
      // Cannot edit exchange item
      if (CartUtils.isCartExchangeable(cart) && item.amount < 0) {
        return
      }

      openCartItemDetailsDialog(item, index)

      trackEvent({
        event: TRACKER_EVENTS.CART_ITEM_EDIT_OPEN,
        data: { name: item.product.name, _id: item.product._id, amount: item.amount },
        merchant: cart.merchant
      })
    }

    const returnProductVariant = () => {
      return (
        (!item.product.current_variant.default ||
          item.product.current_variant.name !== DEFAULT) && (
          <span className="extras">
            {ProductUtils.getVariantName(item.product.current_variant, settings.language)}
          </span>
        )
      )
    }

    const returnProductDeposit = () => {
      return (
        ProductUtils.getDepositPrice(item.product) > 0 && (
          <span className="extras">{`+ ${translate(
            'deposit'
          )} - ${currency}${ProductUtils.getDepositPrice(item.product).toFixed(2)}`}</span>
        )
      )
    }

    const returnProductNote = () => {
      return !!item.note && !!item.note.length && <span className="extras">{item.note}</span>
    }
    const returnProductBarcode = () => {
      return (
        (item.product?.extras?.qr_tag || item.product?.extras?.non_revenue_info) && (
          <span className="extras">
            {item.product.extras.qr_tag || item.product.extras.non_revenue_info}
          </span>
        )
      )
    }

    const returnProductReductionReason = () => {
      const { reduction } = item.product
      return !!reduction && !!reduction.reason && <span className="extras">{reduction.reason}</span>
    }

    const returnProductAddons = () => {
      const renderAddons = addon => {
        const line = `${addon.amount} x ${addon.name} ${
          !!addon.price > 0 ? `(${currency} ${addon.price.toFixed(2)}` : ''
        }`
        return (
          <div key={addon._id}>
            <span className="extras">{line}</span>
          </div>
        )
      }

      return CartUtils.cartEntryHasAddons(item) && item.product.current_addons.map(renderAddons)
    }

    const getItemStatus = () => {
      if (!settings.showItemStatus) {
        return ''
      }

      if (!item) return

      const { status } = item
      const nowTimestamp = new Date()
      const statusWithAmount = !!status?.length ? status.filter(s => !!s.amount) : []

      const latestStatus = !!statusWithAmount?.length
        ? statusWithAmount.reduce((acc, crr) =>
            crr.last_update - nowTimestamp < acc.last_update - nowTimestamp ? crr : acc
          )
        : {}

      return (latestStatus?.state || '').toUpperCase()
    }

    const handleRemoveItem = () => {
      trackEvent({
        event: TRACKER_EVENTS.CART_ITEM_REMOVE,
        data: { name: item.product.name, _id: item.product._id, amount: item.amount },
        merchant: cart.merchant
      })

      removeItem(CartUtilities.getCartEntryId(item), index)
    }

    return (
      <ListItem
        button={!settings.kioskMode}
        className="cart-entry"
        title={getItemStatus()}
        onClick={() => openCartEntryDetails(item, index)}>
        <div className={`cart-entry-container ${getItemStatus()}`}>
          {settings.showProductImage &&
            (item.product.image ? (
              <img
                src={item.product.image}
                className="cart-entry-avatar"
                onError={e => (e.target.src = defaultImg)}
                alt=""
              />
            ) : (
              <div className="cart-entry-avatar cart-entry-avatar-default">
                <font className="initial">{item.product.name.slice(0, 3).toUpperCase()}</font>
              </div>
            ))}
          <div className="cart-entry-details" style={{ flex: 2, marginLeft: 5 }}>
            <span className="name">
              {ProductUtils.getProductName(item.product, settings.language)}
            </span>
            <span className="product-price">{`${currency} ${CartUtilities.getProductPrice(
              item
            )}`}</span>
            {returnProductVariant()}
            {returnProductDeposit()}
            {returnProductNote()}
            {returnProductBarcode()}
            {returnProductAddons()}
            {returnProductReductionReason()}
          </div>
          <div className="cart-entry-details" style={{ flex: 2, textAlign: 'center' }}>
            <span className="name">{`${currency} ${CartUtilities.calculateCartEntryPrice(
              item
            ).toFixed(2)}`}</span>
            <span className="extras">{getItemAmount()}</span>
            {CartUtils.cartEntryHasDiscount(item) && (
              <Fragment>
                <span className="extras korting">{`  ${currency} ${CartUtils.calculateCartEntryPrice(
                  item,
                  true
                ).toFixed(2)}  `}</span>
                <span className="extras">
                  {CartUtilities.getDiscountPercentFromItem(item.product) > 0 ? '-' : ''}
                  {/* Investigate it more as we have this numeric arriving null */}
                  {item.product.reduction?.numeric
                    ? `  ${currency} ${item.product.reduction.numeric.toFixed(2)}`
                    : `${(
                        Math.abs(CartUtilities.getDiscountPercentFromItem(item.product)) * 100
                      ).toFixed(2)}%`}
                </span>
              </Fragment>
            )}
          </div>
        </div>
        {settings.enableCartItemRemove && itemCanBeDeleted() && (
          <ListItemSecondaryAction className="delete-btn">
            <IconButton
              onClick={handleRemoveItem}
              style={{
                color: theme.colors.text
              }}>
              <span className="icon-trash-bin" />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    )
  }
)

export default CartEntry
